import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ChartLines({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3.25h17c.1381 0 .25.11193.25.25v17c0 .1381-.1119.25-.25.25h-17c-.13807 0-.25-.1119-.25-.25v-17c0-.13807.11193-.25.25-.25ZM2 3.5C2 2.67157 2.67157 2 3.5 2h17c.8284 0 1.5.67157 1.5 1.5v17c0 .8284-.6716 1.5-1.5 1.5h-17c-.82843 0-1.5-.6716-1.5-1.5v-17ZM6.375 18c0 .3452.27982.625.625.625s.625-.2798.625-.625v-4c0-.3452-.27982-.625-.625-.625s-.625.2798-.625.625v4Zm5.625.625c-.3452 0-.625-.2798-.625-.625v-8c0-.34518.2798-.625.625-.625s.625.27982.625.625v8c0 .3452-.2798.625-.625.625ZM16.375 18c0 .3452.2798.625.625.625s.625-.2798.625-.625V7c0-.34518-.2798-.625-.625-.625s-.625.27982-.625.625v11Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ChartLines.propTypes = {
  className: PropTypes.string,
};

export default ChartLines;
