import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'i18n';

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
    fontSize: '0.9rem',
    '& td': {
      borderColor: 'black',
      borderStyle: 'solid',
      borderWidth: 1,
      overflow: 'hidden',
      padding: theme.spacing(1),
      wordBreak: 'normal',
    },
    '& th': {
      borderColor: 'black',
      borderStyle: 'solid',
      borderWidth: 1,
      overflow: 'hidden',
      padding: theme.spacing(1),
      wordBreak: 'normal',
    },
  },
}));

function CacheTableInstructions() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th> </th>
          <th>{t('errorPage.help.cacheTable.operatingSystem.pc')}</th>
          <th>{t('errorPage.help.cacheTable.operatingSystem.mac')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t('errorPage.help.cacheTable.browser.chrome')}</td>
          <td rowSpan="3">{t('errorPage.help.cacheTable.instructions.pc')}</td>
          <td rowSpan="2">{t('errorPage.help.cacheTable.instructions.mac')}</td>
        </tr>
        <tr>
          <td>{t('errorPage.help.cacheTable.browser.firefox')}</td>
        </tr>
        <tr>
          <td>{t('errorPage.help.cacheTable.browser.ie')}</td>
          <td>NA</td>
        </tr>
      </tbody>
    </table>
  );
}

export default CacheTableInstructions;
