import PropTypes from 'prop-types';

import { cx } from '@emotion/css';

import Badge from '../../Badge/Badge';

function MultiValue(props) {
  const {
    children,
    data,
    innerProps,
    innerRef,
    removeProps: { onClick },
  } = props;

  return (
    <Badge
      ref={innerRef}
      name={data.value}
      className={cx('HioSelect__multi-value')}
      isRemovable
      onRemove={onClick}
      {...innerProps}
    >
      {children}
    </Badge>
  );
}

MultiValue.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  removeProps: PropTypes.shape({
    onClick: PropTypes.func,
  }),
};

export default MultiValue;
