import { css } from '@emotion/react';

export const globalTokensCss = css`
  :root {
    /* -------------------
          Global colors
    -------------------- */
    /* Primary pallet */
    --text: var(--black);
    --primary: var(--primary-400);
    --white: #ffff;
    --background: var(--gray-100);

    /* Functionality palette */
    --info: var(--info-500);
    --success: var(--success-500);
    --danger: var(--danger-500);
    --warning: var(--warning-500);
    --link: #266aee;

    /* General */
    --overlay: rgba(20, 38, 61, 0.4);

    /* Black */
    --black: #14263d;

    /* Blue */
    --blue-100: #eff6ff;
    --blue-200: #c6def8;
    --blue-300: #a0c5f0;
    --blue-400: #3778c0;
    --blue-500: #326bab;
    --blue-600: #275486;
    --blue-700: #1e4269;

    /* Blue gray */
    --blue-gray-100: #f6f9fd;
    --blue-gray-200: #e8eef5;
    --blue-gray-300: #e1eaf3;
    --blue-gray-400: #d5dfec;
    --blue-gray-500: #c8d7e8;
    --blue-gray-600: #afbed3;
    --blue-gray-700: #95a5ba;

    /* Brown */
    --brown-100: #fff8eb;
    --brown-200: #ffd58c;
    --brown-300: #e9a733;
    --brown-400: #bd8421;
    --brown-500: #906417;
    --brown-600: #714d0e;
    --brown-700: #5b4011;

    /* Gray */
    --gray-100: #fafafb;
    --gray-200: #f5f6f7;
    --gray-300: #edeff1;
    --gray-400: #e5e8eb;
    --gray-500: #d8dbde;
    --gray-600: #a1a8b1;
    --gray-700: #5a6777;

    /* Green */
    --green-100: #e9f7f4;
    --green-200: #d9f2e9;
    --green-300: #a7e0d1;
    --green-400: #14a37d;
    --green-500: #058462;
    --green-600: #096b51;
    --green-700: #0e5945;

    /* Indigo */
    --indigo-100: #e9eeff;
    --indigo-200: #c5c6f5;
    --indigo-300: #a5a8f6;
    --indigo-400: #464be3;
    --indigo-500: #2b30c7;
    --indigo-600: #21259c;
    --indigo-700: #101369;

    /* Orange */
    --orange-100: #fff4ec;
    --orange-200: #ffdcc0;
    --orange-300: #ffbe8c;
    --orange-400: #f59247;
    --orange-500: #f47e24;
    --orange-600: #e86c0c;
    --orange-700: #d16009;

    /* Red */
    --red-100: #f9ecee;
    --red-200: #f6ced6;
    --red-300: #e19eac;
    --red-400: #c67586;
    --red-500: #c23c59;
    --red-600: #aa2441;
    --red-700: #8e1e36;

    /* Red Gray */
    --red-gray-100: #f7f7f7;
    --red-gray-200: #e6e6e6;
    --red-gray-300: #d1d1d1;
    --red-gray-400: #b3b3b3;
    --red-gray-500: #939393;
    --red-gray-600: #696969;
    --red-gray-700: #4c4c4c;

    /* Salmon */
    --salmon-100: #ffeeed;
    --salmon-200: #ffd5d4;
    --salmon-300: #ffbcba;
    --salmon-400: #ff8a86;
    --salmon-500: #ff6b66;
    --salmon-600: #e5605c;
    --salmon-700: #cc5652;

    /* Sky blue */
    --sky-blue-100: #e3eafa;
    --sky-blue-200: #c7d4f5;
    --sky-blue-300: #7c9ce6;
    --sky-blue-400: #4471dc;
    --sky-blue-500: #345dbe;
    --sky-blue-600: #39538f;
    --sky-blue-700: #364872;

    /* Turquoise */
    --turquoise-100: #d9edf0;
    --turquoise-200: #a8d4dd;
    --turquoise-300: #4faabb;
    --turquoise-400: #04859e;
    --turquoise-500: #176780;
    --turquoise-600: #0d4a64;
    --turquoise-700: #042e40;

    /* Yellow */
    --yellow-100: #fef5cc;
    --yellow-200: #fef1b3;
    --yellow-300: #fee780;
    --yellow-400: #fddd4d;
    --yellow-500: #fccf00;
    --yellow-600: #fcc400;
    --yellow-700: #e8b500;

    /* Info */
    --info-100: var(--blue-100);
    --info-200: var(--blue-200);
    --info-300: var(--blue-300);
    --info-400: var(--blue-400);
    --info-500: var(--blue-500);
    --info-600: var(--blue-600);
    --info-700: var(--blue-700);

    /* Success */
    --success-100: var(--green-100);
    --success-200: var(--green-200);
    --success-300: var(--green-300);
    --success-400: var(--green-400);
    --success-500: var(--green-500);
    --success-600: var(--green-600);
    --success-700: var(--green-700);

    /* Warning */
    --warning-100: var(--brown-100);
    --warning-200: var(--brown-200);
    --warning-300: var(--brown-300);
    --warning-400: var(--brown-400);
    --warning-500: var(--brown-500);
    --warning-600: var(--brown-600);
    --warning-700: var(--brown-700);

    /* Danger */
    --danger-100: var(--red-100);
    --danger-200: var(--red-200);
    --danger-300: var(--red-300);
    --danger-400: var(--red-400);
    --danger-500: var(--red-500);
    --danger-600: var(--red-600);
    --danger-700: var(--red-700);

    /* Teal */
    --teal-100: #e9f8f8;
    --teal-200: #cdf4f5;
    --teal-300: #a7dcdd;
    --teal-400: #0e6869;
    --teal-500: #0b5a5a;
    --teal-600: #095152;
    --teal-700: #094545;

    /* -------------------
          Font family
    -------------------- */
    --font-family-primary: Roboto, system-ui, BlinkMacSystemFont, 'Segoe UI',
      Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    /* -------------------
          Font sizes
    -------------------- */
    --font-size-caption: 0.75rem;
    --font-size-body1: 1rem;
    --font-size-body2: 0.875rem;
    --font-size-h1: 2.5rem;
    --font-size-h2: 1.875rem;
    --font-size-h3: 1.5rem;
    --font-size-h4: 1.25rem;
    --font-size-h5: 1.125rem;
    --font-size-h6: 0.875rem;
    --font-size-subtitle1: 1.125rem;
    --font-size-subtitle2: 1rem;

    /* -------------------
            Spacing
    -------------------- */
    --spacing-scale-factor: 0.25rem;
    --spacing-1: 0.25rem;
    --spacing-2: 0.5rem;
    --spacing-3: 0.75rem;
    --spacing-4: 1rem;
    --spacing-5: 1.5rem;
    --spacing-6: 2rem;
    --spacing-7: 2.5rem;
    --spacing-8: 3rem;
    --spacing-9: 4rem;
    --spacing-10: 4.5rem;
    --spacing-11: 5rem;

    /* -------------------
          Border-radius
    -------------------- */
    --border-radius-sm: 0.25rem;
    --border-radius-lg: 0.5rem;

    /* -------------------
      Shadows & Elevations & Z-Index
    -------------------- */
    --elevation-100: 0px 2px 2px rgba(20, 38, 61, 0.1);
    --elevation-200: 0px 2px 3px rgba(20, 38, 61, 0.15);

    --z-index-top: 9999; /* Above everything */
    --z-index-bottom: -100; /* Behind everything */
    --z-index-default: 0;
    --z-index-focus: 1;
    --z-index-overlay: 1040;
    --z-index-modal: 1050;
    --z-index-dropdown: 1060;
    --z-index-toast: 1070;
    --z-index-tooltip: 1080;
  }
`;
