import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Search({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.78 9.57776c0 3.20804-2.5918 5.80214-5.78099 5.80214s-5.78096-2.5941-5.78096-5.80214c0-3.20799 2.59177-5.80216 5.78096-5.80216 3.18919 0 5.78099 2.59417 5.78099 5.80216Zm-1.2475 5.39064c-1.224 1.0366-2.806 1.6615-4.53349 1.6615-3.88309 0-7.03096-3.1573-7.03096-7.05214 0-3.8948 3.14787-7.05216 7.03096-7.05216S16.03 5.68296 16.03 9.57776c0 1.71004-.6069 3.27804-1.6164 4.49894l7.2941 6.1905c.2631.2233.2954.6177.0721.8809-.2234.2632-.6178.2955-.881.0721l-7.3663-6.2518Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Search.propTypes = {
  className: PropTypes.string,
};

export default Search;
