import PropTypes from 'prop-types';
import * as RadixDialog from '@radix-ui/react-dialog';
import { forwardRef } from 'react';
import { cx } from '@emotion/css';

/**
 * The trigger (button) that closes the modal.
 */
const CloseTrigger = forwardRef(({ children, className }, ref) => (
  <RadixDialog.Close
    asChild
    className={cx('HioModal__close-btn', className)}
    ref={ref}
  >
    {children}
  </RadixDialog.Close>
));

CloseTrigger.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CloseTrigger.displayName = 'Modal.CloseTrigger';

export default CloseTrigger;
