import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ClipboardCheck({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.675c-.89746 0-1.625.72754-1.625 1.625v.575H6c-.35001 0-.86112.09986-1.30269.41779C4.22387 4.63366 3.875 5.1963 3.875 6v14c0 .35.09986.8611.41779 1.3027.34087.4734.90351.8223 1.70721.8223h12c.35 0 .8611-.0999 1.3027-.4178.4734-.3409.8223-.9035.8223-1.7072V6c0-.35001-.0999-.86112-.4178-1.30269C19.3663 4.22387 18.8037 3.875 18 3.875h-1.375V3.3c0-.89746-.7275-1.625-1.625-1.625H9Zm7.625 3.45V5.3c0 .89747-.7275 1.625-1.625 1.625H9c-.89746 0-1.625-.72753-1.625-1.625v-.175H6c-.14999 0-.38888.05014-.57231.18221C5.27613 5.41634 5.125 5.6037 5.125 6v14c0 .15.05014.3889.18221.5723.10913.1516.29649.3027.69279.3027h12c.15 0 .3889-.0501.5723-.1822.1516-.1091.3027-.2965.3027-.6928V6c0-.14999-.0501-.38888-.1822-.57231-.1091-.15156-.2965-.30269-.6928-.30269h-1.375Zm-8-1.825c0-.2071.16789-.375.375-.375h6c.2071 0 .375.1679.375.375v2c0 .20711-.1679.375-.375.375H9c-.20711 0-.375-.16789-.375-.375v-2Zm7.4471 7.6977c.25.238.2597.6336.0216.8836l-4.1506 4.3592c-.1145.1201-.2719.1898-.4378.1938-.1659.0039-.3265-.0582-.4465-.1728l-2.4904-2.3777c-.24966-.2384-.25881-.634-.02045-.8837.23836-.2496.63399-.2588.88365-.0204l2.0378 1.9455 3.7191-3.9059c.238-.25.6336-.2597.8836-.0216Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ClipboardCheck.propTypes = {
  className: PropTypes.string,
};

export default ClipboardCheck;
