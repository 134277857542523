import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Move({ className }) {
  return (
    <SvgIcon className={className}>
      <path d="M13.0991 9.55805C13.3432 9.31397 13.7389 9.31397 13.983 9.55805L17.3187 12.8938L13.983 16.2295C13.7389 16.4736 13.3432 16.4736 13.0991 16.2295C12.855 15.9854 12.855 15.5897 13.0991 15.3456L14.9259 13.5188H8.71803C8.37285 13.5188 8.09303 13.239 8.09303 12.8938C8.09303 12.5486 8.37285 12.2688 8.71803 12.2688H14.926L13.0991 10.4419C12.855 10.1979 12.855 9.80212 13.0991 9.55805Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.11747 2.74585H6.11456C6.89111 2.74585 7.61746 3.12969 8.05498 3.77126L9.40794 5.75525H19.7492C21.0463 5.75525 22.0978 6.80678 22.0978 8.10391V17.9546C22.0978 19.2518 21.0463 20.3033 19.7492 20.3033H4.38418C3.10603 20.3033 2.06253 19.2812 2.03603 18.0033L1.76931 5.14321C1.74202 3.8273 2.80128 2.74585 4.11747 2.74585ZM8.74519 7.00939H19.7492C20.3537 7.00939 20.8437 7.49942 20.8437 8.10391V17.9546C20.8437 18.5591 20.3537 19.0491 19.7492 19.0491H4.38418C3.78854 19.0491 3.30225 18.5728 3.28989 17.9773L3.02318 5.1172C3.01046 4.50397 3.5041 3.99999 4.11747 3.99999H6.11456C6.47645 3.99999 6.81494 4.17886 7.01883 4.47785L8.74519 7.00939Z" fill="currentColor" />
    </SvgIcon>
  );
}

Move.propTypes = {
  className: PropTypes.string,
};

export default Move;
