import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function HouseMobile({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4187 1.53595c-.2355-.21249-.59288-.21485-.83118-.00547l-8.375 7.35817c-.259311.22782-.284834.62273-.05701.88204.22783.25931.62274.28481.88205.05701l1.20685-1.06033v7.32023c0 1.1736.95139 2.125 2.125 2.125h5.23319c.3452 0 .625-.2798.625-.625s-.2798-.625-.625-.625H8.23502v-5.4832h2.36748c.3452 0 .625-.2798.625-.625 0-.3451-.2798-.625-.625-.625H7.61002c-.34518 0-.625.2799-.625.625v6.1082H5.36941c-.48325 0-.875-.3917-.875-.875V7.86191c0-.05957-.00834-.1172-.0239-.17177L9.99453 2.8368l3.18197 2.87071c.2562.23122.6515.2109.8827-.04539.2312-.25629.2109-.6515-.0454-.88272l-3.5951-3.24345Zm3.9282 6.82783h5.9775c.1381 0 .25.11193.25.25V20.5764c0 .1381-.1119.25-.25.25h-5.9775c-.1381 0-.25-.1119-.25-.25V8.61378c0-.13807.1119-.25.25-.25Zm-1.5.25c0-.82843.6716-1.5 1.5-1.5h5.9775c.8285 0 1.5.67157 1.5 1.5V20.5764c0 .8284-.6715 1.5-1.5 1.5h-5.9775c-.8284 0-1.5-.6716-1.5-1.5V8.61378Zm5.1098 11.10602c0 .3512-.2796.6359-.6246.6359-.3451 0-.6247-.2847-.6247-.6359s.2796-.6359.6247-.6359c.345 0 .6246.2847.6246.6359ZM16.1652 8.8563c-.3451 0-.625.27982-.625.625s.2799.625.625.625h2.3411c.3451 0 .625-.27982.625-.625s-.2799-.625-.625-.625h-2.3411Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

HouseMobile.propTypes = {
  className: PropTypes.string,
};

export default HouseMobile;
