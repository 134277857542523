import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { borderRadius, colors, fontSizes, spacing } from '../../theme';

import FormError from '../FormError/FormError';
import FormHint from '../FormHint/FormHint';
import FormLabel from '../FormLabel/FormLabel';
import Counter from './components/Counter';

const inputWrapperCss = css`
  position: relative;
`;

const inputCss = (props) => css`
  color: ${colors.black};
  border: 1px solid;
  border-color: ${colors.gray600};
  border-radius: ${borderRadius.lg};
  padding: ${spacing.space3};
  font-size: ${fontSizes.body2};
  width: 100%;
  resize: none;

  :hover,
  :focus {
    :not(:disabled) {
      border-color: ${colors.primary};
    }
  }

  :disabled {
    background-color: ${colors.gray200};
    cursor: not-allowed;
  }

  /** Error */
  ${props.isError &&
  css`
    border-color: ${colors.danger};

    :hover,
    :focus {
      :not(:disabled) {
        border-color: ${colors.danger600};
      }
    }
  `}
`;

const Textarea = forwardRef(
  (
    {
      className,
      error,
      hint,
      id,
      isDisabled,
      isError,
      isReadOnly,
      isRequired,
      label,
      name,
      onBlur,
      onChange,
      placeholder,
      type,
      value,
      limit,
      rows = 6,
      ...restTextareaProps
    },
    ref,
  ) => {
    const withCounter = Boolean(limit && !error);

    const isCounterError = Boolean(limit && value?.length > limit && !error);

    const _isError = Boolean(error) || isError || isCounterError;

    return (
      <div className={cx('HioTextarea__root', className)}>
        {/* Label */}
        {label && (
          <FormLabel htmlFor={id} isRequired={isRequired}>
            {label}
          </FormLabel>
        )}

        <div
          css={inputWrapperCss}
          className={cx('HioTextInput__input-wrapper')}
        >
          {/* Input */}
          <textarea
            css={inputCss({ isError: _isError })}
            className={cx('HioTextarea__input', {
              'HioTextarea__input--disabled': isDisabled,
              'HioTextarea__input--error': _isError,
              'HioTextarea__input--readonly': isReadOnly,
            })}
            id={id}
            disabled={isDisabled}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={isReadOnly}
            ref={ref}
            type={type}
            value={value}
            rows={rows}
            {...restTextareaProps}
          />
        </div>

        {/* Counter */}
        {withCounter && (
          <Counter isError={_isError} value={value} limit={limit} />
        )}

        {/* Hint */}
        {hint && !error && <FormHint>{hint}</FormHint>}

        {/* Error */}
        {error && <FormError>{error}</FormError>}
      </div>
    );
  },
);

Textarea.propTypes = {
  className: PropTypes.string,
  /** Adds an error message (using `FormError`). Also adds an error indication to input */
  error: PropTypes.string,
  /** Adds a label (using `FormHint`) */
  hint: PropTypes.string,
  isDisabled: PropTypes.bool,
  /** Adds an error indication */
  isError: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  id: PropTypes.string,
  /** Adds a label (using `FormLabel`) */
  label: PropTypes.string,
  /** Adds character counter (the input as to be controlled) */
  limit: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  rows: PropTypes.number,
};

export default Textarea;
