import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function StickyNote({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 3.125h-15c-.13807 0-.25.11193-.25.25V20.5c0 .1381.11193.25.25.25h8.5625v-5.5174c0-.6213.5037-1.125 1.125-1.125H19.75V3.375c0-.13807-.1119-.25-.25-.25Zm-.3258 12.2326-4.8617 4.9029v-4.9029h4.8617ZM19.5 1.875h-15c-.82843 0-1.5.67157-1.5 1.5V20.5c0 .8284.67157 1.5 1.5 1.5h9.2229c.4 0 .7834-.1598 1.0651-.4438l5.7771-5.826c.2786-.2809.4349-.6605.4349-1.0562V3.375c0-.82843-.6716-1.5-1.5-1.5Zm-12.5625 6.6c-.34518 0-.625.27982-.625.625s.27982.625.625.625h10.125c.3452 0 .625-.27982.625-.625s-.2798-.625-.625-.625H6.9375ZM6.375 5.72222c0-.34518.27982-.625.625-.625h10c.3452 0 .625.27982.625.625s-.2798.625-.625.625H7c-.34518 0-.625-.27982-.625-.625ZM7 11.8056c-.34518 0-.625.2798-.625.625 0 .3451.27982.625.625.625h4.5c.3452 0 .625-.2799.625-.625 0-.3452-.2798-.625-.625-.625H7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

StickyNote.propTypes = {
  className: PropTypes.string,
};

export default StickyNote;
