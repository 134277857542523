import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';

import { colors, spacing } from '../../theme';

import Text from '../Text/Text';

const SWITCH_TRACK_HEIGHT = 16;
const SWITCH_TRACK_WIDTH = 44;
const SWITCH_THUMB_HEIGHT = 22;
const SWITCH_THUMB_WIDTH = 22;

const switchRootCss = css`
  display: flex;
  align-items: center;
  column-gap: ${spacing.space2};
`;

const switchTriggerCss = css`
  width: ${SWITCH_TRACK_WIDTH}px;
  height: ${SWITCH_TRACK_HEIGHT}px;
  background-color: ${colors.gray600};
  border-radius: ${SWITCH_TRACK_HEIGHT / 2}px;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0;
  border: none;
  outline: none;
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  &[data-state='checked'] {
    background-color: ${colors.primary};
  }
`;

const switchThumbYTranslate = (SWITCH_TRACK_HEIGHT - SWITCH_THUMB_HEIGHT) / 2;
const switchThumbXTranslate = SWITCH_TRACK_WIDTH - SWITCH_THUMB_WIDTH;

const switchThumbCss = css`
  cursor: pointer;
  display: block;
  height: ${SWITCH_THUMB_HEIGHT}px;
  width: ${SWITCH_THUMB_HEIGHT}px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  transition: transform 150ms;
  transform: translateY(${switchThumbYTranslate}px);
  will-change: transform;

  &.Hio__thumb--disabled {
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${colors.gray100};
  }
  
  &[data-state='checked'] {
    transform: translateY(${switchThumbYTranslate}px) translateX(${switchThumbXTranslate}px);

    &:hover:not(:disabled) {
      background-color: ${colors.gray200};
    }
  }
`;

const switchLabelCss = css`
  cursor: pointer;

  &.HioText__root {
    font-weight: 500;
  }

  &::selection {
    color: unset;
    background-color: unset;
  }

  &.HioSwitch__label--disabled {
    cursor: not-allowed;
    color: ${colors.gray600};
  }
`;

/**
 * @see
 * [Radix UI: Switch](https://www.radix-ui.com/primitives/docs/components/switch)
 */
const Switch = forwardRef(
  (
    {
      className,
      checked,
      defaultChecked,
      id,
      isDisabled,
      label,
      name,
      onChange,
      value,
      switchRootProps,
      ...restSwitchProps
    },
    ref,
  ) => (
    <div
      css={switchRootCss}
      className={cx('HioSwitch__root', className)}
      ref={ref}
      {...switchRootProps}
    >
      <RadixSwitch.Root
        css={switchTriggerCss}
        className={cx('HioSwitch__trigger', {
          'HioSwitch__trigger--disabled': isDisabled,
        })}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={isDisabled}
        id={id}
        name={name}
        onCheckedChange={onChange}
        value={value}
        {...restSwitchProps}
      >
        <RadixSwitch.Thumb
          css={switchThumbCss}
          className={cx('HioSwitch__thumb', {
          'Hio__thumb--disabled': isDisabled,
        })}
        />
      </RadixSwitch.Root>
      <Text
        as="label"
        htmlFor={id}
        variant="body2"
        css={switchLabelCss}
        className={cx('HioSwitch__label', {
          'HioSwitch__label--disabled': isDisabled,
        })}
      >
        {label}
      </Text>
    </div>
  ),
);

Switch.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  switchRootProps: PropTypes.object,
};

export default Switch;
