import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { colors, spacing } from '../../theme';

const buttonBaseCss = (props) => css`
  background-color: transparent;
  color: ${colors.text};
  border: none;
  display: inline-flex;
  gap: ${spacing.space1};
  align-items: center;
  justify-content: center;
  padding: 0;
  font-weight: 500;
  font-size: inherit;
  line-height: 1.25;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${props.isFullWidth &&
  css`
    width: 100%;
  `}

  > .HioButtonBase-children {
    display: inline-flex;
  }

  .HioButtonBase-icon {
    display: flex;
    align-items: center;
  }
`;

/**
 * A button that contains as few styles as possible.
 * It aims to be a simple building block for creating a button.
 */
const ButtonBase = forwardRef(
  (
    {
      as: Component = 'button',
      ariaLabel,
      children,
      className,
      endIcon,
      isDisabled,
      isFullWidth,
      onClick,
      startIcon,
      type = 'button',
      ...restProps
    },
    ref,
  ) => (
    <Component
      aria-label={ariaLabel}
      css={buttonBaseCss({ isFullWidth })}
      className={cx([
        'HioButtonBase-root',
        {
          'HioButtonBase--disabled': isDisabled,
          'HioButtonBase--fullWidth': isFullWidth,
        },
        className,
      ])}
      disabled={isDisabled}
      onClick={onClick}
      ref={ref}
      type={type}
      {...restProps}
    >
      {startIcon && (
        <span className="HioButtonBase-icon HioButtonBase-icon-start">
          {startIcon}
        </span>
      )}
      {children && <span className="HioButtonBase-children">{children}</span>}
      {endIcon && (
        <span className="HioButtonBase-icon HioButtonBase-icon-end">
          {endIcon}
        </span>
      )}
    </Component>
  ),
);

ButtonBase.propTypes = {
  as: PropTypes.elementType,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  endIcon: PropTypes.node,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  value: PropTypes.string,
};

export default ButtonBase;
