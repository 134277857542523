import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Clock({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 12c0 4.8325-3.9175 8.75-8.75 8.75-4.83249 0-8.75-3.9175-8.75-8.75 0-4.83249 3.91751-8.75 8.75-8.75 4.8325 0 8.75 3.91751 8.75 8.75ZM22 12c0 5.5228-4.4772 10-10 10-5.52285 0-10-4.4772-10-10C2 6.47715 6.47715 2 12 2c5.5228 0 10 4.47715 10 10Zm-9.375-5.55556c0-.34517-.2798-.625-.625-.625s-.625.27983-.625.625v6.11116c0 .3451.2798.625.625.625h5c.3452 0 .625-.2799.625-.625 0-.3452-.2798-.625-.625-.625h-4.375V6.44444Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Clock.propTypes = {
  className: PropTypes.string,
};

export default Clock;
