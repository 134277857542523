import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Message({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="m7.91694 16.5277-.30553 3.038 4.93689-3.1358H17c2.0711 0 3.75-1.6789 3.75-3.75V7c0-2.07107-1.6789-3.75-3.75-3.75H7C4.92893 3.25 3.25 4.92893 3.25 7v5.6799c0 1.9558 1.49819 3.5631 3.40865 3.7348l1.25829.113Zm-.93812 4.9206c-.35221.2238-.80732-.0569-.76557-.472l.33355-3.3167c-.03551-.0031-.07091-.0067-.1062-.0106C3.94248 17.3709 2 15.2522 2 12.6799V7c0-2.76142 2.23858-5 5-5h10c2.7614 0 5 2.23858 5 5v5.6799c0 2.7614-2.2386 5-5 5h-4.0883l-5.93288 3.7684Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Message.propTypes = {
  className: PropTypes.string,
};

export default Message;
