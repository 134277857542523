import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';

import ButtonBase from '../ButtonBase/ButtonBase';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';

import { borderRadius, colors, fontSizes, spacing } from '../../theme';
import { toRem } from '../../helpers';
import useThemeContext from '../Theme/useThemeContext';

const badgeCss = (props) => css`
  border-radius: ${borderRadius.sm};
  display: inline-flex;
  column-gap: ${spacing.space2};
  align-items: center;
  padding-inline: ${props.isPill ? spacing.space3 : spacing.space2};
  font-size: ${fontSizes.caption};
  font-weight: 400;
  width: fit-content;
  height: ${toRem(24)};

  ${props.isPill &&
  css`
    border-radius: ${toRem(20)};
  `};

  ${props.withStroke &&
  css`
    border: 1px solid currentColor;
  `}
`;

const badgeRemoveButtonCss = (props) => css`
  border-radius: ${props.isPill ? '50%' : borderRadius.sm};
  color: currentColor;

  &:hover,
  &:active {
    backdrop-filter: brightness(95%);
  }
`;

const intentCss = {
  danger: css`
    color: ${colors.danger};
    background-color: ${colors.danger100};
  `,
  gray: css`
    background-color: ${colors.gray200};
  `,
  info: css`
    color: ${colors.info};
    background-color: ${colors.info100};
  `,
  primary: css`
    color: ${colors.primary};
    background-color: ${colors.primary100};
  `,
  success: css`
    color: ${colors.success};
    background-color: ${colors.success100};
  `,
  warning: css`
    color: ${colors.warning};
    background-color: ${colors.warning100};
  `,
};

/**
 * Badges help to label, categorize information, or indicate status, by using a keyword
 */
const Badge = forwardRef(
  (
    {
      children,
      className,
      icon,
      isPill,
      isRemovable,
      onRemove,
      withStroke,
      intent = 'gray',
      ...restProps
    },
    ref,
  ) => {
    const { defaultProps } = useThemeContext();

    const _isPill = isPill ?? defaultProps?.Badge?.isPill ?? false;

    const _withStroke = withStroke ?? defaultProps?.Badge?.withStroke ?? false;

    return (
      <span
        css={[
          badgeCss({ isPill: _isPill, withStroke: _withStroke }),
          intentCss[intent],
        ]}
        className={cx(
          'HioBadge__root',
          {
            [`HioBadge--${intent}`]: Boolean(intent),
            'HioBadge--pill': _isPill,
            'HioBadge--with-stroke': _withStroke,
          },
          className,
        )}
        data-testid="badge"
        ref={ref}
        {...restProps}
      >
        <Text className="HioBadge__text">{children}</Text>
        {icon && <Icon className="HioBadge__icon" icon={icon} size="xs" />}
        {isRemovable && (
          <ButtonBase
            title="Remove"
            className="HioBadge__remove-button"
            css={badgeRemoveButtonCss({ isPill })}
            onClick={onRemove}
          >
            <Icon icon="x" size="xs" />
          </ButtonBase>
        )}
      </span>
    );
  },
);

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  intent: PropTypes.oneOf([
    'danger',
    'info',
    'gray',
    'success',
    'warning',
    'primary',
  ]),
  icon: PropTypes.string,
  isPill: PropTypes.bool,
  isRemovable: PropTypes.bool,
  onRemove: PropTypes.func,
  withStroke: PropTypes.bool,
};

export default Badge;
