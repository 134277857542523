import { cx } from '@emotion/css';
import { css, keyframes } from '@emotion/react';
import PropTypes from 'prop-types';

import { spacing, zIndex } from '../../../theme';
import Paper from '../../Paper/Paper';

const menuAnimation = ({ menuPlacement }) => {
  const from = menuPlacement === 'bottom' ? -2 : 2;
  const to = 0;

  return keyframes`
    from {
      opacity: 0;
      transform: translateY(${from}px);
    }
    to {
      opacity: 1;
      transform: translateY(${to}px);
    }
  `;
};

const menuBasicCss = css`
  position: absolute;
  z-index: ${zIndex.dropdown};
  width: 100%;

  .HioSelect__menu-list {
    padding: 0;
    max-height: 340px;
  }
`;

const menuPlacementCss = {
  bottom: css`
    top: 100%;
    margin-block-start: ${spacing.space1};
    animation: ${menuAnimation({
        menuPlacement: 'bottom',
      })}
      400ms cubic-bezier(0.16, 1, 0.3, 1);
  `,

  top: css`
    bottom: 100%;
    margin-block-end: ${spacing.space1};
    animation: ${menuAnimation({
        menuPlacement: 'top',
      })}
      400ms cubic-bezier(0.16, 1, 0.3, 1);
  `,
};

function Menu(props) {
  const {
    children,
    innerRef,
    innerProps,
    selectProps: { inputValue, isSearchable, options, menuPlacement },
  } = props;

  if (isSearchable && inputValue.length === 0 && options.length === 0) {
    return null;
  }

  return (
    <Paper
      css={[menuBasicCss, menuPlacementCss[menuPlacement]]}
      className={cx('HioSelect__menu', {
        [`HioSelect__menu--${menuPlacement}`]: menuPlacement,
      })}
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  innerProps: PropTypes.objectOf(PropTypes.any),
  selectProps: PropTypes.shape({
    inputValue: PropTypes.string,
    isSearchable: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    menuPlacement: PropTypes.oneOf(['bottom', 'top']),
  }),
};

export default Menu;
