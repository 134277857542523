import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function MagicWound({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M13.23 6.92c-.99-.55-2.26-.21-2.84.76L3.61 18.96c-.58.97-.25 2.19.73 2.74.99.55 2.26.21 2.84-.76l6.78-11.28c.58-.97.25-2.19-.73-2.74ZM6.09 20.34c-.23.38-.72.51-1.1.29-.38-.21-.51-.69-.28-1.06l6.25-10.41 1.37.79-6.24 10.39ZM3.95 5.19C6.61 4.84 6.9 4.56 7.27 2c.36 2.56.65 2.84 3.32 3.19-2.66.35-2.95.63-3.32 3.19-.36-2.56-.66-2.84-3.32-3.19ZM21 10.65c-1.9.25-2.11.45-2.37 2.28-.26-1.83-.47-2.03-2.37-2.28 1.9-.25 2.11-.45 2.37-2.28.26 1.83.47 2.03 2.37 2.28Zm-6.63-5.46c1.14-.15 1.27-.27 1.42-1.37.16 1.1.28 1.22 1.42 1.37-1.14.15-1.27.27-1.42 1.37-.16-1.1-.28-1.22-1.42-1.37Zm1.89 10.01c-1.14.15-1.27.27-1.42 1.37-.16-1.1-.28-1.22-1.42-1.37 1.14-.15 1.27-.27 1.42-1.37.16 1.1.28 1.22 1.42 1.37ZM3 9.74c1.14-.15 1.27-.27 1.42-1.37.16 1.1.28 1.22 1.42 1.37-1.14.15-1.27.27-1.42 1.37-.16-1.1-.28-1.22-1.42-1.37Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

MagicWound.propTypes = {
  className: PropTypes.string,
};

export default MagicWound;
