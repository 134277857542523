import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Calendar({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80556 3.73611c0-.34517-.27983-.625-.625-.625-.34518 0-.625.27983-.625.625v1.59723H3.5c-.82843 0-1.5.67157-1.5 1.5V19.3889c0 .8284.67157 1.5 1.5 1.5h17c.8284 0 1.5-.6716 1.5-1.5V6.83334c0-.82843-.6716-1.5-1.5-1.5h-3.9167V3.73611c0-.34517-.2798-.625-.625-.625-.3451 0-.625.27983-.625.625v1.59723H8.80556V3.73611ZM3.5 6.58334h4.05555v1.45833c0 .34518.27983.625.625.625.34518 0 .625-.27982.625-.625V6.58334h6.52775v1.45833c0 .34518.2799.625.625.625.3452 0 .625-.27982.625-.625V6.58334H20.5c.1381 0 .25.11193.25.25V19.3889c0 .1381-.1119.25-.25.25h-17c-.13807 0-.25-.1119-.25-.25V6.83334c0-.13807.11193-.25.25-.25Zm2.38889 4.23606c-.34518 0-.625.2799-.625.625 0 .3452.27982.625.625.625h2.22222c.34518 0 .625-.2798.625-.625 0-.3451-.27982-.625-.625-.625H5.88889Zm-.625 3.4028c0-.3452.27982-.625.625-.625h2.22222c.34518 0 .625.2798.625.625s-.27982.625-.625.625H5.88889c-.34518 0-.625-.2798-.625-.625Zm.625 2.1528c-.34518 0-.625.2798-.625.625s.27982.625.625.625h2.22222c.34518 0 .625-.2798.625-.625s-.27982-.625-.625-.625H5.88889Zm4.37501-4.9306c0-.3451.2798-.625.625-.625h2.2222c.3452 0 .625.2799.625.625 0 .3452-.2798.625-.625.625h-2.2222c-.3452 0-.625-.2798-.625-.625Zm.625 2.1528c-.3452 0-.625.2798-.625.625s.2798.625.625.625h2.2222c.3452 0 .625-.2798.625-.625s-.2798-.625-.625-.625h-2.2222ZM10.2639 17c0-.3452.2798-.625.625-.625h2.2222c.3452 0 .625.2798.625.625s-.2798.625-.625.625h-2.2222c-.3452 0-.625-.2798-.625-.625Zm5.625-6.1806c-.3452 0-.625.2799-.625.625 0 .3452.2798.625.625.625h2.2222c.3452 0 .625-.2798.625-.625 0-.3451-.2798-.625-.625-.625h-2.2222Zm-.625 3.4028c0-.3452.2798-.625.625-.625h2.2222c.3452 0 .625.2798.625.625s-.2798.625-.625.625h-2.2222c-.3452 0-.625-.2798-.625-.625Zm.625 2.1528c-.3452 0-.625.2798-.625.625s.2798.625.625.625h2.2222c.3452 0 .625-.2798.625-.625s-.2798-.625-.625-.625h-2.2222Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Calendar.propTypes = {
  className: PropTypes.string,
};

export default Calendar;
