import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function CardsQuestion({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M10.81 14.92c.18.17.27.39.27.66s-.09.51-.27.69c-.17.18-.41.27-.72.27s-.54-.09-.73-.27c-.18-.18-.27-.41-.27-.69 0-.28.09-.49.27-.66.19-.18.43-.27.73-.27s.55.09.72.27Zm.9-7.28c-.42-.18-.9-.27-1.46-.27-.42 0-.86.06-1.33.19-.47.12-.89.29-1.25.52-.23.14-.37.31-.42.5-.05.19-.04.36.04.51.08.15.21.25.39.31.19.04.4 0 .64-.14.29-.16.58-.28.86-.37.29-.09.62-.14.97-.14.39 0 .69.08.9.24.21.16.32.38.32.66 0 .24-.06.46-.17.67-.11.21-.3.47-.58.76-.37.4-.64.77-.8 1.11-.15.34-.23.7-.23 1.09 0 .18.04.32.13.43.1.1.23.15.4.15.16 0 .28-.04.37-.11.1-.08.15-.17.16-.29.04-.3.15-.58.31-.84s.45-.58.86-.94c.46-.4.78-.77.96-1.11.19-.34.28-.7.28-1.09 0-.42-.12-.79-.36-1.11-.23-.31-.56-.56-.98-.74l-.01.01Zm9.8 1.8-3.94 8.82s-.02.03-.03.04V20c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10.54c1.1 0 2 .9 2 2v1.45l1.98.32c1.7.27 2.69 2.09 1.98 3.66l.01.01ZM16.29 4c0-.41-.34-.75-.75-.75H5c-.41 0-.75.34-.75.75v16c0 .41.34.75.75.75h10.54c.41 0 .75-.34.75-.75V4Zm3.04 3.01-1.79-.29v8.53l2.83-6.33c.37-.83-.15-1.78-1.04-1.92v.01Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

CardsQuestion.propTypes = {
  className: PropTypes.string,
};

export default CardsQuestion;
