import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Outreach({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M4 7.625h1.875c.34518 0 .625-.27982.625-.625s-.27982-.625-.625-.625H4c-.89746 0-1.625.72754-1.625 1.625v8.8889c0 .8975.72754 1.625 1.625 1.625h1.41225l-.83042 2.0886c-.42082 1.0584.81726 2.0003 1.72498 1.3122l4.48659-3.4008H15c.8975 0 1.625-.7275 1.625-1.625V14.625c0-.3452-.2798-.625-.625-.625s-.625.2798-.625.625v2.2639c0 .2071-.1679.375-.375.375h-4.6268l-4.46416 3.3838 1.34538-3.3838H4c-.20711 0-.375-.1679-.375-.375V8c0-.20711.16789-.375.375-.375Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M9.25 4.25v7.5h11.5v-7.5H9.25ZM9 3c-.55228 0-1 .44772-1 1v8c0 .5523.44772 1 1 1h12c.5523 0 1-.4477 1-1V4c0-.55228-.4477-1-1-1H9Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="m15.0336 7.6008 5.4349-3.99295.7401 1.00735-5.4349 3.99295c-.5897.43331-1.3964.4189-1.9704-.0352L8.78407 4.60166l.77561-.98027 5.01922 3.97128c.1325.1048.3186.10812.4547.00813Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Outreach.propTypes = {
  className: PropTypes.string,
};

export default Outreach;
