import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  baseApiUrl: null,
  baseReportApiUrl: null,
  socketApiUrl: null,
  partnersApiUrl: null,
  dashboardApiUrl: null,
  ehrManagerApiUrl: null,
  homePortalApiUrl: null,
};

export const sliceKey = 'region';

const regionSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    initApiUrls(state, action) {
      state.baseApiUrl = action.payload.baseApi;
      state.baseReportApiUrl = action.payload.reportApi;
      state.socketApiUrl = action.payload.socketApi;
      state.partnersApiUrl = action.payload.partnersApi;
      state.dashboardApiUrl = action.payload.dashboardApi;
      state.ehrManagerApiUrl = action.payload.ehrManagerApi;
      state.homePortalApiUrl = action.payload.homePortalApi;
    },
  },
});

export const { reducer, actions } = regionSlice;
