import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Grid({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 3.25H15.5C15.3619 3.25 15.25 3.36193 15.25 3.5V8.5C15.25 8.63807 15.3619 8.75 15.5 8.75H20.5C20.6381 8.75 20.75 8.63807 20.75 8.5V3.5C20.75 3.36193 20.6381 3.25 20.5 3.25ZM15.5 2H20.5C21.3284 2 22 2.67157 22 3.5V8.5C22 9.32843 21.3284 10 20.5 10H15.5C14.6716 10 14 9.32843 14 8.5V3.5C14 2.67157 14.6716 2 15.5 2ZM3.5 15.25H8.5C8.63807 15.25 8.75 15.3619 8.75 15.5V20.5C8.75 20.6381 8.63807 20.75 8.5 20.75H3.5C3.36193 20.75 3.25 20.6381 3.25 20.5V15.5C3.25 15.3619 3.36193 15.25 3.5 15.25ZM8.5 14H3.5C2.67157 14 2 14.6716 2 15.5V20.5C2 21.3284 2.67157 22 3.5 22H8.5C9.32843 22 10 21.3284 10 20.5V15.5C10 14.6716 9.32843 14 8.5 14ZM15.5 15.25H20.5C20.6381 15.25 20.75 15.3619 20.75 15.5V20.5C20.75 20.6381 20.6381 20.75 20.5 20.75H15.5C15.3619 20.75 15.25 20.6381 15.25 20.5V15.5C15.25 15.3619 15.3619 15.25 15.5 15.25ZM20.5 14H15.5C14.6716 14 14 14.6716 14 15.5V20.5C14 21.3284 14.6716 22 15.5 22H20.5C21.3284 22 22 21.3284 22 20.5V15.5C22 14.6716 21.3284 14 20.5 14ZM3.5 3.25H8.5C8.63807 3.25 8.75 3.36193 8.75 3.5V8.5C8.75 8.63807 8.63807 8.75 8.5 8.75H3.5C3.36193 8.75 3.25 8.63807 3.25 8.5V3.5C3.25 3.36193 3.36193 3.25 3.5 3.25ZM8.5 2H3.5C2.67157 2 2 2.67157 2 3.5V8.5C2 9.32843 2.67157 10 3.5 10H8.5C9.32843 10 10 9.32843 10 8.5V3.5C10 2.67157 9.32843 2 8.5 2Z"
        fill="black"
      />
    </SvgIcon>
  );
}

Grid.propTypes = {
  className: PropTypes.string,
};

export default Grid;
