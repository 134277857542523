import { createContext, useContext } from 'react';

export const SplitButtonContext = createContext();

export function useSplitButtonContext() {
  const context = useContext(SplitButtonContext);

  if (!context) {
    throw new Error(
      'Warning! Extracting context without wrapping your component with the SplitButton Provider',
    );
  }

  return {
    appearance: context.appearance,
    isContained: context.isContained,
    size: context.size,
  };
}
