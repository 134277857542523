import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ExclamationCircle({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.75c4.8325 0 8.75-3.9175 8.75-8.75 0-4.83249-3.9175-8.75-8.75-8.75-4.83249 0-8.75 3.91751-8.75 8.75 0 4.8325 3.91751 8.75 8.75 8.75ZM12 22c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10C6.47715 2 2 6.47715 2 12c0 5.5228 4.47715 10 10 10Zm.0694-11.1111c.3452 0 .625.2798.625.625v5.4167c0 .3451-.2798.625-.625.625-.3451 0-.625-.2799-.625-.625v-5.4167c0-.3452.2799-.625.625-.625ZM12 8.66667c.6137 0 1.1111-.49746 1.1111-1.11111S12.6137 6.44444 12 6.44444c-.6136 0-1.1111.49747-1.1111 1.11112S11.3864 8.66667 12 8.66667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ExclamationCircle.propTypes = {
  className: PropTypes.string,
};

export default ExclamationCircle;
