import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function CheckCircle({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 12c0 4.8325-3.9175 8.75-8.75 8.75-4.83249 0-8.75-3.9175-8.75-8.75 0-4.83249 3.91751-8.75 8.75-8.75 4.8325 0 8.75 3.91751 8.75 8.75ZM22 12c0 5.5228-4.4772 10-10 10-5.52285 0-10-4.4772-10-10C2 6.47715 6.47715 2 12 2c5.5228 0 10 4.47715 10 10Zm-5.0931-2.91291c.2322-.25541.2134-.65069-.042-.88289-.2554-.23219-.6507-.21336-.8829.04205l-5.1147 5.62605-2.8698-2.8698c-.24408-.2441-.63981-.2441-.88389 0-.24407.2441-.24407.6398 0 .8839l3.33329 3.3333c.1209.1209.286.187.4569.1829.1708-.0041.3326-.0779.4476-.2044l5.5555-6.11111Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

CheckCircle.propTypes = {
  className: PropTypes.string,
};

export default CheckCircle;
