import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function CloudUpload({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M7.9395 18H5.59621c-1.36699-.2158-4.10085-1.2948-3.51514-4.5319.1953-1.079 1.05442-2.5896 2.92916-2.5896 0-1.72643.82019-5.30876 4.10098-5.82669 1.36699-.2158 4.33529.12948 5.27269 3.23705.9764-.2158 3.0464-.12948 3.5151 1.94224 1.367 0 4.101.7769 4.101 3.8844C22 15.6262 20.8283 18 16.7273 18H13.798m-.4734-4.6137-2.329-2.4772-2.329 2.4772M11 11.5l.0001 6.5"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

CloudUpload.propTypes = {
  className: PropTypes.string,
};

export default CloudUpload;
