import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { cx } from '@emotion/css';
import * as RadixTabs from '@radix-ui/react-tabs';

import Trigger from './Trigger';
import List from './List';
import Content from './Content';

/**
 * @see
 * [Radix UI: Tabs](https://www.radix-ui.com/primitives/docs/components/tabs)
 */
const Tabs = forwardRef(
  ({ children, className, defaultValue, value, onClick, ...restProps }, ref) => (
    <RadixTabs.Root
      className={cx(className, 'HioTabs__root')}
      ref={ref}
      defaultValue={defaultValue}
      value={value}
      onValueChange={onClick}
      {...restProps}
    >
      {children}
    </RadixTabs.Root>
  ),
);

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
};

Tabs.Content = Content;
Tabs.List = List;
Tabs.Trigger = Trigger;

export default Tabs;
