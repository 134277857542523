import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Trash({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M9.625 10c.34518 0 .625.2798.625.625v5.75c0 .3452-.27982.625-.625.625S9 16.7202 9 16.375v-5.75c0-.3452.27982-.625.625-.625ZM15.25 10.625c0-.3452-.2798-.625-.625-.625s-.625.2798-.625.625v5.75c0 .3452.2798.625.625.625s.625-.2798.625-.625v-5.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 5.375V3.5c0-1.17361.9514-2.125 2.125-2.125h4c1.1736 0 2.125.95139 2.125 2.125v1.875H21.5c.3452 0 .625.27982.625.625s-.2798.625-.625.625h-1.414l-.8718 13.5118c-.0721 1.1182-1.0001 1.9882-2.1205 1.9882H6.90634c-1.12048 0-2.04845-.87-2.12059-1.9882L3.91402 6.625H2.5c-.34518 0-.625-.27982-.625-.625s.27982-.625.625-.625h5.375ZM9.125 3.5c0-.48325.39175-.875.875-.875h4c.4832 0 .875.39175.875.875v1.875h-5.75V3.5ZM5.16662 6.625l.86654 13.4313c.0297.4605.41181.8187.87318.8187H17.0937c.4613 0 .8434-.3582.8731-.8187l.8666-13.4313H5.16662Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Trash.propTypes = {
  className: PropTypes.string,
};

export default Trash;
