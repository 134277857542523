import { createMuiTheme } from '@material-ui/core/styles';

import { borderRadius, colors, elevations } from '@packages/ui';
import tokens from './tokens';
// see options object structure
// https://material-ui.com/customization/themes/#theme-configuration-variables

export default createMuiTheme({
  breakpoints: {
    values: {
      xxs: 480,
      xs: 798,
      sm: 799,
      smd: 989,
      md: 1199,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    useNextVariants: true,
  },
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.2,
    ...tokens.color,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: colors.primary,
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: colors.primary,
        },
      },
    },
    MuiLink: {
      root: {
        fontSize: '0.85rem',
        color: colors.primary,
      },
    },
    MuiPaper: {
      root: {
        color: colors.text,
      },
      elevation1: {
        borderRadius: borderRadius.sm,
        boxShadow: elevations.elevation200,
      },
    },
    MuiFab: {
      root: {
        width: tokens.spacing.base * 4,
        height: tokens.spacing.base * 4,
      },
      primary: {
        color: '#fff',
        boxShadow: 'none',
        backgroundColor: colors.primary,
        '&:hover': {
          backgroundColor: '#ff6761',
          '@media (hover: none)': {
            backgroundColor: '#ff6761',
          },
        },
        '&:disabled': {
          backgroundColor: tokens.color.foreground.disabled,
          color: '#fff',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      secondary: {
        color: colors.text,
        backgroundColor: '#fff',
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.35)',
        '&:hover': {
          backgroundColor: '#e8e8e8',
          '@media (hover: none)': {
            backgroundColor: '#e8e8e8',
          },
        },
        '&:disabled': {
          backgroundColor: '#fff',
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.25)',
          color: tokens.color.foreground.disabled,
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
      extended: {
        color: colors.text,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: '#e8e8e8',
          '@media (hover: none)': {
            backgroundColor: '#e8e8e8',
          },
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: colors.text,
          opacity: 0.4,
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#fff',
        },
        '&$disabled': {
          cursor: 'not-allowed',
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: colors.primary,
        '&$checked': {
          color: colors.primary,
        },
      },
      checked: {},
    },
    MuiCheckbox: {
      colorSecondary: {
        color: colors.primary,
        '&$checked': {
          color: colors.primary,
        },
      },
      checked: {},
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          fontWeight: 500,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.7)',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: tokens.color.background.card,
      },
      colorSecondary: {
        color: colors.primary,
      },
    },
    MuiSwitch: {
      colorPrimary: {
        '&$checked': {
          color: colors.primary,
        },
        '&$checked + $track': {
          backgroundColor: colors.primary,
        },
      },
    },
  },
});
