import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function FaceId({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M2.62 8.15396c-.34242 0-.62-.27758-.62-.62V4.24403C2 3.00469 3.00469 2 4.24403 2h3.41278c.34242 0 .62.27758.62.62s-.27758.62-.62.62H4.24403C3.68952 3.24 3.24 3.68952 3.24 4.24403v3.28993c0 .34242-.27758.62-.62.62Zm13.8111-6.15381c-.3424 0-.62.27758-.62.62s.2776.62.62.62h3.2503c.5545 0 1.004.44952 1.004 1.00403v3.45373c0 .34242.2776.62.62.62s.62-.27758.62-.62V4.24418c0-1.23934-1.0047-2.24403-2.244-2.24403h-3.2503ZM9.45476 16.511c-.28884-.1839-.67208-.0988-.85598.19-.18391.2888-.09885.6721.18999.856 2.08653 1.3285 4.75363 1.3285 6.84013 0 .2888-.1839.3739-.5672.19-.856-.1839-.2888-.5671-.3739-.856-.19-1.6802 1.0698-3.8279 1.0698-5.50814 0Zm-1.26065 4.864c0 .3424-.27758.62-.62.62h-3.2503c-1.23934 0-2.24403-1.0047-2.24403-2.2441v-3.4537c0-.3424.27758-.62.62-.62s.62.2776.62.62v3.4537c0 .5546.44952 1.0041 1.00403 1.0041h3.2503c.34242 0 .62.2776.62.62Zm13.81109-4.9138c0-.3424-.2776-.62-.62-.62s-.62.2776-.62.62v3.2899c0 .5545-.4495 1.004-1.004 1.004h-3.4128c-.3424 0-.62.2776-.62.62s.2776.62.62.62h3.4128c1.2393 0 2.244-1.0047 2.244-2.244v-3.2899Zm-9.0157-7.6169c0-.34241-.2776-.62-.62-.62-.3425 0-.62.27759-.62.62v4.6216h-1.0041c-.3424 0-.62.2775-.62.62 0 .3424.2776.62.62.62h2.2441V8.8443Zm-5.17034-.62c.34242 0 .62.27759.62.62v1.9656c0 .3424-.27758.62-.62.62s-.62-.2776-.62-.62V8.8443c0-.34241.27758-.62.62-.62Zm9.06854.62c0-.34241-.2775-.62-.62-.62-.3424 0-.62.27759-.62.62v1.9656c0 .3424.2776.62.62.62.3425 0 .62-.2776.62-.62V8.8443Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

FaceId.propTypes = {
  className: PropTypes.string,
};

export default FaceId;
