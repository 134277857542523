const tokens = {
  color: {
    foreground: {
      brand: '#ff9b97',
      success: '#80c098',
      info: '#5f81f2',
      warning: '#ff6761',
      critical: '#d04e48',
      disabled: '#ffd9d8',
      text: '#4a4a4a',
      brand2: '#0e6869',
      lightBrand: 'rgba(255, 155, 151, 0.15)',
    },
    background: {
      card: '#ffffff',
      tag: '#dde8fd',
      green: '#e9f7f4',
    },
    text: {
      primary: '#14263d',
      secondary: '#5A6777',
      disabled: '#A1A8B1',
      primary2: '#14263d',
    },
    strokes: {
      segmentation: '#f6c54b',
      segmentationActive: '#7fe300',
      convexHull: '#B97FFF',
      crossGreen: '#5af4d3',
      crossBlue: '#7fb1ff',
      depth: '#B97FFF',
      undermine: '#0368dc',
      tunnel: '#43C5A9',
    },
  },
  spacing: {
    base: 8,
  },
};

export const colors = tokens.color;

export default tokens;
