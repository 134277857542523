import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { colors, spacing } from '../../../theme';

const modalFooterCss = css`
  display: flex;
  column-gap: ${spacing.space3};
  justify-content: flex-end;
  padding: 0 ${spacing.space5} ${spacing.space5} ${spacing.space5};

  &.HioModal__footer--with-separator {
    padding: ${spacing.space5};
    border-top: 1px solid ${colors.gray500};
  }
`;

/**
 * The modal footer.
 */
const Footer = forwardRef(
  ({ children, className, withSeparator, ...restProps }, ref) => (
    <div
      css={modalFooterCss}
      className={cx(
        'HioModal__footer',
        {
          'HioModal__footer--with-separator': withSeparator,
        },
        className,
      )}
      ref={ref}
      {...restProps}
    >
      {children}
    </div>
  ),
);

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** For adding a separator border above the footer */
  withSeparator: PropTypes.bool,
};

Footer.displayName = 'Modal.Footer';

export default Footer;
