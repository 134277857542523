import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isObservationQuestionTouched: false,
  showIncompleteQuestionnairePopup: false,
  continueToPath: null,
  patientId: null,
  patientName: '',
  activeObservationId: null,
  activeEditSection: null,
  observationsSocketState: {},
};

export const sliceKey = 'userInterface';

const partnerSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setObservationQuestionsTouched(state, action) {
      state.isObservationQuestionTouched = action.payload;
    },
    setShowIncompleteQuestionnairePopup(state, action) {
      state.showIncompleteQuestionnairePopup = action.payload;
    },
    setContinueToPath(state, action) {
      state.continueToPath = action.payload;
    },
    setPatientId(state, action) {
      state.patientId = action.payload;
    },
    setPatientName(state, action) {
      state.patientName = action.payload;
    },
    setActiveObservationId(state, action) {
      state.activeObservationId = action.payload;
    },
    setActiveEditSection(state, action) {
      state.activeEditSection = action.payload;
    },
    setObservationsSocketState(state, { payload: { observationId, isOpen } }) {
      state.observationsSocketState[observationId] = isOpen;
    },
  },
});

export const { reducer, actions } = partnerSlice;
