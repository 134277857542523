import { Global } from '@emotion/react';
import PropTypes from 'prop-types';

import { createContext } from 'react';
import { fontFaces } from '../../styles/fonts/fontFaces';
import { globalCss } from '../../styles/global';
import {
  globalTokensCss,
  urineTokensCss,
  woundTokensCss,
} from '../../styles/tokens';

export const ThemeContext = createContext();

const TOKENS_BY_APP = {
  urine: urineTokensCss,
  wound: woundTokensCss,
};

/**
 * The Theme component specifies all aspects of a design system and it applies a consistent tone to apps.
 *
 * In order for the shared UI module to work, Theme has to be placed at the top level of the app,
 * as a parent of all of the shared UI components being used.
 *
 * All of the Theme's design aspects can be customized in order to meet the specific needs of the business or brand.
 */
function ThemeProvider({ children, defaultProps, theme = 'urine' }) {
  const themeTokens = TOKENS_BY_APP[theme];

  return (
    <ThemeContext.Provider value={{ defaultProps }}>
      <Global styles={globalTokensCss} />
      <Global styles={themeTokens} />
      <Global styles={fontFaces} />
      <Global styles={globalCss} />
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  /** The theme to be applied to the app */
  theme: PropTypes.oneOf(['urine', 'wound']),
  children: PropTypes.node,
  /** Change a component's default props */
  defaultProps: PropTypes.object,
};

export default ThemeProvider;
