import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Eye({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28229 11.625C4.53344 15.332 8.03954 18 12.1672 18c4.1277 0 7.6337-2.668 8.8849-6.375-1.2511-3.707-4.7572-6.375-8.8849-6.375-4.12766 0-7.63374 2.66797-8.88491 6.375Zm-1.25357-.1873C3.38246 7.12759 7.40876 4 12.1672 4c4.7584 0 8.7848 3.12763 10.1385 7.4378.0383.1219.0383.2526 0 .3745-1.3538 4.3101-5.3801 7.4377-10.1385 7.4377-4.75844 0-8.78477-3.1276-10.13848-7.4378-.03829-.1219-.03829-.2526 0-.3745ZM12.1667 9.25c-1.3116 0-2.37495 1.0633-2.37495 2.375S10.8551 14 12.1667 14c1.3117 0 2.375-1.0633 2.375-2.375s-1.0633-2.375-2.375-2.375Zm-3.62495 2.375C8.54175 9.62297 10.1647 8 12.1667 8c2.0021 0 3.625 1.62297 3.625 3.625 0 2.002-1.6229 3.625-3.625 3.625-2.002 0-3.62495-1.623-3.62495-3.625Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Eye.propTypes = {
  className: PropTypes.string,
};

export default Eye;
