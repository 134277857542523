import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { forwardRef } from 'react';
import { colors, spacing } from '../../../theme';

import Text from '../../Text/Text';

const radioGroupItemContainerCss = css`
  display: flex;
  align-items: center;
  column-gap: ${spacing.space2};
`;

const radioGroupItemCss = css`
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border-width: 1.5px;
  border-style: solid;
  border-color: ${colors.black};
  background-color: ${colors.white};

  :disabled {
    cursor: not-allowed;
    border-color: ${colors.gray600};

    & + label {
      cursor: not-allowed;
      color: ${colors.gray600};
    }
  }

  &:hover:not(:disabled) {
    border-color: ${colors.primary};
  }

  &[data-state='checked'] {
    border-color: ${colors.primary};

    :disabled {
      opacity: 0.5;
    }

    &:hover:not(:disabled) {
      border-color: ${colors.primary600};

      .HioRadioGroup__item--indicator::after {
        background-color: ${colors.primary600};
      }
    }
  }
`;

const radioGroupItemLabelCss = css`
  cursor: pointer;
`;

const radioGroupItemIndicatorCss = css`
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;

  @keyframes scale {
    from {
      width: 0;
      height: 0;
    }
    to {
      width: 10px;
      height: 10px;
    }
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${colors.primary};
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    animation: scale 0.1s ease-in-out;
  }
`;

const Item = forwardRef(({ className, id, isDisabled, label, value }, ref) => (
  <div
    css={radioGroupItemContainerCss}
    className={cx('HioRadioGroup__item--container', className)}
    ref={ref}
  >
    <RadixRadioGroup.Item
      id={id}
      css={radioGroupItemCss}
      className={cx('HioRadioGroup__item', {
        'HioRadioGroup__item--disabled': isDisabled,
      })}
      disabled={isDisabled}
      value={value}
    >
      <RadixRadioGroup.Indicator
        css={radioGroupItemIndicatorCss}
        className={cx('HioRadioGroup__item--indicator')}
      />
    </RadixRadioGroup.Item>
    <Text
      as="label"
      className={cx('HioRadioGroup__item--label')}
      css={radioGroupItemLabelCss}
      htmlFor={id}
      variant="body2"
    >
      {label}
    </Text>
  </div>
));

Item.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string.isRequired,
};

export default Item;
