import PropTypes from 'prop-types';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { colors } from '../../theme';

const separatorCss = css`
  height: 1px;
  background-color: ${colors.gray400};
`;

function Separator({ className }) {
  return (
    <RadixDropdown.Separator
      className={cx(className, 'HioDropdown__separator')}
      css={separatorCss}
    />
  );
}

Separator.propTypes = {
  className: PropTypes.string,
};

Separator.displayName = 'Dropdown.Separator';

export default Separator;
