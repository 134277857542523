import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { Icons, Sizes } from '../../constants';
import IconComponent from './config';
import { toRem } from '../../helpers';

const iconCss = css`
  &.HioIcon--xs {
    width: ${toRem(16)};
    height: ${toRem(16)};
  }

  &.HioIcon--sm {
    width: ${toRem(20)};
    height: ${toRem(20)};
  }

  &.HioIcon--md {
    width: ${toRem(24)};
    height: ${toRem(24)};
  }

  &.HioIcon--lg {
    width: ${toRem(32)};
    height: ${toRem(32)};
  }
`;

function Icon({ className, icon, size = 'md' }) {
  const Component = IconComponent[icon];
  const isValidIcon = Boolean(Component);

  if (!isValidIcon) {
    // eslint-disable-next-line no-console
    console.warn(
      `Failed to render an icon. Component was given with prop: \`${icon}\``,
    );

    return null;
  }

  return (
    <Component
      css={iconCss}
      className={cx(
        'HioIcon__root',
        {
          [`HioIcon--${size}`]: size,
          [`HioIcon-${icon}`]: isValidIcon,
        },
        className,
      )}
    />
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(Icons)),
  size: PropTypes.oneOf([Sizes.XS, Sizes.SM, Sizes.MD, Sizes.LG]),
};

export default Icon;
