import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import theme from 'styles/theme';

const STYLES_BY_DIRECTION = {
  column: {
    justify: 'flex-start',
    align: 'stretch',
    wrap: 'wrap',
    columnGap: 'normal',
    rowGap: 'normal',
  },
  row: {
    justify: 'flex-start',
    align: 'flex-start',
    wrap: 'wrap',
    rowGap: 'normal',
    columnGap: 'normal',
  },
};

const stackCss = ({ direction, align, justify, wrap, rowGap, columnGap }) => {
  const defaultStyles = STYLES_BY_DIRECTION[direction];

  return css`
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justify ?? defaultStyles.justify};
    align-items: ${align ?? defaultStyles.align};
    flex-wrap: ${wrap ?? defaultStyles.wrap};
    row-gap: ${rowGap ?? defaultStyles.rowGap};
    column-gap: ${columnGap ?? defaultStyles.columnGap};

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      flex-wrap: nowrap;
    }
  `;
};

const Stack = forwardRef(
  (
    { direction, align, justify, wrap, rowGap, columnGap, children, ...restProps },
    ref,
  ) => (
    <div ref={ref} css={stackCss({ direction, align, justify, wrap })} {...restProps}>
      {children}
    </div>
  ),
);

Stack.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.array,
  direction: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
  wrap: PropTypes.string,
  rowGap: PropTypes.string,
  columnGap: PropTypes.string,
  orientation: PropTypes.oneOf(['column', 'row']),
};

export default Stack;
