import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Image({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3.25h17c.1381 0 .25.11193.25.25v8.9508l-2.0349-1.7806c-.9821-.85927-2.4462-.86674-3.4369-.0175l-2.1328 1.8281-2.8721-2.68069c-.99891-.93227-2.54589-.94241-3.5569-.02332L3.25 12.9281V3.5c0-.13807.11193-.25.25-.25Zm17.0884 10.7204c.0497.0434.1042.0776.1616.1026V20.5c0 .1381-.1119.25-.25.25h-17c-.13807 0-.25-.1119-.25-.25v-5.927c.06089-.0266.11854-.0634.17042-.1105l4.13682-3.7608c.52958-.4814 1.3399-.4761 1.86312.0122l6.15324 5.743c.2523.2355.6478.2219.8833-.0305.2355-.2523.2219-.6478-.0305-.8833l-2.3615-2.2041 2.0268-1.7373c.5189-.4448 1.2858-.4409 1.8002.0092l2.6965 2.3595ZM2 3.5C2 2.67157 2.67157 2 3.5 2h17c.8284 0 1.5.67157 1.5 1.5v17c0 .8284-.6716 1.5-1.5 1.5h-17c-.82843 0-1.5-.6716-1.5-1.5v-17ZM14 8c.5523 0 1-.44772 1-1s-.4477-1-1-1-1 .44772-1 1 .4477 1 1 1Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Image.propTypes = {
  className: PropTypes.string,
};

export default Image;
