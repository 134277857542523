import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Landline({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7725 9.95c.52.02 1.05.02 1.57.02l-.01.02h.03c.30126 0 .6-.00504.89748-.01006.295-.00498.58876-.00994.88252-.00994.45-.01.87-.16 1.19-.41.31-.26.5-.59.53-.97.03-.29999.03-.58999.03-.88998V7.48c0-.1 0-.22-.02-.35 1.08-.25 2.2-.38 3.32-.38.91 0 1.92.08 3.08.26v.52c0 .35 0 .69.03 1.04.07.76.79 1.34 1.69 1.36.59.03 1.15.03 1.7.03s1.08 0 1.58-.02c.55-.01 1.01-.15 1.34-.42.31-.26.5-.63.54-1.08.02-.4.02-.78 0-1.24v-.4c-.01-.68-.35-1.27-1.05-1.81-.52-.41-1.18-.75-2.07-1.08-1.9-.69-4-1.05-6.45-1.11h-.59c-1.62 0-3.23.19-4.78.55-1.3.31-2.79.73-4 1.57-.11.06-.2.13-.29.21-.55.44-.85.94-.9 1.52-.03.26-.03.52 0 .78v.71c0 .15.01.3.02.45.11.77.83 1.34 1.73 1.36Zm1.6-4.83c2.06-.77 4.31-1.16 6.73-1.19v.01c.1388 0 .28.00492.4225.00987.145.00504.2912.01013.4375.01013 1.6.07 3.02.28 4.36.64.96.26 1.68.53 2.27.85.41.21.85.49 1.06.88.1.2.15.4.15.57v1.37c-.01.2-.06.33-.15.41-.11.09-.29.13-.55.13h-2.95c-.43-.03-.5-.22-.5-.39V7.01c.01-.54-.19-.78-.79-.96-1.17-.35-2.38-.42-3.19-.44h-.55c-1.11 0-2.12.1-3.07.3-.14.03-.28.06-.42.1l-.36.09c-.18.04-.32.11-.42.2-.18.14-.28.35-.28.59v1.55c-.01.12-.06.22-.14.28-.1.08-.23.12-.4.12h-3.11c-.32-.03-.48-.15-.52-.39-.02-.07-.04-.14-.04-.21V6.97c-.02-.4.17-.74.58-1.08.4-.3.88-.56 1.43-.77Zm5.9525 5.13H12.7V9c0-.34518.2798-.625.625-.625s.625.27983.625.625v1.25h1.5755c.887 0 1.6806.5509 1.9908 1.3818l2.6134 7c.5184 1.3887-.5085 2.8682-1.9908 2.8682H6.35492c-1.43968 0-2.46268-1.4015-2.0239-2.7726l2.23999-7c.28165-.8802 1.09981-1.4774 2.02391-1.4774H10.075V9c0-.34518.2798-.625.625-.625s.625.27983.625.625v1.25ZM8.59492 11.5c-.38051 0-.7174.2459-.83337.6083l-2.24 7c-.18068.5646.24056 1.1417.83337 1.1417H18.1389c.6104 0 1.0332-.6092.8197-1.181l-2.6133-7c-.1278-.3422-.4546-.569-.8198-.569H8.59492ZM12.3 17.625c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75Zm0 1.25c1.6568 0 3-1.3431 3-3s-1.3432-3-3-3c-1.6569 0-3.00001 1.3431-3.00001 3s1.34311 3 3.00001 3Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Landline.propTypes = {
  className: PropTypes.string,
};

export default Landline;
