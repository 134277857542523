import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ChartPie({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M12 2C6 2 2 6.80635 2 11.9694 2 17.5091 6.49086 22 12.0306 22 17.1936 22 22 18 22 12l-10-.0306V2Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4904 8.62585h-6.1163V2.50974c2.8501 1.00734 5.1089 3.26613 6.1163 6.11611Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

ChartPie.propTypes = {
  className: PropTypes.string,
};

export default ChartPie;
