import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Printer({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8889 3.15556c-.22091 0-.4.17908-.4.4V6.4h11.0222V3.55555c0-.22091-.1791-.39999-.4-.39999H6.8889Zm-1.6.4V6.4H3.55557c-.88366 0-1.6.71634-1.6 1.6v6.8889c0 .8836.71634 1.6 1.6 1.6h1.77777V21c0 .5523.44772 1 1 1H17.6667c.5523 0 1-.4477 1-1v-4.5111h1.7778c.8836 0 1.6-.7164 1.6-1.6V8c0-.88365-.7164-1.6-1.6-1.6h-1.7334V3.55555c0-.88365-.7163-1.6-1.6-1.6H6.8889c-.88366 0-1.6.71635-1.6 1.60001ZM3.15557 8c0-.22091.17908-.4.4-.4H20.4445c.2209 0 .4.17908.4.4v6.8889c0 .2209-.1791.4-.4.4h-1.7778V13c0-.5523-.4477-1-1-1H6.33334c-.55228 0-1 .4477-1 1v2.2889H3.55557c-.22092 0-.4-.1791-.4-.4V8Zm3.37777 12.8v-7.6H17.4667v7.6H6.53334ZM18.3889 10.3333c.4602 0 .8333-.37306.8333-.8333 0-.46024-.3731-.83333-.8333-.83333-.4602 0-.8333.37309-.8333.83333 0 .46024.3731.8333.8333.8333Zm-9.72222 5.5111c0-.3313.26863-.6.6-.6h5.46662c.3314 0 .6.2687.6.6 0 .3314-.2686.6-.6.6H9.26668c-.33137 0-.6-.2686-.6-.6Zm.6 1.6223c-.33137 0-.6.2686-.6.6 0 .3313.26863.6.6.6h5.46662c.3314 0 .6-.2687.6-.6 0-.3314-.2686-.6-.6-.6H9.26668Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Printer.propTypes = {
  className: PropTypes.string,
};

export default Printer;
