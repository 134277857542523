import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { fontSizes } from '../../theme';

const textCss = css`
  margin-block: 0;

  &.HioText--caption {
    font-size: ${fontSizes.caption};
    font-weight: 400;
    line-height: 1.5;
  }

  &.HioText--body1 {
    font-size: ${fontSizes.body1};
    font-weight: 400;
    line-height: 1.5;
  }

  &.HioText--body2 {
    font-size: ${fontSizes.body2};
    font-weight: 400;
    line-height: 1.25;
  }

  &.HioText--subtitle1 {
    font-size: ${fontSizes.subtitle1};
    font-weight: 500;
    line-height: 1.5;
  }

  &.HioText--subtitle2 {
    font-size: ${fontSizes.subtitle2};
    font-weight: 500;
    line-height: 1.5;
  }
`;

const Text = forwardRef(
  (
    { children, className, as: Component = 'p', variant, ...restProps },
    ref,
  ) => (
    <Component
      css={textCss}
      className={cx(
        'HioText__root',
        {
          [`HioText--${variant}`]: variant,
        },
        className,
      )}
      ref={ref}
      {...restProps}
    >
      {children}
    </Component>
  ),
);

Text.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'caption',
    'body1',
    'body2',
    'subtitle1',
    'subtitle2',
  ]),
};

export default Text;
