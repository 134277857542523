import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { spacing } from '../../theme';

import { FLEX_UTILS } from '../../constants';

const flexCss = ({
  alignItems,
  columnGap,
  direction,
  gap,
  justifyContent,
  rowGap,
}) => css`
  display: flex;

  /* Direction */
  &.HioFlex__direction-${direction} {
    flex-direction: ${direction};
  }

  /* Justify Content */
  &.HioFlex__justify-content-${justifyContent} {
    justify-content: ${justifyContent};
  }

  /* Align Items */
  &.HioFlex__align-items-${alignItems} {
    align-items: ${alignItems};
  }

  /* Wrap */
  &.HioFlex__wrap {
    flex-wrap: wrap;
  }

  /* Gap */
  ${columnGap &&
  css`
    column-gap: ${columnGap};
  `}

  ${gap &&
  css`
    gap: ${gap};
  `}

  ${rowGap &&
  css`
    row-gap: ${rowGap};
  `}
`;

/**
 * `Flex` is a layout component that allows you to easily create a flexbox layout.
 *
 * Use `Flex` component to position group of sub-elements in one dimension, horizontal or vertical, without being dependent on a custom CSS file for positioning the sub-elements.
 */
const Flex = forwardRef(
  (
    {
      children,
      className,
      columnGap,
      gap,
      role,
      rowGap,
      as: Component = 'div',
      alignItems = FLEX_UTILS.ALIGN_ITEMS.STRETCH,
      direction = FLEX_UTILS.DIRECTIONS.ROW,
      justifyContent = FLEX_UTILS.JUSTIFY_CONTENT.STRETCH,
      wrap = false,
      ...restFlexProps
    },
    ref,
  ) => (
    <Component
      css={flexCss({
        alignItems,
        columnGap,
        direction,
        gap,
        justifyContent,
        rowGap,
      })}
      className={cx(
        'HioFlex__root',
        {
          [`HioFlex__align-items-${alignItems}`]: alignItems,
          [`HioFlex__direction-${direction}`]: direction,
          [`HioFlex__justify-content-${justifyContent}`]: justifyContent,
          HioFlex__wrap: wrap,
        },
        className,
      )}
      ref={ref}
      role={role}
      {...restFlexProps}
    >
      {children}
    </Component>
  ),
);

Flex.propTypes = {
  /** The DOM element to render as the Flex. Defaults to `div`. */
  as: PropTypes.elementType,
  /** Used to align children along the cross axis. */
  alignItems: PropTypes.oneOf(Object.values(FLEX_UTILS.ALIGN_ITEMS)),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** Used to set the space between children. */
  columnGap: PropTypes.oneOf(Object.values(spacing)),
  /** Represents the flex direction property of CSS flexbox. */
  direction: PropTypes.oneOf(Object.values(FLEX_UTILS.DIRECTIONS)),
  /** Used to set the space between children. */
  gap: PropTypes.oneOf(Object.values(spacing)),
  /** Used to align children along the main axis. */
  justifyContent: PropTypes.oneOf(Object.values(FLEX_UTILS.JUSTIFY_CONTENT)),
  /** Represents the flex wrap property of CSS flexbox. */
  wrap: PropTypes.bool,
  /** Accessible role */
  role: PropTypes.string,
  /** Used to set the space between children. */
  rowGap: PropTypes.oneOf(Object.values(spacing)),
};

export default Flex;
