import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ListUl({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M8 18h13M3 18h.01M8 12h13M3 12h.01M8 6h13M3 6h.01"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

ListUl.propTypes = {
  className: PropTypes.string,
};

export default ListUl;
