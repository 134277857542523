import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Filter({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M3.23935 4.81125C5.12745 7.219 8.33633 10.0728 9.39389 10.9913c.22219.193.34949.4704.34949.7647v8.119c0 .4902.36332.9097.82362 1.0629.2346.0781.4791-.0295.6628-.1951l2.5318-2.2819c.7443-.5739.5063-1.3033.5063-3.5756v-3.1301c0-.2939.1269-.5708.3485-.7638 1.0548-.9185 4.2561-3.77233 6.1442-6.18015C21.3375 4.06875 20.8059 3 19.867 3H4.13295c-.93884 0-1.47048 1.06875-.8936 1.81125Z"
        stroke="currentColor"
        strokeWidth="1.25"
      />
    </SvgIcon>
  );
}

Filter.propTypes = {
  className: PropTypes.string,
};

export default Filter;
