import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Support({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="m7.91694 17.5277-.30553 3.038 4.93689-3.1358H17c2.0711 0 3.75-1.6789 3.75-3.75V7c0-2.07107-1.6789-3.75-3.75-3.75H7C4.92893 3.25 3.25 4.92893 3.25 7v6.6799c0 1.9558 1.49819 3.5631 3.40865 3.7348l1.25829.113Zm-.93812 4.9206c-.35221.2238-.80732-.0569-.76557-.472l.33355-3.3167c-.03551-.0031-.07091-.0067-.1062-.0106C3.94248 18.3709 2 16.2522 2 13.6799V7c0-2.76142 2.23858-5 5-5h10c2.7614 0 5 2.23858 5 5v6.6799c0 2.7614-2.2386 5-5 5h-4.0883l-5.93288 3.7684Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M11.05 14.98c-.02-.52.46-.97.97-.97.54 0 .95.44.97.97.02.52-.46.97-.97.97-.54 0-.95-.44-.97-.97ZM9 8.02C9 6.36 10.35 5 12.02 5l.01.01c1.66 0 3.02 1.35 3.02 3.02 0 1.46-1.03 2.68-2.41 2.96v1.04c0 .34-.27.61-.61.61-.34 0-.61-.27-.61-.61v-1.59c0-.34.27-.61.61-.61 1 0 1.81-.81 1.81-1.81s-.81-1.81-1.81-1.81-1.81.81-1.81 1.81c0 .34-.27.61-.61.61-.34 0-.61-.27-.61-.61Z"
      />
    </SvgIcon>
  );
}

Support.propTypes = {
  className: PropTypes.string,
};

export default Support;
