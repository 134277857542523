import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function UrineCup({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.625 4.25h1.07642l1.458 15.066c.10541 1.0892 1.0208 1.9203 2.11512 1.9203h9.33106c1.0944 0 2.0098-.8311 2.1152-1.9203l1.458-15.066h1.0763c.3452 0 .625-.27982.625-.625S21.6003 3 21.2551 3H2.625C2.27982 3 2 3.27982 2 3.625s.27982.625.625.625Zm2.84762 5.32534L4.95726 4.25H18.9229l-1.4463 14.9456c-.0434.4485-.4204.7907-.871.7907H7.27454c-.4506 0-.82753-.3422-.87093-.7907l-.1737-1.7949h4.06629c.3452 0 .625-.2798.625-.625s-.2798-.625-.625-.625H6.10894l-.19719-2.0377h4.38455c.3451 0 .625-.2798.625-.625s-.2799-.625-.625-.625H5.79078l-.19719-2.0377h4.70271c.3451 0 .625-.2798.625-.625 0-.34513-.2799-.62496-.625-.62496H5.47262Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

UrineCup.propTypes = {
  className: PropTypes.string,
};

export default UrineCup;
