import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ChevronUp({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4116 16.4704c.2597-.2273.2861-.6222.0588-.882l-7-7.99997c-.1187-.13564-.2902-.21344-.4704-.21344-.1802 0-.3517.0778-.4704.21344L4.52963 15.5884c-.2273.2598-.20098.6546.0588.882.25977.2273.65462.2009.88192-.0588L12 8.94911l6.5296 7.46249c.2273.2597.6222.2861.882.0588Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ChevronUp.propTypes = {
  className: PropTypes.string,
};

export default ChevronUp;
