import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function LogOut({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.95963 5.19231c0-1.17361.95139-2.125 2.125-2.125h6.25387c.3452 0 .625.27982.625.625 0 .34517-.2798.625-.625.625H4.08463c-.48325 0-.875.39175-.875.875V18.8077c0 .4832.39175.875.875.875h6.25387c.3452 0 .625.2798.625.625s-.2798.625-.625.625H4.08463c-1.17361 0-2.125-.9514-2.125-2.125V5.19231Zm14.55467 2.5192c.2273-.25977.6221-.2861.8819-.05879l4.4308 3.87688c.1356.1187.2134.2902.2134.4704 0 .1802-.0778.3517-.2134.4704l-4.4308 3.8769c-.2598.2273-.6546.201-.8819-.0588-.2273-.2598-.201-.6546.0588-.8819l3.1789-2.7816H7.56924c-.34518 0-.625-.2798-.625-.625s.27982-.625.625-.625H19.752l-3.1789-2.78156c-.2598-.22731-.2861-.62216-.0588-.88193Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

LogOut.propTypes = {
  className: PropTypes.string,
};

export default LogOut;
