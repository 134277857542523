import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { zIndex } from '../../theme';
import Button from '../Button/Button';
import { useSplitButtonContext } from './useSplitButtonContext';

const defaultBtnCss = css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  :focus {
    z-index: ${zIndex.focus};
  }
`;

function DefaultButton({ children, ...restProps }) {
  const { appearance, size } = useSplitButtonContext();

  return (
    <Button
      appearance={appearance}
      css={defaultBtnCss}
      size={size}
      intent="primary"
      {...restProps}
    >
      {children}
    </Button>
  );
}

DefaultButton.propTypes = {
  children: PropTypes.node,
};

DefaultButton.displayName = 'SplitButton.DefaultButton';

export default DefaultButton;
