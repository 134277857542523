import { useEffect } from 'react';
import fetchIntercept from 'fetch-intercept';

import { network } from '@packages/network';
import {
  handleRefreshToken,
  getHeadersWithAuthToken,
} from './utils';

/**
 * An interceptor hook around HealthyAuth.
 *
 * @see
 * - [`Axios Interceptors`](https://axios-http.com/docs/interceptors)
 * - [`fetch-intercept`](https://github.com/werk85/fetch-intercept)
 *
 * @param {Array<string>} apiUrls - Append authentication headers to the URLs listed in this list
 */
export default function useInterceptHealthyAuth({ apiUrls, forceLogout }) {
  // Intercepting Axios
  useEffect(() => {
    // Request
    const requestInterceptor = network.interceptors.request.use((config) => {
      const newConfig = { ...config };

      // Add authentication headers if the URL is listed as a Healthy API URL
      if (apiUrls?.length) {
        const isApiUrl = apiUrls.some((apiUrl) => config.url.includes(apiUrl));

        if (isApiUrl) {
          const authHeaders = network.shapeHeadersToObj(
            getHeadersWithAuthToken(),
          );

          newConfig.headers = {
            ...newConfig.headers,
            ...authHeaders,
          };
        }
      }

      return newConfig;
    });

    // Response
    const responseInterceptor = network.interceptors.response.use(
      (response) => {
        handleRefreshToken(response.headers);
        return response;
      },
      (error) => {
        if (
          error?.response?.status === 401 &&
          error?.config?.url.includes('healthy.io')
        ) {
          forceLogout();
          return Promise.reject(new Error('Unauthorized'));
        }

        return Promise.reject(error);
      },
    );

    return () => {
      network.interceptors.request.eject(requestInterceptor);
      network.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  // Intercepting Fetch
  useEffect(() => {
    const unregister = fetchIntercept.register({
      response(response) {
        if (response.status === 401 && response.url.includes('healthy.io')) {
          forceLogout();
          return Promise.reject(new Error('Unauthorized'));
        }

        return response;
      },
    });

    return () => {
      unregister();
    };
  }, []);
}
