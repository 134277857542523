import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function StopCircle({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M8 9c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v6c0 .55-.45 1-1 1H9c-.55 0-1-.45-1-1V9Zm1.25 5.75h5.5v-5.5h-5.5v5.5ZM22 12c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2s10 4.48 10 10Zm-1.24 0c0-4.84-3.92-8.76-8.76-8.76S3.24 7.16 3.24 12s3.92 8.76 8.76 8.76 8.76-3.92 8.76-8.76Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

StopCircle.propTypes = {
  className: PropTypes.string,
};

export default StopCircle;
