import { useDispatch } from 'react-redux';

import { actions } from './slice';
import { logoutAndInvalidate } from './utils';

export default function useLogout() {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(actions.resetAuthData());
    logoutAndInvalidate();
  };

  return { logout };
}
