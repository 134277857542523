import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function AbortFlow({ className }) {
  return (
    <SvgIcon className={className}>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="12"
        cy="12"
        r="9.375"
        stroke="currentColor"
        strokeWidth="1.25"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit="2"
        strokeWidth="1.25"
        d="M8.852 13.05V11.3c0-.675-.43-1.025-.876-1.206-.512-.207-.976.254-.976.806v2.5c0 2.45 1.852 4.9 5.556 4.9 2.638-.129 3.476-1.841 3.66-2.324a.723.723 0 0 0 .043-.26V11.3"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit="2"
        strokeWidth="1.25"
        d="M8.852 11.3V6.575c0-.483.415-.875.926-.875.51 0 .926.392.926.875v5.075m0 .35V5.921c0-.508.416-.921.928-.921.513 0 .929.413.929.921V12"
      />
      <path
        stroke="currentColor"
        strokeMiterlimit="2"
        strokeWidth="1.25"
        d="M12.556 11.65V6.575c0-.483.414-.875.926-.875.51 0 .925.392.925.875V12m.001 0V7.975c0-.483.414-.875.925-.875.511 0 .926.392.926.875v4.375"
      />
    </SvgIcon>
  );
}

AbortFlow.propTypes = {
  className: PropTypes.string,
};

export default AbortFlow;
