import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: spacing(3, 0),
    padding: spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: palette.foreground.brand,
  },
}));

function Loader() {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <CircularProgress color="inherit" size={24} thickness={4} />
    </Paper>
  );
}

export default Loader;
