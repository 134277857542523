import PropTypes from 'prop-types';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { colors, spacing } from '../../theme';

const labelCss = css`
  color: ${colors.gray700};
  padding-inline: ${spacing.space3};
  padding-block: ${spacing.space3} ${spacing.space2};
`;

function Label({ children, className }) {
  return (
    <RadixDropdown.Label
      className={cx(className, 'HioDropdown__label')}
      css={labelCss}
    >
      {children}
    </RadixDropdown.Label>
  );
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Label.displayName = 'Dropdown.Label';

export default Label;
