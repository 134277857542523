import PropTypes from 'prop-types';

import { cx } from '@emotion/css';
import { css } from '@emotion/react';

import { colors } from '../../../theme';

const indicatorSeparatorCss = css`
  width: 1px;
  height: 100%;
  background-color: ${colors.gray400};
`;

function IndicatorSeparator(props) {
  const {
    children,
    hasValue,
    innerProps,
    innerRef,
    selectProps: { isClearable, isLoading },
  } = props;

  const showIndicatorSeparator = Boolean(
    (hasValue && isClearable) || isLoading,
  );

  return showIndicatorSeparator ? (
    <div
      className={cx('HioSelect__indicator-separator')}
      css={indicatorSeparatorCss}
      {...innerProps}
      ref={innerRef}
    >
      {children}
    </div>
  ) : null;
}

IndicatorSeparator.propTypes = {
  children: PropTypes.node,
  hasValue: PropTypes.bool.isRequired,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.shape({
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool,
  }).isRequired,
};

export default IndicatorSeparator;
