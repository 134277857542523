import PropTypes from 'prop-types';
import * as RadixTabs from '@radix-ui/react-tabs';
import { cx } from '@emotion/css';

function Content({ children, className, value }) {
  return (
    <RadixTabs.Content
      value={value}
      className={cx(className, 'HioTabs__content')}
    >
      {children}
    </RadixTabs.Content>
  );
}

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.string,
};

Content.displayName = 'Tabs.Content';

export default Content;
