import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { colors, spacing } from '../../theme';

import Text from '../Text/Text';

const labelCss = (props) => css`
  display: inline-block;
  color: ${colors.gray700};
  margin-block-end: ${spacing.space1};
  cursor: pointer;

  ${props.isRequired &&
  css`
    :after {
      content: '*';
      position: relative;
      font-size: inherit;
      color: ${colors.danger};
      margin-inline-start: ${spacing.space1};
    }
  `}
`;

const FormLabel = forwardRef(
  ({ children, className, htmlFor, isRequired }, ref) => (
    <div className={cx('HioFormLabel__root', className)}>
      <Text
        as="label"
        css={labelCss({ isRequired })}
        className={cx('HioFormLabel__label', {
          'HioFormLabel--required': isRequired,
        })}
        htmlFor={htmlFor}
        variant="body2"
        ref={ref}
      >
        {children}
      </Text>
    </div>
  ),
);

FormLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default FormLabel;
