import { useContext } from 'react';

import { ThemeContext } from './ThemeProvider';

export default function useThemeContext() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error(
      'Error! Extracting context without wrapping your component with the Theme Provider',
    );
  }

  return { defaultProps: context.defaultProps };
}
