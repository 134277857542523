import PropTypes from 'prop-types';

import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import Text from '../../Text/Text';
import { colors, spacing } from '../../../theme';

const counterCss = ({ isError }) => css`
  display: flex;
  justify-content: flex-end;
  margin-block-start: ${spacing.space1};
  color: ${isError ? colors.danger500 : colors.text};
`;

function Counter({ isError, limit, value }) {
  return (
    <div
      css={counterCss({ isError })}
      className={cx('HioTextarea__counter', {
        'HioTextarea__counter--error': isError,
      })}
    >
      <Text>
        {value?.length ?? 0}/{limit}
      </Text>
    </div>
  );
}

Counter.propTypes = {
  isError: PropTypes.bool,
  limit: PropTypes.number,
  value: PropTypes.string,
};

export default Counter;
