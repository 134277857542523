import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ChevronLeft({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4704 4.58843c-.2273-.25978-.6222-.2861-.882-.0588L7.58843 11.5296c-.13564.1187-.21344.2902-.21344.4704 0 .1802.0778.3517.21344.4704l7.99997 7c.2598.2273.6546.2009.882-.0588.2273-.2598.2009-.6547-.0588-.882L8.94911 12l7.46249-6.52965c.2597-.2273.2861-.62215.0588-.88192Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ChevronLeft.propTypes = {
  className: PropTypes.string,
};

export default ChevronLeft;
