import PropTypes from 'prop-types';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { cx } from '@emotion/css';

function Trigger({ children, className, isDisabled }) {
  return (
    <RadixDropdown.Trigger
      asChild
      className={cx(className, 'HioDropdown__trigger')}
      disabled={isDisabled}
    >
      {children}
    </RadixDropdown.Trigger>
  );
}

Trigger.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

Trigger.displayName = 'Dropdown.Trigger';

export default Trigger;
