import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Import({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19231 22.0404c-1.17361 0-2.125-.9514-2.125-2.125v-6.2539c0-.3451.27982-.625.625-.625.34517 0 .625.2799.625.625v6.2539c0 .4832.39175.875.875.875H18.8077c.4832 0 .875-.3918.875-.875v-6.2539c0-.3451.2798-.625.625-.625s.625.2799.625.625v6.2539c0 1.1736-.9514 2.125-2.125 2.125H5.19231Zm2.5192-10.5108c-.25977.2273-.28609.6222-.05879.882l3.87688 4.4307c.1187.1357.2902.2135.4704.2135.1802 0 .3517-.0778.4704-.2135l3.8769-4.4307c.2273-.2598.201-.6547-.0588-.882-.2598-.2273-.6546-.2009-.8819.0588l-2.7816 3.179V2.58463c0-.34518-.2798-.625-.625-.625s-.625.27982-.625.625V14.7674l-2.78156-3.179c-.2273-.2597-.62215-.2861-.88193-.0588Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Import.propTypes = {
  className: PropTypes.string,
};

export default Import;
