import PropTypes from 'prop-types';

import Text from '../Text/Text';

function Title({ children, ...restProps }) {
  return (
    <Text variant="subtitle2" {...restProps}>
      {children}
    </Text>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

Title.displayName = 'Alert.Title';

export default Title;
