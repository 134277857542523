import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Email({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.62761c0-.89746.72754-1.625 1.625-1.625h15c.8975 0 1.625.72754 1.625 1.62501v5.33336c0 .34522-.2798.62502-.625.62502S19 10.3062 19 9.96098V5.03833l-7.1638 5.84497c-.414.3377-1.0084.3377-1.4224 0L3.25 5.03833V15.682c0 .2071.16789.375.375.375h7.5c.3452 0 .625.2798.625.625s-.2798.625-.625.625h-7.5c-.89746 0-1.625-.7275-1.625-1.625V4.62761Zm9.125 5.22266L4.26429 4.25261H17.9857L11.125 9.85027Zm2 6.48033c0 2.5707 2.09 4.6694 4.65 4.6694h2.4l-.05-1.205h-2.35c-1.87 0-3.45-1.5866-3.45-3.4644 0-1.8779 1.6-3.4645 3.47-3.4645s3.45 1.5866 3.45 3.4645v.6426c0 .2812-.27.5624-.53.5624s-.53-.2812-.53-.5624v-.6426c0-1.3256-1.08-2.4101-2.4-2.4101-1.32 0-2.4 1.0845-2.4 2.4101 0 1.3255 1.08 2.41 2.4 2.41.59 0 1.17-.231 1.54-.6326.33.4016.85.6326 1.38.6326.97 0 1.72-.7732 1.72-1.7674v-.6426c0-2.5708-2.09-4.6695-4.65-4.6695-2.56 0-4.65 2.0987-4.65 4.6695Zm3.45 0c0-.6628.54-1.2051 1.2-1.2051.66 0 1.2.5423 1.2 1.2051 0 .6627-.54 1.205-1.2 1.205-.66 0-1.2-.5423-1.2-1.205Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Email.propTypes = {
  className: PropTypes.string,
};

export default Email;
