import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  page: 1,
  perPage: 25,
  patientType: 'all',
  woundStatus: null,
  clinics: [],
  sortBy: 'lastVisit',
  sortingOrder: 'DESC',
};

export const sliceKey = 'patientsFilters';

function updateSortingOrder(state, nextSortBy) {
  switch (true) {
    case state.sortBy !== nextSortBy:
      return initialState.sortingOrder;
    case state.sortBy === nextSortBy && state.sortingOrder === 'DESC':
      return 'ASC';
    case state.sortBy === nextSortBy && state.sortingOrder === 'ASC':
      return 'DESC';
    default:
      return null;
  }
}

const patientsFilters = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    updateFilters(state, action) {
      const { name, value } = action.payload;
      state[name] = value;
    },
    toggleSortDirection(state, action) {
      const sortBy = action.payload;
      state.sortBy = sortBy;
      state.sortingOrder = updateSortingOrder(state, sortBy);
    },
    applyBatchFilters(state, action) {
      const { patientType, woundStatus, clinics } = action.payload;
      state.patientType = patientType ?? 'all';
      state.woundStatus = woundStatus ?? null;
      state.clinics = clinics ?? [];
      state.page = 1;
    },
    resetFilters(state) {
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

export const { reducer, actions } = patientsFilters;
