import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Headset({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65157 5.31543c-.73526.9262-1.12019 1.97587-1.24304 2.73895.54866-.14392 1.07789-.17955 1.50859-.11614.3067.04516.53395.30832.53395.61833v8.06603c0 .3452-.27982.625-.625.625-1.84739 0-3.07543-.7045-3.82756-1.6466-.73035-.9148-.97135-2.0011-.97135-2.7425V11.783c0-.0334.00268-.0668.00802-.0998.24236-1.4988 1.10961-2.4973 2.08043-3.08333-.00098-.01431-.00147-.02875-.00147-.04331 0-1.04769.48128-2.66146 1.55841-4.01832C7.77062 3.155 9.50753 2.01645 12 2.01645c1.4252 0 3.0193.43924 4.3272 1.48035 1.3207 1.0513 2.311 2.68636 2.5552 4.99398.0039.03689.0045.07337.0021.10915.9708.58603 1.838 1.58457 2.0803 3.08327.0054.033.008.0664.008.0998v1.0755c0 .7414-.241 1.8277-.9713 2.7425-.2959.3706-.6654.7045-1.1174.9731.0034.044.0022.0889-.0041.1343-.2307 1.6645-.9721 2.6918-1.8812 3.2552-.6525.4043-1.3527.5455-1.9341.5467C14.8391 21.3677 14.0586 22 13.1304 22H11.913c-1.1045 0-1.99998-.8954-1.99998-2v-.3019c0-1.1046.89548-2 1.99998-2h1.2174c.9542 0 1.7524.6682 1.952 1.5623.373-.004.8341-.0967 1.258-.3594.4681-.29.9444-.8203 1.192-1.7971-.4099.0928-.8618.1437-1.3585.1437-.3452 0-.625-.2798-.625-.625V8.55657c0-.31001.2273-.57317.534-.61833.4219-.06212.9384-.02918 1.4751.10749-.2985-1.67647-1.0696-2.82299-2.0093-3.57094C14.4922 3.63381 13.1835 3.26645 12 3.26645c-2.06088 0-3.45441.92278-4.34843 2.04898ZM4.27716 11.8352v1.0233c0 .5133.17639 1.309.69821 1.9626.42562.5331 1.11121 1.0111 2.2257 1.1415V9.17428c-.42479.04684-.94632.19815-1.43904.49548-.69678.42044-1.2999 1.11024-1.48487 2.16544Zm15.44564 1.0233v-1.0233c-.1849-1.0552-.7881-1.745-1.4848-2.16544-.4927-.29733-1.0143-.44864-1.4391-.49548v6.78832c1.1145-.1304 1.8001-.6084 2.2257-1.1415.5219-.6536.6982-1.4493.6982-1.9626Zm-7.8098 6.0896h1.2174c.4142 0 .75.3358.75.75V20c0 .4142-.3358.75-.75.75H11.913c-.4142 0-.75-.3358-.75-.75v-.3019c0-.4142.3358-.75.75-.75Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Headset.propTypes = {
  className: PropTypes.string,
};

export default Headset;
