import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function UserCheck({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8172 9.61026c1.7719 0 3.1991-1.42883 3.1991-3.18013S12.5891 3.25 10.8172 3.25 7.61805 4.67883 7.61805 6.43013 9.0453 9.61026 10.8172 9.61026Zm0 1.25004c2.4572 0 4.4491-1.98348 4.4491-4.43017C15.2663 3.98344 13.2744 2 10.8172 2 8.35999 2 6.36805 3.98344 6.36805 6.43013c0 2.44669 1.99194 4.43017 4.44915 4.43017ZM21.785 12.596c.2647.2216.2997.6157.0782.8805l-6.9518 8.3064c-.115.1374-.2834.2189-.4625.2237-.1791.0048-.3517-.0675-.4739-.1985l-3.6149-3.8764c-.2354-.2524-.2216-.6479.0308-.8833.2525-.2354.648-.2216.8834.0308l3.1325 3.3591 6.4978-7.7641c.2215-.2647.6157-.2997.8804-.0782Zm-6.2403.4736c-1.5514-.7724-5.3215-2.0367-8.92972.0163-1.29767.7383-3.69834 3.0089-4.53303 6.7965-.2563 1.1631.68915 2.1244 1.7748 2.1244h6.40415c.3452 0 .625-.2798.625-.625 0-.3451-.2798-.625-.625-.625H3.85675c-.39383 0-.61619-.3236-.55409-.6054.7518-3.4115 2.91187-5.3995 3.93049-5.9791 3.06545-1.7442 6.33985-.688 7.75445.0163.309.1538.6842.0281.8381-.2809.1538-.309.028-.6842-.281-.8381Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

UserCheck.propTypes = {
  className: PropTypes.string,
};

export default UserCheck;
