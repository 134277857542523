import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Minimize({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="m2.6824 3.08354 5.00587 4.99241H5.15493c-.35466 0-.62826.28354-.62826.62785 0 .3443.28373.62785.62826.62785H8.7016c.62827 0 1.13493-.50633 1.13493-1.13418V4.65316c0-.35443-.28373-.62784-.62826-.62784-.34454 0-.62827.28354-.62827.62784v2.52152l-4.99573-4.9924c-.25334-.24304-.64854-.24304-.90187 0-.2432.25316-.2432.6481 0 .90126ZM7.68827 15.9241H5.15493c-.35466 0-.62826-.2836-.62826-.6279s.28373-.6278.62826-.6278H8.7016c.62827 0 1.13493.5063 1.13493 1.1341v3.5443c0 .3545-.28373.6279-.62826.6279-.34454 0-.62827-.2836-.62827-.6279v-2.5215l-4.99573 4.9924c-.2432.2431-.64854.2431-.89174 0-.2432-.243-.2432-.6481 0-.8911l4.99574-4.9924v-.0101ZM20.4157 2.18228 15.42 7.17468V4.65316c0-.35443-.2837-.62784-.6283-.62784-.3445 0-.6282.28354-.6282.62784v3.54431c0 .62785.5066 1.13418 1.1349 1.13418h3.5467c.3546 0 .6282-.28355.6282-.62785 0-.34431-.2837-.62785-.6282-.62785h-2.5232l4.9957-4.99241c.2432-.24303.2432-.6481 0-.89113-.2432-.24304-.6485-.24304-.8917 0l-.0102-.01013Zm-4.104 13.74182h2.5232c.3547 0 .6283-.2836.6283-.6279s-.2837-.6278-.6283-.6278h-3.5466c-.6283 0-1.135.5063-1.135 1.1341v3.5443c0 .3545.2838.6279.6283.6279s.6283-.2836.6283-.6279v-2.5215l4.9957 4.9924c.2432.2431.6485.2431.8917 0 .2432-.243.2432-.6481 0-.8911l-4.9957-4.9924.0101-.0101Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Minimize.propTypes = {
  className: PropTypes.string,
};

export default Minimize;
