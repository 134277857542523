import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ImportAlt({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.125C3 3.9514 3.95139 3 5.125 3h9.5c.3452 0 .625.27982.625.625s-.2798.625-.625.625h-9.5c-.48325 0-.875.39175-.875.875v14c0 .4832.39175.875.875.875h14c.4832 0 .875-.3918.875-.875v-9.5c0-.34518.2798-.625.625-.625s.625.27982.625.625v9.5c0 1.1736-.9514 2.125-2.125 2.125h-14C3.95139 21.25 3 20.2986 3 19.125v-14ZM10.625 8c.3452 0 .625.27982.625.625v3.7197l7.4264-7.6549c.2404-.24774.636-.25374.8838-.01339.2477.24036.2537.63604.0134.88379L12.3559 13h3.2691c.3452 0 .625.2798.625.625s-.2798.625-.625.625h-5c-.3452 0-.625-.2798-.625-.625v-5c0-.34518.2798-.625.625-.625Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ImportAlt.propTypes = {
  className: PropTypes.string,
};

export default ImportAlt;
