import PropTypes from 'prop-types';
import * as RadixDropdown from '@radix-ui/react-tabs';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { colors, spacing } from '../../theme';
import Text from '../Text/Text';

const triggerCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: ${spacing.space2} ${spacing.space6};
  background-color: transparent;
  border: none;
  border-block-end: 1px solid ${colors.gray500};

  &[data-state='active'] {
    box-shadow: inset 0 0px 0 0 currentColor, 0 1px 0 0 currentColor;
    color: ${colors.primary};
    transition:  box-shadow ease-in-out .3s;

    .HioText--body2 {
      font-weight: 600;
      border-color: currentColor;
    }
  }

  &:hover:not(:disabled) {
    color: ${colors.primary};
  }

  &:disabled {
    color: ${colors.gray600};
  }

`;

function Trigger({ children, className, value, isDisabled }) {
  return (
    <RadixDropdown.Trigger
      value={value}
      className={cx(className, 'HioTabs__trigger')}
      disabled={isDisabled}
      css={triggerCss}
    >
      <Text variant="body2">{children}</Text>
    </RadixDropdown.Trigger>
  );
}

Trigger.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

Trigger.displayName = 'Tabs.Trigger';

export default Trigger;
