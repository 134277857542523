import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';

function ArrowRight({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.5017 4.69254c-.3079-.2771-.7821-.25214-1.0592.05574-.2771.30789-.2521.7821.0558 1.0592L17.5455 11.25H3.75c-.41421 0-.75.3358-.75.75s.33579.75.75.75h13.7955l-6.0472 5.4425c-.3079.2771-.3329.7513-.0558 1.0592.2771.3079.7513.3329 1.0592.0558l7.5-6.75c.0292-.0263.056-.0545.0804-.0846.0996-.1226.1584-.2741.1669-.4332.0007-.0132.001-.0264.001-.0397 0-.186-.0691-.3644-.1922-.5014-.0176-.0196-.0363-.0383-.0561-.0561m-7.5-6.74996 7.4973 6.74756-7.4973-6.74756Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}

ArrowRight.propTypes = {
  className: PropTypes.string,
};

export default ArrowRight;
