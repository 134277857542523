import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  body1: {
    fontSize: ({ inheritSize }) => (inheritSize ? 'inherit' : '0.85rem'),
    fontWeight: ({ fontWeight }) => fontWeight,
    display: 'inline',
  },
}));

function Text({
  children,
  color = 'textPrimary',
  fontWeight = 400,
  inheritSize = false,
  classes = {},
  ...restProps
}) {
  const innerClasses = useStyles({ fontWeight, inheritSize });
  return (
    <Typography
      color={color}
      classes={{ ...classes, body1: innerClasses.body1 }}
      {...restProps}
    >
      {children}
    </Typography>
  );
}

Text.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  classes: PropTypes.object,
  inheritSize: PropTypes.bool,
};

export default Text;
