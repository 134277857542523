import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  questionnaire: { patient: {}, wound: {}, observation: {}, carePlan: null },
  questionnaireVersion: '',
  showIncompleteAlert: false,
};

export const sliceKey = 'questionnaire';

const questionnaireSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    getQuestionnaireSuccess(state, action) {
      const { questionnaireVersion, ...questionnaire } = action.payload;
      state.questionnaire = questionnaire;
      state.questionnaireVersion = questionnaireVersion;
    },
    setShowIncompleteAlert(state, { payload: show }) {
      state.showIncompleteAlert = show;
    },
  },
});

export const { getQuestionnaireSuccess, setShowIncompleteAlert } =
  questionnaireSlice.actions;

export const { reducer } = questionnaireSlice;
