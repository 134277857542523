import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Sync({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64935 2c.35863 0 .64935.29072.64935.64935v4.4192C5.01734 4.04254 8.26953 2 12 2c5.1 0 9.3075 3.81726 9.9227 8.7508.0443.3559-.2082.6804-.564.7247-.3559.0444-.6804-.2081-.7248-.564C20.0988 6.61993 16.4368 3.2987 12 3.2987c-3.29961 0-6.1715 1.83678-7.64677 4.54546h4.14028c.35862 0 .64935.29072.64935.64935 0 .35862-.29073.64935-.64935.64935H2.64935C2.29072 9.14286 2 8.85213 2 8.49351V2.64935C2 2.29072 2.29072 2 2.64935 2Zm-.00801 10.5245c.35587-.0444.68034.2081.72471.564.53516 4.2916 4.19719 7.6128 8.63395 7.6128 3.2996 0 6.1715-1.8368 7.6468-4.5455h-4.1403c-.3586 0-.6494-.2907-.6494-.6493s.2908-.6494.6494-.6494h5.8441c.3587 0 .6494.2908.6494.6494v5.8441c0 .3587-.2907.6494-.6494.6494-.3586 0-.6493-.2907-.6493-.6494v-4.4192C18.9827 19.9575 15.7305 22 12 22c-5.10001 0-9.30746-3.8173-9.92267-8.7508-.04437-.3559.20814-.6804.56401-.7247Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Sync.propTypes = {
  className: PropTypes.string,
};

export default Sync;
