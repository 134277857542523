import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Activity({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fill="currentColor"
        d="M19.7779 9.19447c.537 0 .9722-.43529.9722-.97224S20.3149 7.25 19.7779 7.25c-.5369 0-.9722.43528-.9722.97223s.4353.97224.9722.97224Zm0 1.25003c1.2273 0 2.2222-.99496 2.2222-2.22227C22.0001 6.99493 21.0052 6 19.7779 6s-2.2222.99493-2.2222 2.22223c0 .38639.0986.74975.272 1.06628l-2.5391 2.53909c-.3165-.1734-.6799-.272-1.0663-.272-.3864 0-.7498.0986-1.0663.2721l-1.428-1.428c.1734-.3166.2721-.67994.2721-1.06635 0-1.22731-.995-2.22223-2.22228-2.22223-1.22731 0-2.22224.99492-2.22224 2.22223 0 .38639.09862.74975.27205 1.06625l-2.53912 2.5391c-.31653-.1734-.67989-.272-1.06628-.272C2.99493 12.6667 2 13.6616 2 14.8889s.99493 2.2223 2.22223 2.2223c1.22731 0 2.22224-.995 2.22224-2.2223 0-.3864-.09862-.7497-.27207-1.0663l2.53911-2.5391c.31653.1735.6799.2721 1.06631.2721.38638 0 .74978-.0986 1.06628-.2721l1.428 1.4281c-.1734.3165-.272.6798-.272 1.0662 0 1.2273.9949 2.2223 2.2222 2.2223s2.2223-.995 2.2223-2.2223c0-.3864-.0987-.7498-.2721-1.0663l2.5391-2.5391c.3165.1734.6799.2721 1.0663.2721Zm-10.00008-.1389c-.53695 0-.97224-.4353-.97224-.97225 0-.53695.43529-.97223.97224-.97223.53698 0 .97228.43528.97228.97223s-.4353.97225-.97228.97225Zm-5.55559 5.5556c-.53695 0-.97223-.4353-.97223-.9723 0-.5369.43528-.9722.97223-.9722s.97224.4353.97224.9722c0 .537-.43529.9723-.97224.9723Zm10.97237-2.0834c0 .537-.4353.9723-.9723.9723-.5369 0-.9722-.4353-.9722-.9723 0-.5369.4353-.9722.9722-.9722.537 0 .9723.4353.9723.9722Z"
      />
    </SvgIcon>
  );
}

Activity.propTypes = {
  className: PropTypes.string,
};

export default Activity;
