import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { zIndex } from '../../theme';
import Button from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import Icon from '../Icon/Icon';
import { useSplitButtonContext } from './useSplitButtonContext';

const dropdownBtnCss = (props) => css`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  ${!props.isContained &&
  css`
    margin-inline-start: -1px;
  `}

  :focus {
    z-index: ${zIndex.focus};
  }
`;

function _Dropdown({ children, className, isDisabled }) {
  const { appearance, isContained, size } = useSplitButtonContext();

  return (
    <Dropdown className={cx('HioSplitButton__dropdown', className)}>
      <Dropdown.Trigger isDisabled={isDisabled}>
        <Button
          appearance={appearance}
          css={dropdownBtnCss({ isContained })}
          isIcon
          isDisabled={isDisabled}
          size={size}
        >
          <Icon icon="chevron-down" />
        </Button>
      </Dropdown.Trigger>
      {children}
    </Dropdown>
  );
}

_Dropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

_Dropdown.displayName = 'SplitButton.Dropdown';

export default _Dropdown;
