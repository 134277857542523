import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const checkIfIsRtl = (language) => language === 'he';

function useIsRtl() {
  const {
    i18n: { language },
  } = useTranslation();
  const [isRtl, setIsRtl] = useState(checkIfIsRtl(language));
  useEffect(() => {
    setIsRtl(checkIfIsRtl(language));
  }, [language]);
  return isRtl;
}

export default useIsRtl;
