import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function PauseCircle({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.75c4.8325 0 8.75-3.9175 8.75-8.75 0-4.83249-3.9175-8.75-8.75-8.75-4.83249 0-8.75 3.91751-8.75 8.75 0 4.8325 3.91751 8.75 8.75 8.75ZM22 12c0 5.5228-4.4772 10-10 10-5.52285 0-10-4.4772-10-10C2 6.47715 6.47715 2 12 2c5.5228 0 10 4.47715 10 10Zm-8-3.625c.3452 0 .625.27982.625.625v6c0 .3452-.2798.625-.625.625s-.625-.2798-.625-.625V9c0-.34518.2798-.625.625-.625ZM10.625 9c0-.34518-.2798-.625-.625-.625-.34518 0-.625.27982-.625.625v6c0 .3452.27982.625.625.625.3452 0 .625-.2798.625-.625V9Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

PauseCircle.propTypes = {
  className: PropTypes.string,
};

export default PauseCircle;
