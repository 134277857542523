import { useNavigate } from 'react-router-dom';

import { AuthProvider } from '@packages/healthy-auth';

import AuthenticatedApp from 'containers/App/authenticated-app-loadable';
import UnauthenticatedApp from 'containers/App/unauthenticated-app-loadable';

import Loader from 'components/Loader';

function AppAuthProvider() {
  const navigate = useNavigate();

  return (
    <AuthProvider
      AuthenticatedApp={AuthenticatedApp}
      UnauthenticatedApp={UnauthenticatedApp}
      clientId={process.env.USER_MANAGEMENT_CLIENT_ID}
      redirectUri={window.location.origin}
      options={{
        userClaimsMultiRegion: {
          uk: `${process.env.BASE_URL_UK}/userClaims`,
          us: `${process.env.BASE_URL_US}/userClaims`,
        },
        singleUseTokenMultiRegion: {
          uk: `${process.env.BASE_URL_UK}/validateSingleUseToken`,
          us: `${process.env.BASE_URL_US}/validateSingleUseToken`,
        },
        externalAuthProviders: ['epic', 'mcb', 'cerner', 'dr_chrono'],
        CustomLoader: <Loader />,
      }}
      navigate={navigate}
    />
  );
}

export default AppAuthProvider;
