import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Heart({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M11.9877 21c-.1769 0-.3537-.0671-.5526-.1901-2.19918-1.4534-4.03367-2.9403-5.59188-4.5391-1.35929-1.3863-2.29864-2.6497-2.96171-3.9578-.75148-1.4869-1.01671-2.87325-.81779-4.25958C2.48367 5.16894 4.95913 3 7.84348 3c1.10511 0 2.17712.3354 3.10532.96149.2653.17888.5084.39131.7626.59255l.3536.29068.0442-.1118C13.1701 3.67081 14.4631 3.08944 15.9329 3h.2763c1.2267 0 2.4423.41366 3.4369 1.17391 1.072.81615 1.8345 1.99007 2.166 3.30932.0553.2236.0995.45838.1326.68199L22 8.47826v.92795c-.0111.06708-.0221.1118-.0332.15652-.0773.68197-.2431 1.34157-.5083 1.97887-.5194 1.2857-1.304 2.4932-2.4755 3.8124-1.7239 1.9454-3.8347 3.723-6.4649 5.4559-.1879.123-.3536.1789-.5304.1789V21ZM7.77717 4.38634c-2.09972 0-4.01157 1.64347-4.35416 3.74534-.03315.20124-.05525.41366-.0663.559v.15653c0 1.10683.28733 2.09069.90619 3.19749.70728 1.2522 1.64662 2.4149 3.05012 3.7565 1.31509 1.2634 2.81808 2.4597 4.57518 3.6448l.21.0559-.0332-.0448c2.6744-1.8223 4.6525-3.5329 6.2218-5.3888 1.0278-1.2074 1.6798-2.3031 2.0555-3.4323.6852-2.03476.0774-4.13662-1.5361-5.35525-.7625-.58137-1.6798-.88323-2.6302-.88323-.221 0-.442.02236-.663.0559-1.1714.17888-2.1992.81615-2.9065 1.8-.1436.20124-.3205.38012-.6078.3913-.2542 0-.442-.1118-.5968-.3354-.7183-1.01739-1.70183-1.6323-2.92851-1.8559-.23207-.04472-.4752-.06708-.70727-.06708h.01105Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Heart.propTypes = {
  className: PropTypes.string,
};

export default Heart;
