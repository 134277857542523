import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { fontSizes } from '../../theme';
import Text from '../Text/Text';

const headingCss = css`
  &.HioHeading--h1 {
    font-size: ${fontSizes.h1};
    font-weight: 900;
    line-height: 1.2;
  }

  &.HioHeading--h2 {
    font-size: ${fontSizes.h2};
    font-weight: 900;
    line-height: 1.2;
  }

  &.HioHeading--h3 {
    font-size: ${fontSizes.h3};
    font-weight: 900;
    line-height: 1.25;
  }

  &.HioHeading--h4 {
    font-size: ${fontSizes.h4};
    font-weight: 900;
    line-height: 1.2;
  }

  &.HioHeading--h5 {
    font-size: ${fontSizes.h5};
    font-weight: 900;
    line-height: 1.2;
  }

  &.HioHeading--h6 {
    font-size: ${fontSizes.h6};
    font-weight: 900;
    line-height: 1.25;
  }

  &.HioHeading--flushMargin {
    margin-block: 0;
  }
`;

/**
 * Heading is used to render semantic HTML heading elements.
 */
const Heading = forwardRef(
  ({ as, children, className, flushMargin, variant }, ref) => (
    <Text
      as={as}
      css={headingCss}
      className={cx(
        'HioHeading__root',
        {
          'HioHeading--flushMargin': flushMargin,
          [`HioHeading--${variant ?? as}`]: Boolean(variant || as),
        },
        className,
      )}
      ref={ref}
    >
      {children}
    </Text>
  ),
);

Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  /** Remove margin spacing */
  flushMargin: PropTypes.bool,
  /** Override the heading's styling */
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export default Heading;
