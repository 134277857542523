import PropTypes from 'prop-types';
import { Children } from 'react';
import { css } from '@emotion/react';
import { cx } from '@emotion/css';

import { colors, spacing } from '../../theme';

import Text from '../Text/Text';

const contentCss = css`
  display: flex;
  align-items: flex-start;
  word-break: break-word;
  gap: ${spacing.space2};
  color: ${colors.text};
`;

const directionCss = (contentDirection) => css`
  flex-direction: ${contentDirection};
`;

function Content({ children, className, direction = 'column', ...restProps }) {
  const parsedChildren = Children.map(children, (child) => {
    if (typeof child === 'string') {
      return <Text variant="body2">{child}</Text>;
    }
    return child;
  });

  return (
    <div
      css={[contentCss, directionCss(direction)]}
      className={cx(['HioAlert__content', className])}
      {...restProps}
    >
      {parsedChildren}
    </div>
  );
}

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** The alert's content direction */
  direction: PropTypes.oneOf(['column', 'row']),
};

Content.displayName = 'Alert.Content';

export default Content;
