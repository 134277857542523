import PropTypes from 'prop-types';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { cx } from '@emotion/css';

function Sub({ children, className }) {
  return (
    <RadixDropdown.Sub className={cx(className, 'HioDropdown__sub')}>
      {children}
    </RadixDropdown.Sub>
  );
}

Sub.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Sub.displayName = 'Dropdown.Sub';

export default Sub;
