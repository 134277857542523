import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

// TODO: Replace path asset, as its original view-box was 24x25
// https://github.com/OwnHealthIL/technodrome/issues/149
function Link({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M14.4046 10.1716c-1.6272-1.56212-4.2654-1.56212-5.89254 0l-4.16667 4c-1.62719 1.5621-1.62719 4.0947 0 5.6568 1.62718 1.5621 4.26537 1.5621 5.89251 0l1.1475-1.1015m-.79-4.8985c1.6272 1.5621 4.2654 1.5621 5.8925 0l4.1667-3.99997c1.6272-1.5621 1.6272-4.09476 0-5.65686-1.6272-1.56209-4.2654-1.56209-5.8925 0l-1.1455 1.09961"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

Link.propTypes = {
  className: PropTypes.string,
};

export default Link;
