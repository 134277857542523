import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Layers({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2795 1.44098c-.1759-.08797-.383-.08797-.559 0l-9.99999 5c-.21174.10587-.3455.32229-.3455.55902 0 .23673.13376.45315.3455.55902L11.7205 12.559c.176.088.3831.088.559 0l10-4.99998c.2118-.10587.3455-.32229.3455-.55902 0-.23673-.1337-.45315-.3455-.55902l-10-5ZM12 11.3012 3.39756 7 12 2.69877 20.6025 7 12 11.3012Zm-10.559.4193c.15437-.3087.52979-.4339.83852-.2795L12 16.3012l9.7205-4.8602c.3087-.1544.6842-.0292.8385.2795.1544.3087.0293.6841-.2795.8385l-10 5c-.1759.088-.383.088-.559 0l-9.99999-5c-.30874-.1544-.43388-.5298-.27951-.8385Zm0 5c.15437-.3087.52979-.4339.83852-.2795L12 21.3012l9.7205-4.8602c.3087-.1544.6842-.0292.8385.2795.1544.3087.0293.6841-.2795.8385l-10 5c-.1759.088-.383.088-.559 0l-9.99999-5c-.30874-.1544-.43388-.5298-.27951-.8385Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Layers.propTypes = {
  className: PropTypes.string,
};

export default Layers;
