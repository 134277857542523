import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Pencil({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5714 3.34305c.1506-.14218.39-.13729.5346.01091l3.5282 3.61447c.1447.1482.1399.38361-.0107.52578l-2.8981 2.73609-4.1382-4.06983 2.9842-2.81742ZM12.678 7.01876 5.14784 14.128c-.04587.0433-.07987.0973-.09888.157l-1.72072 5.4017 5.70888-1.3253c.06575-.0153.12612-.0476.17484-.0936l7.60434-7.1792-4.1383-4.06984Zm5.3369-4.52291c-.6268-.64222-1.6641-.66339-2.3166-.04729L4.27469 13.2335c-.19874.1877-.34611.4216-.42848.6802L2.0593 19.5232c-.26288.8252.48796 1.6186 1.34377 1.4199l5.92439-1.3753c.28489-.0661.54652-.2062.75764-.4055L21.4966 8.38871c.6526-.61611.6734-1.63617.0465-2.27839l-3.5282-3.61447Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Pencil.propTypes = {
  className: PropTypes.string,
};

export default Pencil;
