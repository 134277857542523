import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function QuestionCircle({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M12 20.76c-4.84 0-8.76-3.92-8.76-8.76S7.16 3.24 12 3.24s8.76 3.92 8.76 8.76-3.92 8.76-8.76 8.76ZM12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10Zm.03-6.67c-.51 0-.99.45-.97.97.02.53.43.97.97.97.51 0 .99-.45.97-.97-.02-.53-.43-.97-.97-.97Zm0-9.01c-1.67 0-3.02 1.36-3.02 3.02 0 .34.27.61.61.61.34 0 .61-.27.61-.61 0-1 .81-1.81 1.81-1.81s1.81.81 1.81 1.81-.81 1.81-1.81 1.81c-.34 0-.61.27-.61.61v1.59c0 .34.27.61.61.61.34 0 .61-.27.61-.61v-1.04c1.38-.28 2.41-1.5 2.41-2.96 0-1.67-1.36-3.02-3.02-3.02l-.01-.01Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

QuestionCircle.propTypes = {
  className: PropTypes.string,
};

export default QuestionCircle;
