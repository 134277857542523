import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Plus({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.625 19c0 .3452-.2798.625-.625.625s-.625-.2798-.625-.625v-6.375H4.99998c-.34517 0-.625-.2798-.625-.625 0-.3451.27983-.625.625-.625H11.375V5c0-.34518.2798-.625.625-.625s.625.27982.625.625v6.375H19c.3452 0 .625.2799.625.625 0 .3452-.2798.625-.625.625h-6.375V19Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Plus.propTypes = {
  className: PropTypes.string,
};

export default Plus;
