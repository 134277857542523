import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Icons } from '../../constants';
import { toRem } from '../../helpers';
import { borderRadius, colors, fontSizes, spacing } from '../../theme';
import FormError from '../FormError/FormError';
import FormHint from '../FormHint/FormHint';
import FormLabel from '../FormLabel/FormLabel';
import Icon from '../Icon/Icon';

const inputWrapperCss = css`
  position: relative;
`;

const textInputCss = (props) => css`
  color: ${colors.black};
  border: 1px solid;
  border-color: ${colors.gray600};
  border-radius: ${borderRadius.lg};
  height: ${toRem(40)};
  padding-inline: ${spacing.space3};
  font-size: ${fontSizes.body2};
  width: 100%;

  :hover,
  :focus {
    :not(:disabled) {
      border-color: ${colors.primary};
    }
  }

  :disabled {
    background-color: ${colors.gray200};
    cursor: not-allowed;
  }

  /** Error */
  ${props.isError &&
  css`
    border-color: ${colors.danger};

    :hover,
    :focus {
      :not(:disabled) {
        border-color: ${colors.danger600};
      }
    }
  `}

  /** With icon */
  ${props.isWithIcon &&
  css`
    padding-inline-end: ${spacing.space7};
  `}
`;

const iconCss = css`
  position: absolute;
  top: 0;
  right: ${spacing.space3};
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
`;

const TextInput = forwardRef(
  (
    {
      className,
      error,
      hint,
      icon,
      id,
      isDisabled,
      isError,
      isReadOnly,
      isRequired,
      label,
      name,
      onBlur,
      onChange,
      placeholder,
      type,
      value,
      ...restTextInputProps
    },
    ref,
  ) => {
    const _isError = Boolean(error) || isError;
    const isWithIcon = Boolean(icon);

    return (
      <div className={cx('HioTextInput__root', className)}>
        {/* Label */}
        {label && (
          <FormLabel htmlFor={id} isRequired={isRequired}>
            {label}
          </FormLabel>
        )}

        <div
          css={inputWrapperCss}
          className={cx('HioTextInput__input-wrapper')}
        >
          {/* Input */}
          <input
            css={textInputCss({ isError: _isError, isWithIcon })}
            className={cx('HioTextInput__input', {
              'HioTextInput__input--disabled': isDisabled,
              'HioTextInput__input--error': _isError,
              'HioTextInput__input--readonly': isReadOnly,
              'HioTextInput__input--with-icon': isWithIcon,
            })}
            id={id}
            disabled={isDisabled}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={isReadOnly}
            ref={ref}
            type={type}
            value={value}
            {...restTextInputProps}
          />

          {/* Icon */}
          {isWithIcon && (
            <span css={iconCss} className={cx('HioTextInput__icon')}>
              <Icon icon={icon} size="sm" />
            </span>
          )}
        </div>

        {/* Hint */}
        {hint && !error && <FormHint>{hint}</FormHint>}

        {/* Error */}
        {error && <FormError>{error}</FormError>}
      </div>
    );
  },
);

TextInput.propTypes = {
  className: PropTypes.string,
  /** Adds an error message (using `FormError`). Also adds an error indication to input */
  error: PropTypes.string,
  /** Adds a label (using `FormHint`) */
  hint: PropTypes.string,
  /** Adds an icon at the end of the input */
  icon: PropTypes.oneOf(Object.values(Icons)),
  isDisabled: PropTypes.bool,
  /** Adds an error indication */
  isError: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  id: PropTypes.string,
  /** Adds a label (using `FormLabel`) */
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default TextInput;
