import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ChevronRight({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52965 19.4116c.2273.2597.62215.2861.88192.0588l8.00003-7c.1356-.1187.2134-.2902.2134-.4704 0-.1802-.0778-.3517-.2134-.4704L8.41157 4.52963c-.25977-.2273-.65462-.20098-.88192.0588-.2273.25977-.20098.65462.05879.88192L15.0509 12l-7.46246 6.5296c-.25977.2273-.28609.6222-.05879.882Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ChevronRight.propTypes = {
  className: PropTypes.string,
};

export default ChevronRight;
