import PropTypes from 'prop-types';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';

import { toRem } from '../../../helpers';
import { colors, spacing } from '../../../theme';
import Checkbox from '../../Checkbox/Checkbox';

const optionCss = ({ isFocused, isMulti }) => css`
  height: ${toRem(40)};
  padding-inline: ${spacing.space3};
  cursor: pointer;
  color: ${colors.text};
  background-color: transparent;
  display: flex;
  align-items: center;
  column-gap: ${isMulti ? spacing.space1 : spacing.space4};

  // Hover and focus state
  ${isFocused &&
  css`
    background-color: ${colors.gray200};
  `}
`;

function Option(props) {
  const {
    children,
    label,
    value,
    isFocused,
    isSelected,
    innerProps,
    innerRef,
    selectProps: { isMulti },
  } = props;

  return (
    <div
      className={cx('HioSelect__option', {
        'HioSelect__option--is-focused': isFocused,
      })}
      css={optionCss({ isFocused, isMulti })}
      ref={innerRef}
      {...innerProps}
    >
      {isMulti ? (
        <Checkbox
          id={innerProps.id}
          label={label}
          value={value}
          checked={isSelected}
          ref={innerRef}
        />
      ) : (
        children
      )}
    </div>
  );
}

Option.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

export default Option;
