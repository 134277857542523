import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function PaperClip({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="m12.5744 19.4646-8.07063-7.6303c-1.02484-.969-2.49316-3.52172-.16774-5.98134.96194-1.27406 3.65446-3.09548 6.72897-.1887l8.4549 7.99364c.7687.7267 1.8699 2.6413.1258 4.486-.7267.7686-2.7181 1.7972-4.8703-.2376l-6.53331-6.1769c-.51242-.4844-1.53725-1.4534-.44722-2.60631.36335-.38431 1.09005-1.15294 2.62733.30045l5.7647 5.45026"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

PaperClip.propTypes = {
  className: PropTypes.string,
};

export default PaperClip;
