import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Maximize({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M7.1816 3.26646H4.6584L9.66427 8.2614c.2432.24316.2432.64843 0 .89159-.2432.24316-.64854.24316-.89174 0L3.76667 4.15805v2.5228c0 .35461-.28374.62817-.62827.62817s-.62827-.28369-.62827-.62817v-3.5461C2.5 2.50659 3.00667 2 3.63493 2H7.1816c.35467 0 .62827.28369.62827.62817 0 .34447-.28374.62816-.62827.62816v.01013ZM3.76667 19.8521l4.99573-4.995c.2432-.2431.64853-.2431.89173 0 .2432.2432.2432.6485 0 .8916l-4.99573 4.995h2.5232c.35467 0 .62827.2837.62827.6281 0 .3445-.28374.6282-.62827.6282H3.63493C3.00667 22 2.5 21.4934 2.5 20.8652v-3.5461c0-.3546.28373-.6281.62827-.6281.34453 0 .62826.2837.62826.6281v2.5228l.01014.0102ZM16.8083 3.26646h2.5232L14.3357 8.2614c-.2432.24316-.2432.64843 0 .89159.2432.24316.6486.24316.8918 0l4.9957-4.99494v2.5228c0 .35461.2837.62817.6283.62817.3445 0 .6282-.28369.6282-.62817v-3.5461C21.4797 2.50659 20.9731 2 20.3448 2h-3.5467c-.3546 0-.6282.28369-.6282.62817 0 .34447.2837.62816.6282.62816l.0102.01013Zm3.425 16.58564-4.9957-4.995c-.2432-.2431-.6485-.2431-.8917 0-.2432.2432-.2432.6485 0 .8916l4.9957 4.995h-2.5232c-.3547 0-.6283.2837-.6283.6281 0 .3445.2838.6282.6283.6282h3.5467c.6282 0 1.1349-.5066 1.1349-1.1348v-3.5461c0-.3546-.2837-.6281-.6283-.6281-.3445 0-.6282.2837-.6282.6281v2.5228l-.0102.0102Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Maximize.propTypes = {
  className: PropTypes.string,
};

export default Maximize;
