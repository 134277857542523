import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { cx } from '@emotion/css';
import { borderRadius, colors, elevations } from '../../theme';

const paperCss = (props) => css`
  background: ${colors.white};
  border-radius: ${borderRadius.sm};
  box-shadow: ${elevations.elevation100};

  ${props.isOutlined &&
  css`
    border: 1px solid ${colors.gray300};
  `}
`;

const Paper = forwardRef(
  ({ children, className, isOutlined = true, ...restProps }, ref) => (
    <div
      css={paperCss({ isOutlined })}
      className={cx(className, 'HioPaper__root', {
        'HioPaper--outlined': isOutlined,
      })}
      ref={ref}
      {...restProps}
    >
      {children}
    </div>
  ),
);

Paper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOutlined: PropTypes.bool,
};

export default Paper;
