import PropTypes from 'prop-types';
import * as RadixTabs from '@radix-ui/react-tabs';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { toSpacing } from '../../helpers';

const fullWidthCss = css`
  width: 100%;
  
  .HioTabs__trigger {
    flex-grow: 1;
  }
`;

const listCss = ({ isFullWidth }) => css`
  display: flex;
  margin-block-end: ${toSpacing(3)};
  ${isFullWidth && fullWidthCss}
`;

function List({ children, isFullWidth, className }) {
  return (
    <RadixTabs.List
      css={listCss({ isFullWidth })}
      className={cx([
        'HioTabs__list',
        {
          'HioTabs__list--fullWidth': isFullWidth,
        },
        className,
      ])}
    >
      {children}
    </RadixTabs.List>
  );
}

List.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
};

List.displayName = 'Tabs.List';

export default List;
