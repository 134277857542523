import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function QrCode({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 3.25h3.5v3.5h-3.5v-3.5ZM2 3.25C2 2.55964 2.55964 2 3.25 2h3.5C7.44036 2 8 2.55964 8 3.25v3.5C8 7.44036 7.44036 8 6.75 8h-3.5C2.55964 8 2 7.44036 2 6.75v-3.5Zm15.25 0h3.5v3.5h-3.5v-3.5Zm-1.25 0c0-.69036.5596-1.25 1.25-1.25h3.5c.6904 0 1.25.55964 1.25 1.25v3.5C22 7.44036 21.4404 8 20.75 8h-3.5C16.5596 8 16 7.44036 16 6.75v-3.5Zm-9.25 14h-3.5v3.5h3.5v-3.5ZM3.25 16C2.55964 16 2 16.5596 2 17.25v3.5c0 .6904.55964 1.25 1.25 1.25h3.5C7.44036 22 8 21.4404 8 20.75v-3.5C8 16.5596 7.44036 16 6.75 16h-3.5Zm18.125 5H16.5c-.3452 0-.625.2798-.625.625s.2798.625.625.625h4.875c.3452 0 .625-.2798.625-.625S21.7202 21 21.375 21ZM22 16.625c0 .3452-.2798.625-.625.625h-2.75c-.3452 0-.625-.2798-.625-.625s.2798-.625.625-.625h2.75c.3452 0 .625.2798.625.625Zm-19.5-5.25c-.34518 0-.625.2798-.625.625s.27982.625.625.625h5c.34518 0 .625-.2798.625-.625s-.27982-.625-.625-.625h-5Zm9.5-5.5c.3452 0 .625.27982.625.625V11c0 .2071.1679.375.375.375h4.5c.3452 0 .625.2798.625.625s-.2798.625-.625.625H13c-.8975 0-1.625-.7275-1.625-1.625V6.5c0-.34518.2798-.625.625-.625Zm.65-3.25c0-.34518-.2798-.625-.625-.625s-.625.27982-.625.625v.75c0 .34518.2798.625.625.625s.625-.27982.625-.625v-.75ZM11.375 17.5c0-.8975.7275-1.625 1.625-1.625h2.5c.3452 0 .625.2798.625.625s-.2798.625-.625.625H13c-.2071 0-.375.1679-.375.375v4c0 .3452-.2798.625-.625.625s-.625-.2798-.625-.625v-4Zm10-4.85c.3452 0 .625-.2798.625-.625s-.2798-.625-.625-.625h-.75c-.3452 0-.625.2798-.625.625s.2798.625.625.625h.75Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

QrCode.propTypes = {
  className: PropTypes.string,
};

export default QrCode;
