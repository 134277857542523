import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function EllipsisHorizontal({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.28571 13.3214c.57201 0 1.03571-.4637 1.03571-1.0357 0-.572-.4637-1.0357-1.03571-1.0357-.57201 0-1.03571.4637-1.03571 1.0357 0 .572.4637 1.0357 1.03571 1.0357Zm0 1.25c1.26236 0 2.28571-1.0233 2.28571-2.2857C8.57142 11.0233 7.54807 10 6.28571 10S4 11.0233 4 12.2857c0 1.2624 1.02335 2.2857 2.28571 2.2857Zm5.71419-1.25c.572 0 1.0358-.4637 1.0358-1.0357 0-.572-.4638-1.0357-1.0358-1.0357-.572 0-1.0357.4637-1.0357 1.0357 0 .572.4637 1.0357 1.0357 1.0357Zm0 1.25c1.2624 0 2.2858-1.0233 2.2858-2.2857 0-1.2624-1.0234-2.2857-2.2858-2.2857-1.2623 0-2.28567 1.0233-2.28567 2.2857 0 1.2624 1.02337 2.2857 2.28567 2.2857Zm6.7501-2.2857c0 .572-.4637 1.0357-1.0357 1.0357-.572 0-1.0357-.4637-1.0357-1.0357 0-.572.4637-1.0357 1.0357-1.0357.572 0 1.0357.4637 1.0357 1.0357Zm1.25 0c0 1.2624-1.0233 2.2857-2.2857 2.2857-1.2624 0-2.2857-1.0233-2.2857-2.2857 0-1.2624 1.0233-2.2857 2.2857-2.2857C18.9767 10 20 11.0233 20 12.2857Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

EllipsisHorizontal.propTypes = {
  className: PropTypes.string,
};

export default EllipsisHorizontal;
