import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Copy({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97107 3.25H18.5c.1381 0 .25.11193.25.25v13.6158c0 .1381-.1119.25-.25.25H8.97107c-.13807 0-.25-.1119-.25-.25V3.5c0-.13807.11193-.25.25-.25Zm-1.5.25c0-.82843.67158-1.5 1.5-1.5H18.5c.8284 0 1.5.67157 1.5 1.5v13.6158c0 .8284-.6716 1.5-1.5 1.5h-1.8615v1.2693c0 1.1736-.9514 2.125-2.125 2.125H6.12357c-1.17361 0-2.125-.9514-2.125-2.125V7.37702c0-1.17361.95139-2.125 2.12499-2.125h1.34751V3.5Zm0 3.00202H6.12356c-.48324 0-.87499.39175-.87499.875V19.8851c0 .4832.39175.875.875.875h8.38993c.4833 0 .875-.3918.875-.875v-1.2693H8.97107c-.82842 0-1.5-.6716-1.5-1.5V6.50202Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Copy.propTypes = {
  className: PropTypes.string,
};

export default Copy;
