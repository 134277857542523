import { useSelector } from 'react-redux';

import { selectAuthState } from './selectors';
import useLogout from './useLogout';
import {
  getLoginUrl,
  getExternalPatientIdFromStorage,
  clearExternalPatientIdFromStorage,
} from './utils';

export default function useHealthyAuth() {
  const {
    user,
    additionalClaims,
    errorCode,
    isAuthenticated,
    clientId,
    redirectUri,
  } = useSelector(selectAuthState);

  const { logout } = useLogout();

  return {
    getLoginUrl: ({ email, enableDeepLinking }) =>
      getLoginUrl({ clientId, redirectUri, email, enableDeepLinking }),

    isAuthenticated,
    errorCode,
    userClaims: { ...user, ...additionalClaims },

    getExternalPatientIdFromStorage,
    clearExternalPatientIdFromStorage,
    logout,
  };
}
