import { createSlice } from '@reduxjs/toolkit';
import { set, lowerCase, partition } from 'lodash';

 const partitionTissuesAndRestQuestions = (lockedAssessment) => {
  const [lockedTissuesQuestion, lockedQuestions] = partition(
    lockedAssessment,
    ({ title }) => lowerCase(title) === 'tissue distribution',
  );
  return { lockedTissuesQuestion, lockedQuestions };
};

function getResultKeyFromAnnotationType(annotationType) {
  switch (annotationType) {
    case 'segmentation':
      return 'woundArea';
    case 'cross':
      return 'measurements';
    default:
      return '';
  }
}

export const initialState = {
  // Observation state
  questions: {},
  measurements: {
    unmeasurableMetadata: {},
    depth: {},
    length: {},
    width: {},
    tunnel: {},
    undermine: {},
  },
  answers: {},
  results: {},
  homeAssessment: null,
  integrationState: null,

  lockedAssessment: null,
  // Locked assessment state
  isLocked: false,
  lockedTissuesQuestion: [],
  lockedQuestions: [],

  // App variables
  isAlgoLoading: false,
};

export const sliceKey = 'observation';

const observationSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    fetchObservationSuccess(state, action) {
      const { observationData, answers, measurements } = action.payload;
      Object.entries(observationData).forEach(([key, value]) => {
        state[key] = value;
      });
      state.answers = answers;
      state.measurements = measurements;

      const isLockedAssessment = Boolean(observationData.lockedAssessment);
      state.isLocked = isLockedAssessment;
      if (isLockedAssessment) {
        const { lockedTissuesQuestion, lockedQuestions } = partitionTissuesAndRestQuestions(
          observationData.lockedAssessment,
        );
        state.lockedTissuesQuestion = lockedTissuesQuestion;
        state.lockedQuestions = lockedQuestions;
      }
    },
    setEditedAnnotationsDisplay(state, action) {
      // Manually show the new annotations while waiting for the results.
      const { annotationType, annotations } = action.payload;
      const annotationKeyInResults = getResultKeyFromAnnotationType(annotationType);
      set(
        state,
        ['results', 'algoResult', annotationKeyInResults, 'annotations'],
        annotations,
      );
    },

    setIsAlgoLoading(state, { payload: isLoading }) {
      state.isAlgoLoading = isLoading;
    },
    updateMeasurementsAnswer(state, { payload: { measureName, answer } }) {
      state.measurements[measureName].draftAnswer = answer;
    },
    updateManualAnnotationFields(state, { payload: { measureName, fields } }) {
      state.measurements[measureName].fields = fields;
    },
    updateMeasurementsMode(state, { payload: { measureName, mode, unit } }) {
      state.measurements[measureName].mode = mode;
      if (unit) state.measurements[measureName].unit = unit;
    },
    setMeasurementsError(state, { payload: { measureName, hasError } }) {
      state.measurements[measureName].hasError = hasError;
    },
  },
});

export const { reducer, actions } = observationSlice;
