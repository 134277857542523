import PropTypes from 'prop-types';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { cx } from '@emotion/css';
import { itemCss } from './Item';
import Icon from '../Icon/Icon';

function SubTrigger({ children, className, isDisabled, startIcon }) {
  return (
    <RadixDropdown.SubTrigger
      className={cx(className, 'HioDropdown__sub-trigger', {
        'HioDropdown__sub-trigger--disabled': isDisabled,
      })}
      disabled={isDisabled}
      css={itemCss({ isDisabled })}
    >
      {startIcon && <Icon icon={startIcon} size="sm" />}
      <span>{children}</span>
      <Icon icon="chevron-right" size="sm" />
    </RadixDropdown.SubTrigger>
  );
}

SubTrigger.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  startIcon: PropTypes.string,
};

SubTrigger.displayName = 'Dropdown.SubTrigger';

export default SubTrigger;
