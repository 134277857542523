import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Folder({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M12.1697 7.25539c.1159.10895.269.16961.4281.16961H20.5c.4832 0 .875.39175.875.875v10.2c0 .4833-.3917.875-.875.875h-17c-.48325 0-.875-.3918-.875-.875v-13c0-.48325.39175-.875.875-.875h5.87141l2.79829 2.63039Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

Folder.propTypes = {
  className: PropTypes.string,
};

export default Folder;
