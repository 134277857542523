import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Mailbox({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97498 4.75v-1.5h-3.5v1.5h3.5Zm1.21212-2.02362C10.0681 2.30711 9.68239 2 9.22498 2h-4c-.55229 0-1 .44772-1 1v2c0 .55228.44771 1 1 1H9v.875H8c-1.38103 0-2.87622.46247-4.03534 1.47136C2.79073 9.36815 2 10.9181 2 13v3.75c0 1.0355.83947 1.875 1.875 1.875H7V21c0 .8975.72754 1.625 1.625 1.625h1c.8975 0 1.625-.7275 1.625-1.625v-2.375h9.9402c.5853 0 1.0598-.4745 1.0598-1.0598v-5.2174c0-.7251-.0239-2.0867-.6893-3.2731-.3403-.60675-.8469-1.16657-1.5881-1.56998-.6571-.35769-1.4718-.57797-2.4726-.62168V6.875h-7.25V3c0-.09813-.0226-.19098-.0629-.27362ZM8 8.125h1v2.2432c0 .3451.27982.625.625.625s.625-.2799.625-.625V8.125h3.5204C12.7557 8.9889 12 10.3492 12 12.3478V16.75c0 .2191.0376.4295.1067.625H3.875c-.34518 0-.625-.2798-.625-.625V13c0-1.7374.64677-2.9374 1.53534-3.71077C5.68872 8.50292 6.88103 8.125 8 8.125ZM8.25 21v-2.375H10V21c0 .2071-.16789.375-.375.375h-1c-.20711 0-.375-.1679-.375-.375Zm8.8826-12.875h-.0121c-.6341.00104-1.6144.22369-2.4228.85632-.7823.61224-1.4477 1.64078-1.4477 3.36648V16.75c0 .3452.2798.625.625.625H21v-5.0272c0-.7241-.0386-1.7864-.5295-2.66163-.2378-.42404-.5827-.80444-1.0955-1.08355-.5152-.28046-1.2334-.47664-2.2424-.47762Zm1.9924 5.625c.3452 0 .625.2798.625.625s-.2798.625-.625.625h-4c-.3452 0-.625-.2798-.625-.625s.2798-.625.625-.625h4Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Mailbox.propTypes = {
  className: PropTypes.string,
};

export default Mailbox;
