import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function UploadAlt({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M5 3.375c-.89746 0-1.625.72754-1.625 1.625v14c0 .8975.72754 1.625 1.625 1.625h14c.8975 0 1.625-.7275 1.625-1.625v-6.5294c0-.3452-.2798-.625-.625-.625s-.625.2798-.625.625V19c0 .2071-.1679.375-.375.375H5c-.20711 0-.375-.1679-.375-.375V5c0-.20711.16789-.375.375-.375h6.5294c.3452 0 .625-.27982.625-.625s-.2798-.625-.625-.625H5Z"
        fill="currentColor"
      />
      <path
        d="M20 9.33091c-.3452 0-.625-.27982-.625-.625V5.50889l-6.9331 6.93301c-.244.2441-.6398.2441-.8838 0-.2441-.244-.2441-.6398 0-.8838l6.933-6.93307h-3.197c-.3452 0-.625-.27982-.625-.625s.2798-.625.625-.625H20c.3452 0 .625.27982.625.625v4.70588c0 .34518-.2798.625-.625.625Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

UploadAlt.propTypes = {
  className: PropTypes.string,
};

export default UploadAlt;
