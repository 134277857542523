import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function User({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2394 6.44444c0 1.7342-1.4515 3.19445-3.31 3.19445S8.61939 8.17864 8.61939 6.44444c0-1.73419 1.45151-3.19444 3.31001-3.19444s3.31 1.46025 3.31 3.19444Zm1.25 0c0 2.4546-2.0416 4.44446-4.56 4.44446-2.51843 0-4.56001-1.98986-4.56001-4.44446C7.36939 3.98985 9.41097 2 11.9294 2c2.5184 0 4.56 1.98985 4.56 4.44444ZM4.30945 20.75c-.071 0-.11094-.0263-.12527-.0398.22561-1.3814.78653-3.2789 1.9841-4.8041C7.32513 14.4327 9.1127 13.25 12 13.25c2.5459 0 4.3195.9445 5.5318 2.2884 1.2407 1.3754 1.9535 3.2412 2.1847 5.1185.0009.0077.0006.0117.0004.0128-.0001.0011-.0002.0018-.0005.0025-.0005.0013-.0035.0093-.0151.0214-.0252.0261-.0772.0564-.1519.0564H4.30945ZM19.5494 22c.8284 0 1.5089-.6736 1.4077-1.4958C20.4479 16.3684 17.7928 12 12 12c-6.5944 0-8.56198 5.5207-9.04981 8.5108C2.81679 21.3285 3.48102 22 4.30945 22H19.5494Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

User.propTypes = {
  className: PropTypes.string,
};

export default User;
