import { createSelector } from 'reselect';

import { sliceKey, initialState } from './slice';

export const selectLanguageState = (state) => state[sliceKey] ?? initialState;

export const selectLocale = createSelector(
  selectLanguageState,
  (subState) => subState.locale,
);
