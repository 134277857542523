import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function VolumeMute({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="m7.09684 8.91672 6.63606-4.28575c.683-.38028 1.5189.11305 1.5189.89415V18.4749c0 .7811-.8359 1.2744-1.5189.8941l-6.63606-4.2857H3.01937C2.45872 15.0833 2 14.6208 2 14.0555V9.94448c0-.56527.45872-1.02776 1.01937-1.02776h4.07747Zm0 1.28468H3.27421v3.5972h3.82263c.23445 0 .4791.0719.68298.2055l6.19778 3.998V5.99788L7.77982 9.99587c-.20388.13363-.43833.20553-.68298.20553Zm9.74516-.70913c.2446-.24667.6524-.24667.897 0l1.5903 1.60333 1.5902-1.60333c.2446-.24667.6524-.24667.897 0 .2447.24666.2447.65773 0 .90443L20.2263 12l1.5902 1.6033c.2447.2467.2447.6578 0 .9044-.2446.2467-.6524.2467-.897 0l-1.5902-1.6033-1.5903 1.6033c-.2446.2467-.6524.2467-.897 0-.2447-.2466-.2447-.6577 0-.9044L18.4322 12l-1.5902-1.6033c-.2447-.2467-.2447-.65777 0-.90443Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

VolumeMute.propTypes = {
  className: PropTypes.string,
};

export default VolumeMute;
