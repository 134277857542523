import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Lock({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.18054 7.05191c0-.63773.19714-1.60857.7705-2.40215C9.50145 3.88794 10.425 3.24784 12 3.24784c.683 0 1.6502.13099 2.4289.65484.7362.49518 1.3905 1.39802 1.3905 3.14923v2.76816H8.18054V7.05191Zm-1.25 2.76816V7.05191c0-.83863.24731-2.08232 1.00728-3.13419C8.72074 2.83409 10.0194 1.99784 12 1.99784c.7984 0 2.0535.14582 3.1266.86766 1.1157.75049 1.9428 2.06219 1.9428 4.18641v2.76816h1.2084c.8284 0 1.5.67153 1.5 1.50003V20.5c0 .8284-.6716 1.5-1.5 1.5H5.72223c-.82843 0-1.5-.6716-1.5-1.5v-9.1799c0-.8285.67157-1.50003 1.5-1.50003h1.20831ZM5.72223 11.0701H18.2778c.1381 0 .25.1119.25.25V20.5c0 .1381-.1119.25-.25.25H5.72223c-.13807 0-.25-.1119-.25-.25v-9.1799c0-.1381.11193-.25.25-.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Lock.propTypes = {
  className: PropTypes.string,
};

export default Lock;
