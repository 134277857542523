import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Document({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 3.97562c0-1.1736.9514-2.125 2.125-2.125h11c1.1736 0 2.125.9514 2.125 2.125V20.0125c0 1.1736-.9514 2.125-2.125 2.125h-11c-1.1736 0-2.125-.9514-2.125-2.125V3.97562Zm2.125-.875c-.48325 0-.875.39175-.875.875V20.0125c0 .4832.39175.875.875.875h11c.4832 0 .875-.3918.875-.875V3.97562c0-.48324-.3918-.875-.875-.875h-11Zm.92859 3.91715c0-.34518.27982-.625.625-.625h2.17861c.3451 0 .625.27982.625.625s-.2799.625-.625.625H8.05359c-.34518 0-.625-.27982-.625-.625Zm.625 6.63223c-.34518 0-.625.2798-.625.625s.27982.625.625.625h7.89281c.3452 0 .625-.2798.625-.625s-.2798-.625-.625-.625H8.05359Zm-.625 3.725c0-.3452.27982-.625.625-.625h7.89281c.3452 0 .625.2798.625.625s-.2798.625-.625.625H8.05359c-.34518 0-.625-.2798-.625-.625Zm.625-6.7188c-.34518 0-.625.2798-.625.625s.27982.625.625.625h7.89281c.3452 0 .625-.2798.625-.625s-.2798-.625-.625-.625H8.05359Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Document.propTypes = {
  className: PropTypes.string,
};

export default Document;
