import { combineReducers } from 'redux';

import {
  sliceKey as healthyAuthSliceKey,
  reducer as healthyAuth,
} from '@packages/healthy-auth';

import {
  sliceKey as questionnaireSliceKey,
  reducer as questionnaireReducer,
} from 'utils/questionnaire/v2/data/questionnaireState/slice';

import {
  sliceKey as languageSliceKey,
  reducer as languageReducer,
} from 'i18n/slice';

import {
  sliceKey as regionSliceKey,
  reducer as regionReducer,
} from 'containers/Region/slice';
import {
  sliceKey as userInterfaceSliceKey,
  reducer as userInterfaceReducer,
} from 'containers/UserInterface/slice';
import {
  sliceKey as patientsFiltersSliceKey,
  reducer as patientsListFiltersReducer,
} from 'containers/PatientsList/slice';
import {
  sliceKey as observationSliceKey,
  reducer as observationReducer,
} from 'containers/ObservationCard/slice';

import {
  sliceKey as timelineSliceKey,
  reducer as timelineReducer,
} from 'components/TimelineControls/slice';
import {
  sliceKey as graphSliceKey,
  reducer as graphReducer,
} from 'components/ObservationGraphs/slice';
import {
  sliceKey as editCrossSliceKey,
  reducer as editCrossReducer,
} from 'components/Annotations/Cross/data/slice';

const rootReducer =
  combineReducers({
    [healthyAuthSliceKey]: healthyAuth,
    [languageSliceKey]: languageReducer,
    [regionSliceKey]: regionReducer,
    [userInterfaceSliceKey]: userInterfaceReducer,
    [questionnaireSliceKey]: questionnaireReducer,
    [patientsFiltersSliceKey]: patientsListFiltersReducer,
    [observationSliceKey]: observationReducer,
    [graphSliceKey]: graphReducer,
    [graphSliceKey]: graphReducer,
    [timelineSliceKey]: timelineReducer,
    [editCrossSliceKey]: editCrossReducer,
  });

export default rootReducer;
