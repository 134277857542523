import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function LocationPin({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 10.1818C18.75 12.8717 17.0312 15.5247 15.083 17.6278C14.1328 18.6536 13.1785 19.4944 12.4607 20.0791C12.2925 20.216 12.1379 20.3384 12 20.4454C11.8621 20.3384 11.7075 20.216 11.5393 20.0791C10.8215 19.4944 9.86722 18.6536 8.91702 17.6278C6.96884 15.5247 5.25 12.8717 5.25 10.1818C5.25 8.33443 5.96783 6.56822 7.2369 5.2703C8.50505 3.97333 10.219 3.25 12 3.25C13.781 3.25 15.4949 3.97333 16.7631 5.2703C18.0322 6.56822 18.75 8.33443 18.75 10.1818ZM20 10.1818C20 16.5455 12 22 12 22C12 22 4 16.5455 4 10.1818C4 8.01187 4.84285 5.93079 6.34315 4.3964C7.84344 2.86201 9.87827 2 12 2C14.1217 2 16.1566 2.86201 17.6569 4.3964C19.1571 5.93079 20 8.01187 20 10.1818ZM9.625 10C9.625 8.68832 10.6883 7.625 12 7.625C13.3117 7.625 14.375 8.68832 14.375 10C14.375 11.3117 13.3117 12.375 12 12.375C10.6883 12.375 9.625 11.3117 9.625 10ZM12 6.375C9.99797 6.375 8.375 7.99797 8.375 10C8.375 12.002 9.99797 13.625 12 13.625C14.002 13.625 15.625 12.002 15.625 10C15.625 7.99797 14.002 6.375 12 6.375Z"
        fill="black"
      />
    </SvgIcon>
  );
}

LocationPin.propTypes = {
  className: PropTypes.string,
};

export default LocationPin;
