import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ButtonBase, colors, spacing } from '@packages/ui';

const buttonCss = ({ withUnderline }) => css`
  padding-inline: ${spacing.space4};
  padding-block: ${spacing.space2};

  ${withUnderline &&
    css`
      text-decoration: underline;
    `}

  :active,
  :focus,
  :hover {
    text-decoration: underline;

    &:not(:disabled) {
      color: ${colors.primary700};
    }
  }
`;

function PlainTextButton({ children, withUnderline, ...buttonProps }) {
  return (
    <ButtonBase appearance="text" css={buttonCss({ withUnderline })} {...buttonProps}>
      {children}
    </ButtonBase>
  );
}

PlainTextButton.propTypes = {
  children: PropTypes.node.isRequired,
  withUnderline: PropTypes.bool,
};

export default PlainTextButton;
