import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ChevronSort({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M7 9.14286 12 4l5 5.14286m0 5.71424L12 20l-5-5.1429"
        stroke="#111827"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

ChevronSort.propTypes = {
  className: PropTypes.string,
};

export default ChevronSort;
