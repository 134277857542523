import { createSlice } from '@reduxjs/toolkit';
import upperFirst from 'lodash/upperFirst';

import { convertToPercentageByDimensions } from '../../shared/utils';

import colors from './colors';

export const sliceKey = 'editCross';

export const initialState = {
  parentRefDimensions: null,
  points: [],
  newPointsWidth: {
    a: {},
    b: {},
    color: 'newLine',
  },
  newPointsLength: {
    a: {},
    b: {},
    color: 'newLine',
  },
  clickCount: 0,
  history: [],
  isDragging: false,
  originalDimensions: null,
};

const editCrossSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    init(state, { payload: { annotationData, width, height, originalDimensions } }) {
      state.parentRefDimensions = { width, height };
      state.points = (annotationData ?? []).map((annotation, index) => ({
        a: convertToPercentageByDimensions({
          item: annotation.line[0],
          width: originalDimensions?.width,
          height: originalDimensions?.height,
        }),
        b: convertToPercentageByDimensions({
          item: annotation.line[1],
          width: originalDimensions?.width,
          height: originalDimensions?.height,
        }),
        color: Object.keys(colors)[index],
      }));
      state.history = [state.points];
      state.originalDimensions = originalDimensions;
    },
    updatePoints(state, { payload: { lineIndex, positionKey, x, y } }) {
      const newX = x / state.parentRefDimensions.width;
      const newY = y / state.parentRefDimensions.height;

      state.points[lineIndex][positionKey] = { x: newX, y: newY };
    },
    resetChanges(state) {
      const [originalPoints] = state.history;
      state.points = originalPoints;
      state.newPointsWidth = initialState.newPointsWidth;
      state.newPointsLength = initialState.newPointsLength;
      state.clickCount = initialState.clickCount;
    },
    startDragging(state) {
      state.isDragging = true;
    },
    finishDragging(state) {
      state.isDragging = false;
    },
    // New points creation logic
    commitNewPoint(state, { payload: { x, y, measure, aOrBPoint } }) {
      // On complete "Width" measure set permanent color
      if (measure === 'length') {
        state.newPointsWidth.color = 'highlightGreen';
      }
      state[`newPoints${upperFirst(measure)}`][aOrBPoint] = {
        x: x / state.parentRefDimensions.width,
        y: y / state.parentRefDimensions.height,
      };
    },
    markCrossFinish(state) {
      // On complete "Length" measure set permanent color
      state.newPointsLength.color = 'highlightBlue';
      state.points = [state.newPointsWidth, state.newPointsLength];
    },
    setClickCount(state, { payload }) {
      state.clickCount = payload;
    },
  },
});

export const { reducer, actions } = editCrossSlice;
