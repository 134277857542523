import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function House({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5242 3.64538c.2306-.19267.5657-.19397.7978-.00309l9.575 7.87501c.2666.2192.3049.6131.0857.8797-.2193.2666-.6131.305-.8797.0857l-1.4781-1.2156V19.5c0 1.1736-.9514 2.125-2.125 2.125H6.49994c-1.17361 0-2.125-.9514-2.125-2.125v-8.2522l-1.47423 1.2318c-.26488.2213-.65903.186-.88036-.0789-.22132-.2648-.18601-.659.07888-.8803l9.42497-7.87502Zm.4039 1.29144 6.4468 5.30218V19.5c0 .4832-.3917.875-.875.875H15.55v-6.75c0-.3452-.2799-.625-.625-.625H8.92496c-.34518 0-.625.2798-.625.625v6.75H6.49994c-.48325 0-.875-.3918-.875-.875v-9.2966l6.30316-5.26658ZM9.54996 20.375H14.3V14.25H9.54996v6.125Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

House.propTypes = {
  className: PropTypes.string,
};

export default House;
