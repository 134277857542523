import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ChartAnalysis({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 3.25h17c.1381 0 .25.11193.25.25v17c0 .1381-.1119.25-.25.25h-17c-.13807 0-.25-.1119-.25-.25v-17c0-.13807.11193-.25.25-.25ZM2 3.5C2 2.67157 2.67157 2 3.5 2h17c.8284 0 1.5.67157 1.5 1.5v17c0 .8284-.6716 1.5-1.5 1.5h-17c-.82843 0-1.5-.6716-1.5-1.5v-17Zm4.25 14.25v-4.5h1.5v4.5h-1.5ZM5 13c0-.5523.44772-1 1-1h2c.55228 0 1 .4477 1 1v5c0 .5523-.44772 1-1 1H6c-.55228 0-1-.4477-1-1v-5Zm6.25-3.75v8.5h1.5v-8.5h-1.5ZM11 8c-.5523 0-1 .44771-1 1v9c0 .5523.4477 1 1 1h2c.5523 0 1-.4477 1-1V9c0-.55228-.4477-1-1-1h-2Zm6.75 9.75h-1.5V6.25h1.5v11.5ZM15 6c0-.55228.4477-1 1-1h2c.5523 0 1 .44772 1 1v12c0 .5523-.4477 1-1 1h-2c-.5523 0-1-.4477-1-1V6Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ChartAnalysis.propTypes = {
  className: PropTypes.string,
};

export default ChartAnalysis;
