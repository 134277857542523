import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ArrowBack({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M22 14.0117c0 3.5854-2.9626 6.4883-6.6216 6.4883H9.79626c-.35343 0-.64449-.2852-.64449-.6315 0-.3463.29106-.6315.64449-.6315h5.58214c2.9418 0 5.3326-2.3428 5.3326-5.2253 0-2.8826-2.3908-5.2253-5.3326-5.2253H4.20374l3.00416 2.9437c.24948.2444.24948.6417 0 .8963-.24948.2547-.65489.2445-.91476 0L2.18711 8.59287c-.24948-.24446-.24948-.6417 0-.89635l4.10603-4.01318c.24948-.24445.65489-.24445.91476 0 .24948.24446.24948.6417 0 .89635L4.20374 7.52337H15.3784c3.659 0 6.6216 2.90293 6.6216 6.48833Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ArrowBack.propTypes = {
  className: PropTypes.string,
};

export default ArrowBack;
