import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function SmsDisabled({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23694 4.6903c0-.20711.16789-.375.375-.375H20.3881c.2071 0 .375.16789.375.375v5.07463c0 .34517.2798.62497.625.62497.3451 0 .625-.2798.625-.62497V4.6903c0-.89746-.7276-1.625-1.625-1.625H3.61194c-.89746 0-1.625.72754-1.625 1.625V15.944c0 .8975.72754 1.625 1.625 1.625h1.13619v3.2407c0 .2387.13597.4566.35041.5614.21443.1049.46988.0785.65831-.0681l4.69935-3.655c.0658-.0512.1468-.079.2302-.079h.7614c.3451 0 .625-.2798.625-.625 0-.3451-.2799-.625-.625-.625h-.7614c-.3614 0-.71242.1205-.99766.3423l-3.69061 2.8705V16.944c0-.3451-.27982-.625-.625-.625H3.61194c-.20711 0-.375-.1679-.375-.375V4.6903Zm2.68843 1.68843c-.34518 0-.625.27982-.625.625s.27982.625.625.625H18.0746c.3452 0 .625-.27982.625-.625s-.2798-.625-.625-.625H5.92537Zm0 2.7612c-.34518 0-.625.27982-.625.625 0 .34517.27982.62497.625.62497H14.209c.3451 0 .625-.2798.625-.62497 0-.34518-.2799-.625-.625-.625H5.92537Zm-.625 3.38617c0-.3452.27982-.625.625-.625h5.52243c.3451 0 .625.2798.625.625s-.2799.625-.625.625H5.92537c-.34518 0-.625-.2798-.625-.625Zm12.22203 5.9291c1.7496 0 3.1679-1.4183 3.1679-3.1679 0-.5235-.127-1.0174-.3519-1.4525l-4.8508 3.8807c.5504.4617 1.2601.7397 2.0348.7397Zm2.0347-5.5961c-.5504-.4617-1.2601-.7397-2.0347-.7397-1.7496 0-3.1679 1.4183-3.1679 3.1679 0 .5235.127 1.0174.3518 1.4524l4.8508-3.8806Zm-2.0347 6.8461c2.4399 0 4.4179-1.9779 4.4179-4.4179 0-2.4399-1.978-4.4179-4.4179-4.4179-2.44 0-4.4179 1.978-4.4179 4.4179 0 2.44 1.9779 4.4179 4.4179 4.4179Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

SmsDisabled.propTypes = {
  className: PropTypes.string,
};

export default SmsDisabled;
