import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import spotTokens from 'styles/tokens';

/**
 * @param {Number} size - number of base spacing units
 */

const spacerCss = ({ size }) => css`
  padding: ${size * spotTokens.spacing.base}px;
`;

function Spacer({ size = 0.5, ...restProps }) {
  return <div css={spacerCss({ size })} {...restProps} />;
}

Spacer.propTypes = {
  size: PropTypes.number,
};

export default Spacer;
