import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ArrowBounce({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8858 8.84697c.2284-.25883.2037-.65379-.0551-.88216-.2588-.22838-.6538-.20369-.8822.05513l-7.6361 8.65436c-.1374.1557-.3752.1701-.5304.0321L4.17804 9.05846h5.06547c.34518 0 .625-.27983.625-.625 0-.34518-.27982-.625-.625-.625H2.60333c-.34518 0-.625.27982-.625.625v6.64014c0 .3452.27982.625.625.625s.625-.2798.625-.625V9.88672l8.72327 7.75398c.6725.5978 1.7027.5353 2.2981-.1394l7.6361-8.65433Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ArrowBounce.propTypes = {
  className: PropTypes.string,
};

export default ArrowBounce;
