import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ThumbUp({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M14.4116 3.35152c-.4381-.17641-.9283.0934-1.0221.55002-.1461.72642-.4902 1.41134-.9909 1.96135L8.35181 10.3356c-.12515.1349-.30246.218-.47977.218H3.68965c-.21903 0-.39634.1764-.39634.3943v7.2643c0 .2179.17731.3943.39634.3943h2.39887c.6258 0 1.2203.2076 1.71051.6019l1.46019 1.1623c.25031.1972.57364.3114.89698.3114h9.9918c.1669 0 .3129-.1038.3755-.2699l.1669-.4877c.0313-.083.0313-.1868 0-.2698l-.2295-.5604c-.1668-.4047-.1668-.851 0-1.2557l.2295-.5604c.0313-.083.0417-.1764 0-.2698l-.292-.8717c-.1148-.3425-.1148-.7161 0-1.0689l.292-.8717c.0313-.083.0313-.1868 0-.2698l-.2295-.5604c-.1668-.4048-.1668-.851 0-1.2557l.2399-.6019c.0209-.0519.0209-.1245 0-.1764-.1564-.4774-.6049-.7991-1.116-.7991h-5.1315c-.6362 0-1.0534-.67455-.7718-1.23493l.4276-.84059c.605-1.20379.9179-2.5425.9179-3.89158v-.42548c0-.34246-.2086-.65379-.532-.77832l-.0938-.04151.0104.03113Zm-2.305.30095c.2503-1.25568 1.5853-1.98211 2.7848-1.50474l.0938.04151c.8136.3217 1.3455 1.1104 1.3455 1.98211v.42548c0 1.54626-.3651 3.08214-1.0639 4.46235l-.1043.20755h4.4119c1.0638 0 2.013.67455 2.3467 1.68117.1148.3528.1043.7264-.0313 1.0689l-.2399.6019c-.0417.0934-.0417.1972 0 .2906l.2295.5603c.146.3736.1565.7784.0313 1.1623l-.292.8717c-.0313.0831-.0313.1661 0 .2491l.292.8717c.1252.3736.1147.7887-.0313 1.1623l-.2295.5604c-.0417.0934-.0417.1972 0 .2906l.2295.5604c.146.3736.1565.7783.0313 1.1622l-.1669.4878C21.5144 21.533 20.8677 22 20.1376 22h-9.971c-.62578 0-1.22028-.2076-1.71049-.6019l-1.46018-1.1623c-.25032-.1972-.57365-.3113-.89698-.3113h-2.4093C2.75095 19.9245 2 19.1669 2 18.2433V10.979c0-.9339.76138-1.68113 1.68965-1.68113h3.87992l3.84863-4.2548c.3442-.38397.5841-.85096.678-1.34909l.0104-.04151Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ThumbUp.propTypes = {
  className: PropTypes.string,
};

export default ThumbUp;
