import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function GoogleDrive({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M22 14.5632c0-.115-.0503-.2203-.1107-.3161 0-.0096 0-.0287-.0101-.0383l-6.2595-9.9215C15.509 4.10534 15.2977 4 15.0763 4H9.07846c-.2214 0-.42267.10534-.53337.2873l-6.45071 9.9215c-.13082.1916-.12076.4405 0 .6321l3.11969 4.7692c.05032.1245.13083.2298.25159.3065.16101.0957.35222.1053.5233.0478H18.2866c.2214 0 .4226-.1149.5434-.2873l2.8983-4.616c.161-.1149.2717-.2873.2616-.4884L22 14.5632Zm-1.7309-.5746-4.5789.0766-5.71609-8.86811h4.75999l5.545 8.80101-.01-.0095Zm-6.0381.0957-4.37765.0671 2.19385-3.4573 2.1838 3.3902ZM8.96776 5.91535l2.34484 3.63917-5.50478 8.67658-2.43537-3.7158 5.59531-8.59995ZM17.9343 18.7578H6.93493l2.14353-3.3806 11.08994-.182-2.2441 3.5626h.01Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

GoogleDrive.propTypes = {
  className: PropTypes.string,
};

export default GoogleDrive;
