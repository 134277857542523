import PropTypes from 'prop-types';

import { cx } from '@emotion/css';
import { css } from '@emotion/react';

import { colors } from '../../../theme';

const singleValueCss = ({ isDisabled }) => css`
  grid-area: 1/1/2/3;
  color: ${isDisabled ? colors.gray600 : colors.black};
`;

function SingleValue(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { isDisabled },
  } = props;

  return (
    <div
      className={cx('HioSelect__single-value')}
      css={singleValueCss({ isDisabled })}
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </div>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.shape({
    isDisabled: PropTypes.bool,
  }),
};

export default SingleValue;
