import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Sms({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12367 17.5826c0-.6903-.55964-1.25-1.25-1.25H3.40436c-.13807 0-.25-.1119-.25-.25V4.5c0-.13807.11192-.25.25-.25H20.5957c.1381 0 .25.11193.25.25v11.5826c0 .1381-.1119.25-.25.25H10.2184c-.29394 0-.57852.1036-.80368.2927l-3.29105 2.7628v-1.8055Zm-2.71931 0H4.87367v3.4144c0 .4248.49616.6561.82149.3829l4.52324-3.7973h10.3773c.8284 0 1.5-.6715 1.5-1.5V4.5c0-.82843-.6716-1.5-1.5-1.5H3.40436c-.82843 0-1.5.67157-1.5 1.5v11.5826c0 .8285.67157 1.5 1.5 1.5ZM5.83045 6.30109c-.34518 0-.625.27982-.625.625s.27982.625.625.625H18.1696c.3452 0 .625-.27982.625-.625s-.2798-.625-.625-.625H5.83045Zm-.625 3.42935c0-.34517.27982-.625.625-.625H18.1696c.3452 0 .625.27983.625.625 0 .34516-.2798.62496-.625.62496H5.83045c-.34518 0-.625-.2798-.625-.62496Zm.625 2.17936c-.34518 0-.625.2798-.625.625s.27982.625.625.625h6.73045c.3452 0 .625-.2798.625-.625s-.2798-.625-.625-.625H5.83045Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Sms.propTypes = {
  className: PropTypes.string,
};

export default Sms;
