import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function VideoCamera({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 5.25h12c.1381 0 .25.11193.25.25v13c0 .1381-.1119.25-.25.25h-12c-.13807 0-.25-.1119-.25-.25v-13c0-.13807.11193-.25.25-.25ZM2 5.5C2 4.67157 2.67157 4 3.5 4h12c.8284 0 1.5.67157 1.5 1.5v3.50786l3.4861-1.16204c1.0523-.35075 2.1389.43245 2.1389 1.54161v5.22517c0 1.1091-1.0866 1.8923-2.1389 1.5416L17 14.9921V18.5c0 .8284-.6716 1.5-1.5 1.5h-12c-.82843 0-1.5-.6716-1.5-1.5v-13Zm15 8.1745 3.8814 1.2938c.2428.081.4936-.0998.4936-.3557V9.38743c0-.25596-.2508-.4367-.4936-.35576L17 10.3255v3.349Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

VideoCamera.propTypes = {
  className: PropTypes.string,
};

export default VideoCamera;
