import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Stethoscope({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 2.5c0-.34518.27982-.625.625-.625H5c.34518 0 .625.27982.625.625s-.27982.625-.625.625H3.125V8.5c0 .90849.22853 1.8687.84954 2.5933.60492.7057 1.66314 1.2817 3.52546 1.2817.81843 0 1.93121-.1229 2.821-.6601.8384-.5061 1.554-1.4225 1.554-3.2149V3.125H10c-.34518 0-.625-.27982-.625-.625s.27982-.625.625-.625h2.5c.3452 0 .625.27982.625.625v6c0 2.2076-.9257 3.5412-2.1579 4.2851-.9327.563-1.99416.7606-2.8421.8188v1.0836c0 1.0984.12566 2.806.83712 4.2189.35088.6969.83649 1.3064 1.50778 1.7438.6684.4354 1.5586.7248 2.7612.7248 1.4729 0 2.8457-.356 3.7964-1.315.8978-.9056 1.5309-2.463 1.3772-5.1191C17.033 14.6648 16 13.453 16 12c0-1.6569 1.3431-3 3-3s3 1.3431 3 3c0 1.4316-1.0027 2.6289-2.3441 2.9281.1531 2.842-.5184 4.779-1.7407 6.0119-1.2798 1.291-3.0415 1.685-4.6841 1.685-1.4129 0-2.5469-.3434-3.4435-.9275-.89371-.5822-1.51393-1.3789-1.94194-2.2289-.84632-1.6808-.97066-3.6295-.97066-4.7811v-1.0827c-1.79762-.1194-3.04463-.759-3.84954-1.6981C2.14647 10.8813 1.875 9.59151 1.875 8.5v-6ZM19 13.75c.9665 0 1.75-.7835 1.75-1.75s-.7835-1.75-1.75-1.75-1.75.7835-1.75 1.75.7835 1.75 1.75 1.75Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Stethoscope.propTypes = {
  className: PropTypes.string,
};

export default Stethoscope;
