import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Volume({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="m13.73 5.12989-6.63 4.29H3.02c-.56 0-1.02.46-1.02 1.03001v4.11c0 .57.46 1.03 1.02 1.03H7.1l6.64 4.29c.68.38 1.51-.11 1.51-.9V6.02989c0-.78-.83-1.28-1.51-.9h-.01ZM7.78 10.4999l6.19-4.00001V18.4999l-6.19-4c-.21-.13-.44-.2-.69-.2H3.27v-3.59h3.82c.24 0 .48-.07.69-.2v-.01Zm8.93-.34c.16-.32001.54-.45001.85-.29001.51.26001 1 .54001 1.35.96001.38.45.53 1 .53 1.67 0 .67-.16 1.22-.53 1.67-.35.42-.84.7-1.35.96-.31.16-.7.03-.85-.29-.15-.32-.03-.7.28-.86.51-.26.78-.44.94-.63.13-.16.23-.39.23-.85 0-.46-.1-.69-.23-.85-.16-.2-.43-.38-.94-.63-.31-.16-.44-.54-.28-.86Zm2.99-2.28001c-.28-.21-.68-.16-.89.13-.21.28-.15.69.13.9.66.5 1.08.9 1.35 1.39001.27.49.43 1.14.43 2.21s-.16 1.72-.43 2.21-.69.89-1.35 1.39c-.28.21-.34.62-.13.9.21.28.61.34.89.13.69-.53 1.3-1.05 1.7-1.79.41-.74.59-1.64.59-2.83 0-1.19-.18-2.09-.59-2.83001-.41-.74-1.01-1.26-1.7-1.79v-.02Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Volume.propTypes = {
  className: PropTypes.string,
};

export default Volume;
