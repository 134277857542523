import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Archive({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 3.25h-17c-.13807 0-.25.11193-.25.25v3.11465c0 .13807.11193.25.25.25h17c.1381 0 .25-.11193.25-.25V3.5c0-.13807-.1119-.25-.25-.25ZM3.5 2C2.67157 2 2 2.67157 2 3.5v3.11465c0 .82843.67157 1.5 1.5 1.5h.375V19.8631c0 1.1736.9514 2.125 2.125 2.125h12c1.1736 0 2.125-.9514 2.125-2.125V8.11465h.375c.8284 0 1.5-.67157 1.5-1.5V3.5c0-.82843-.6716-1.5-1.5-1.5h-17Zm1.625 17.8631V8.11465h13.75V19.8631c0 .4832-.3918.875-.875.875H6c-.48325 0-.875-.3918-.875-.875Zm4.5-8.1131c-.34518 0-.625.2798-.625.625s.27982.625.625.625h4.75c.3452 0 .625-.2798.625-.625s-.2798-.625-.625-.625h-4.75Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Archive.propTypes = {
  className: PropTypes.string,
};

export default Archive;
