import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import { spacing } from '../../theme';

const formHintCss = css`
  display: flex;
  margin-block-start: ${spacing.space1};
  align-items: center;
  column-gap: ${spacing.space1};
`;

const FormHint = forwardRef(({ children, className }, ref) => (
  <Text
    css={formHintCss}
    className={cx('HioFormHint__root', className)}
    ref={ref}
    variant="caption"
  >
    <Icon icon="exclamation-circle" size="xs" />
    {children}
  </Text>
));

FormHint.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FormHint;
