import PropTypes from 'prop-types';

function SvgIcon({ children, className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      {children}
    </svg>
  );
}

SvgIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default SvgIcon;
