import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function LandlineAlt({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2.375c-1.1736 0-2.125.95139-2.125 2.125v15c0 1.1736.95139 2.125 2.125 2.125h15c1.1736 0 2.125-.9514 2.125-2.125v-15c0-1.17361-.9514-2.125-2.125-2.125h-15ZM6 3.625H4.5c-.48325 0-.875.39175-.875.875v15c0 .4832.39175.875.875.875h3c.48325 0 .875-.3918.875-.875v-15c0-.48325-.39175-.875-.875-.875H6Zm3.43707 0c.12072.26684.18793.56307.18793.875v15c0 .3119-.06721.6082-.18793.875H19.5c.4832 0 .875-.3918.875-.875v-15c0-.48325-.3918-.875-.875-.875H9.43707ZM12.25 7.25v1.5h5.5v-1.5h-5.5ZM12.2 6c-.6627 0-1.2.53726-1.2 1.2v1.6c0 .66274.5373 1.2 1.2 1.2h5.6c.6627 0 1.2-.53726 1.2-1.2V7.2c0-.66274-.5373-1.2-1.2-1.2h-5.6ZM11 11.7c-.2761 0-.5.2239-.5.5s.2239.5.5.5h1.2c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5H11Zm-.5 3.2c0-.2761.2239-.5.5-.5h1.2c.2761 0 .5.2239.5.5s-.2239.5-.5.5H11c-.2761 0-.5-.2239-.5-.5Zm.5 2.3c-.2761 0-.5.2239-.5.5s.2239.5.5.5h1.2c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5H11Zm2.8-5c0-.2761.2238-.5.5-.5h1.2c.2761 0 .5.2239.5.5s-.2239.5-.5.5h-1.2c-.2762 0-.5-.2239-.5-.5Zm.5 2.2c-.2762 0-.5.2239-.5.5s.2238.5.5.5h1.2c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5h-1.2Zm-.5 3.3c0-.2761.2238-.5.5-.5h1.2c.2761 0 .5.2239.5.5s-.2239.5-.5.5h-1.2c-.2762 0-.5-.2239-.5-.5Zm3.8-6c-.2762 0-.5.2239-.5.5s.2238.5.5.5h1.2c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5h-1.2Zm-.5 3.2c0-.2761.2238-.5.5-.5h1.2c.2761 0 .5.2239.5.5s-.2239.5-.5.5h-1.2c-.2762 0-.5-.2239-.5-.5Zm.5 2.3c-.2762 0-.5.2239-.5.5s.2238.5.5.5h1.2c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5h-1.2Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

LandlineAlt.propTypes = {
  className: PropTypes.string,
};

export default LandlineAlt;
