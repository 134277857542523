import PropTypes from 'prop-types';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { css } from '@emotion/react';
import { cx } from '@emotion/css';
import Paper from '../Paper/Paper';
import { toRem } from '../../helpers';
import { zIndex } from '../../theme';

const subContentCss = css`
  // TODO: Decide what to do with min-width. This will become an issue once Select is using Dropdown
  min-width: ${toRem(220)};

  z-index: ${zIndex.dropdown};

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  animation: slideLeftAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
`;

// TODO: Nested dropdown might appear outside of the screen
// https://github.com/OwnHealthIL/technodrome/issues/463
function SubContent({ children, className }) {
  return (
    <RadixDropdown.SubContent
      className={cx(className, 'HioDropdown__sub-content')}
      css={subContentCss}
    >
      <Paper>{children}</Paper>
    </RadixDropdown.SubContent>
  );
}

SubContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

SubContent.displayName = 'Dropdown.SubContent';

export default SubContent;
