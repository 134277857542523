import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Discharge({ className }) {
  return (
    <SvgIcon fill="none" className={className}>
      <g id="user-discharge">
        <g id="user-x">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.0119 9.15872C12.3666 9.15872 13.4648 8.06049 13.4648 6.70576C13.4648 5.35103 12.3666 4.25281 11.0119 4.25281C9.65714 4.25281 8.55891 5.35103 8.55891 6.70576C8.55891 8.06049 9.65714 9.15872 11.0119 9.15872ZM11.0119 10.6587C13.195 10.6587 14.9648 8.88892 14.9648 6.70576C14.9648 4.52261 13.195 2.75281 11.0119 2.75281C8.82871 2.75281 7.05891 4.52261 7.05891 6.70576C7.05891 8.88892 8.82871 10.6587 11.0119 10.6587Z" fill="currentColor" />
          <path fillRule="evenodd" clipRule="evenodd" d="M11.8666 11.5422C13.307 11.6659 14.5599 12.0885 15.3002 12.4586C15.6707 12.6439 15.8208 13.0944 15.6356 13.4649C15.4504 13.8354 14.9999 13.9855 14.6294 13.8003C14.052 13.5116 12.9825 13.1436 11.7382 13.0367C10.498 12.9302 9.13882 13.0871 7.9251 13.7807C7.07239 14.2679 5.21665 15.97 4.5446 18.9059C4.52269 19.0016 4.54651 19.0871 4.61017 19.1603C4.67803 19.2384 4.78986 19.2971 4.92602 19.2971H10.5177C10.9319 19.2971 11.2677 19.6329 11.2677 20.0471C11.2677 20.4614 10.9319 20.7971 10.5177 20.7971H4.92602C3.80393 20.7971 2.80148 19.7984 3.08242 18.5711C3.85569 15.1931 5.99507 13.1559 7.18089 12.4783C8.73423 11.5907 10.4221 11.4181 11.8666 11.5422Z" fill="currentColor" />
        </g>
        <path id="Vector" d="M13 17.9106C13 17.5648 13.2751 17.2979 13.6092 17.2979L18.0328 17.2979L16.726 16.0627C16.4804 15.8255 16.4706 15.4401 16.6966 15.193C16.9324 14.946 17.3156 14.9361 17.5612 15.1634L19.9979 17.4659C20.2435 17.7031 20.2435 18.128 19.9979 18.3652L17.5612 20.6678C17.3156 20.905 16.9226 20.8852 16.6966 20.6381C16.4608 20.3911 16.4706 19.9958 16.726 19.7685L18.023 18.5431L13.6092 18.5431C13.2653 18.5431 13 18.2664 13 17.9304L13 17.9106Z" fill="currentColor" />
      </g>
    </SvgIcon>
  );
}

Discharge.propTypes = {
  className: PropTypes.string,
};

export default Discharge;
