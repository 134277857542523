import { css } from '@emotion/react';

const loaderCss = css`
  %sharedSetup {
    height: 2px;
    width: 100%;
    margin: 0;
  }

  @keyframes running-progress {
    0% {
      margin-left: 0;
      margin-right: 100%;
    }

    50% {
      margin-left: 25%;
      margin-right: 0%;
    }

    100% {
      margin-left: 100%;
      margin-right: 0;
    }
  }

  div {
    @extend %sharedSetup;

    display: flex;
    position: fixed;
    top: 0;
    z-index: 999;
    color: #3f51b5;

    &::before {
      @extend %sharedSetup;

      content: '';
      background-color: currentColor;
      animation: running-progress 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
`;

function Loader() {
  return <div css={loaderCss} />;
}

export default Loader;
