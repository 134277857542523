/**
 * Convert a pixel unit to rem.
 *
 * @example
 * toRem(4) // 0.25rem (4px)
 * toRem(8) // 0.5rem (8px)
 *
 * @param {number} px - Pixel unit
 */
export function toRem(px) {
  if (typeof px !== 'number') {
    return '';
  }

  return `${px / 16}rem`;
}

/**
 * Convert a scale factor into a rem unit.
 *
 * By default, the theme's scale factor is 0.25rem (4px).
 * Changing it can be done by overriding `var(--spacing-scale-factor)`.
 *
 * @example
 * toSpacing(1) // 0.25rem (4px)
 * toSpacing(2) // 0.5rem (8px)
 *
 * @param {number} scale - Scale factor
 */
export function toSpacing(scale) {
  if (typeof scale !== 'number') {
    return '';
  }

  return `calc(${scale} * var(--spacing-scale-factor))`;
}
