import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function UserCircle({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9722 17.2877C20.0878 15.8189 20.75 13.9868 20.75 12c0-4.83249-3.9175-8.75-8.75-8.75-4.83249 0-8.75 3.91751-8.75 8.75 0 1.9821.65902 3.8102 1.76989 5.2772.86226-1.7055 3.00914-4.2355 6.98011-4.2355 1.8319 0 5.1046.5526 6.9722 4.246Zm-.9033 1.0156c-1.5333-3.5148-4.4072-4.0116-6.0689-4.0116-3.74618 0-5.55256 2.659-6.07052 4.01C7.50286 19.8177 9.64255 20.75 12 20.75c2.3566 0 4.4956-.9316 6.0689-2.4467ZM12 22c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10C6.47715 2 2 6.47715 2 12c0 5.5228 4.47715 10 10 10Zm0-11.25c1.1506 0 2.0833-.93274 2.0833-2.08333 0-1.1506-.9327-2.08334-2.0833-2.08334-1.1506 0-2.08333.93274-2.08333 2.08334C9.91667 9.81726 10.8494 10.75 12 10.75ZM12 12c1.8409 0 3.3333-1.4924 3.3333-3.33333 0-1.84095-1.4924-3.33334-3.3333-3.33334S8.66667 6.82572 8.66667 8.66667C8.66667 10.5076 10.1591 12 12 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

UserCircle.propTypes = {
  className: PropTypes.string,
};

export default UserCircle;
