import { components } from 'react-select';

import Icon from '../../Icon/Icon';

function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <Icon icon="x" size="sm" />
    </components.ClearIndicator>
  );
}

export default ClearIndicator;
