import { Provider as ReduxProvider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { install as installResizeObserver } from 'resize-observer';
import { Global as GlobalStyles } from '@emotion/react';
import { BrowserRouter as Router } from 'react-router-dom';

import { reporter } from '@packages/reporter';
import { tracking } from '@packages/tracking';

import {
  Toaster,
  ThemeProvider as DesignSystemThemeProvider,
} from '@packages/ui';
import { LanguageFacilitator, initI18n } from 'i18n';

import theme from 'styles/theme';
import globalStyles from 'styles/global';

import AppAuthProvider from 'containers/App/AppAuthProvider';
import { ErrorBoundary } from 'containers/App/errorBoundary/ErrorBoundary';

import ErrorBoundaryPage from 'components/ErrorBoundaryPage';

import packageJson from '../package.json';
import createStore from './redux/createStore';

const container = document.getElementById('app');
const root = createRoot(container);

const domain = window.location.href;
const endsWithDotSlashRegex = /\.\/$/;
if (domain.match(endsWithDotSlashRegex)) {
  window.location.replace(domain.replace(endsWithDotSlashRegex, ''));
}

const store = createStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 5000,
    },
  },
});

// Supporting graphs in older browsers
installResizeObserver();

initI18n();

tracking.initTracking({
  token: process.env.MIXPANEL_TOKEN,
  isEnabled:
    process.env.NODE_ENV === 'production' &&
    Boolean(
      process.env.HEALTHY_ENV === 'prod' ||
        process.env.HEALTHY_ENV === 'staging',
    ),
  config: {
    debug: process.env.NODE_ENV === 'development',
  },
});

reporter.init({
  dsn: process.env.SENTRY_DSN,
  version: `spot-portal-${packageJson.version}`,
  environment: process.env.HEALTHY_ENV,
});

root.render(
  <MuiThemeProvider theme={theme}>
    <DesignSystemThemeProvider theme="wound">
      <ErrorBoundary fallbackComponent={ErrorBoundaryPage}>
        <ReduxProvider store={store}>
          <Router>
            <QueryClientProvider client={queryClient}>
              <LanguageFacilitator />
              <ReactQueryDevtools />
              <Toaster />
              <GlobalStyles styles={globalStyles} />
              <AppAuthProvider />
            </QueryClientProvider>
          </Router>
        </ReduxProvider>
      </ErrorBoundary>
    </DesignSystemThemeProvider>
  </MuiThemeProvider>,
);
