import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Upload({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.19232 22.0404c-1.1736 0-2.125-.9514-2.125-2.125v-6.2539c0-.3451.27982-.625.625-.625s.625.2799.625.625v6.2539c0 .4832.39175.875.875.875H18.8077c.4833 0 .875-.3918.875-.875v-6.2539c0-.3451.2798-.625.625-.625s.625.2799.625.625v6.2539c0 1.1736-.9514 2.125-2.125 2.125H5.19232ZM7.71153 7.48576c-.25978-.2273-.2861-.62216-.0588-.88193l3.87697-4.43077c.1186-.13563.2901-.21343.4703-.21343.1802 0 .3517.0778.4704.21343l3.8769 4.43077c.2273.25977.201.65463-.0588.88193-.2598.2273-.6546.20097-.8819-.0588L12.625 4.24803V16.4308c0 .3451-.2798.625-.625.625s-.625-.2799-.625-.625V4.24803L8.59345 7.42696c-.2273.25977-.62215.2861-.88192.0588Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Upload.propTypes = {
  className: PropTypes.string,
};

export default Upload;
