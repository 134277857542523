import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import { spacing } from '../../../theme';
import { Icons } from '../../../constants';
import Text from '../../Text/Text';
import Icon from '../../Icon/Icon';

const modalContentCss = css`
  padding: 0 ${spacing.space5} ${spacing.space5} ${spacing.space5};
`;

const textWithIconWrapperCss = css`
  display: flex;
  column-gap: ${spacing.space4};

  .HioText__root {
    flex: 1;
  }
`;

/**
 * The modal content.
 */
const Content = forwardRef(({ children, className, icon, content }, ref) => {
  const withOnlyChildren = Boolean(children && !content);

  const withOnlyText = Boolean(content && !children && !icon);

  const withTextAndIcon = Boolean(content && icon && !children);

  return (
    <div
      css={modalContentCss}
      className={cx('HioModal__content', className)}
      ref={ref}
    >
      {withOnlyChildren && children}
      {withOnlyText && <Text>{content}</Text>}
      {withTextAndIcon && (
        <div
          css={textWithIconWrapperCss}
          className={cx({
            'HioModal__inner-content--with-icon': withTextAndIcon,
          })}
        >
          <Icon icon={icon} size="lg" />
          <Text>{content}</Text>
        </div>
      )}
    </div>
  );
});

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** For adding Icon to the Content */
  icon: PropTypes.oneOf(Object.values(Icons)),
  /** For adding regular Text to the Content */
  content: PropTypes.string,
};

Content.displayName = 'Modal.Content';

export default Content;
