import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function X({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4419 18.5581c.2441.244.2441.6398 0 .8838-.244.2441-.6398.2441-.8838 0L12 12.8839l-6.55806 6.558c-.24407.2441-.6398.2441-.88388 0-.24408-.244-.24408-.6398 0-.8838L11.1161 12 4.55806 5.44194c-.24408-.24408-.24408-.6398 0-.88388.24408-.24408.6398-.24408.88388 0L12 11.1161l6.5581-6.55804c.244-.24408.6398-.24408.8838 0 .2441.24408.2441.6398 0 .88388L12.8839 12l6.558 6.5581Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

X.propTypes = {
  className: PropTypes.string,
};

export default X;
