import mixpanel from 'mixpanel-browser';

let isTrackingEnabled = false;

/**
 * Initialize a new instance of the Mixpanel tracking object.
 *
 * @see
 * [mixpanel.init](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelinit)
 *
 * @param {object} options
 * @param {string} options.token - Mixpanel API token
 * @param {boolean} [options.isEnabled] - Set tracking on or off
 * @param {object} [options.config] - Config options to override
 */
function initTracking({ token, isEnabled, config }) {
  isTrackingEnabled = isEnabled;

  if (!isTrackingEnabled) {
    return;
  }

  mixpanel.init(token, config);
}

/**
 * Identify a user with a unique ID to track user activity across devices, tie a user to their events, and create a user profile.
 * If the methods is never being called, unique visitors are tracked using a UUID generated the first time they visit the site.
 *
 * Identify should be used when the identity of the current user is known. Typically after login.
 *
 * @see
 * [mixpanel.identify](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelidentify)
 *
 * @param {string} uniqueId - A string that uniquely identifies a user
 * @returns
 */
function identify(uniqueId) {
  if (!isTrackingEnabled) {
    return;
  }

  mixpanel.identify(uniqueId);
}

/**
 * Track an event.
 *
 * @see
 * [mixpanel.track](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack)
 *
 * @param {object} options
 * @param {string} options.eventName - The name of the event
 * @param {object} [options.props] - A set of properties to include with the event
 */
function trackEvent({ eventName, props }) {
  if (!isTrackingEnabled) {
    return;
  }

  mixpanel.track(eventName, props);
}

/**
 * Register a set of super properties, which are included with all events.
 * This will overwrite previous super property values.
 *
 * @see
 * [mixpanel.register](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelregister)
 *
 * @param {object} props - An associative object of properties to store
 */
function register(props) {
  if (!isTrackingEnabled) {
    return;
  }

  mixpanel.register(props);
}

/**
 * Delete a super property.
 *
 * @see
 * [mixpanel.unregister](https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelunregister)
 *
 * @param {string} property - The name of the super property to remove
 */
function unregister(property) {
  if (!isTrackingEnabled) {
    return;
  }

  mixpanel.unregister(property);
}

/**
 * A set of methods used by apps wishing to send data to Mixpanel.
 * This service is a simple wrapper over `mixpanel-browser`. To ease using Mixpanel within an app.
 *
 * @see
 * https://developer.mixpanel.com/docs/javascript-full-api-reference
 */
const tracking = {
  identify,
  initTracking,
  register,
  trackEvent,
  unregister,
};

export { tracking };
