import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function PlayCircle({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 12c0 4.8325-3.9175 8.75-8.75 8.75-4.83249 0-8.75-3.9175-8.75-8.75 0-4.83249 3.91751-8.75 8.75-8.75 4.8325 0 8.75 3.91751 8.75 8.75ZM12 22c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10C6.47715 2 2 6.47715 2 12c0 5.5228 4.47715 10 10 10ZM10.3467 7.47997c-.1918-.12786-.43839-.13978-.64161-.03102C9.50187 7.55772 9.375 7.7695 9.375 8v8c0 .2305.12687.4423.33009.551.20322.1088.44981.0969.64161-.031l6-4c.1739-.1159.2783-.311.2783-.52 0-.209-.1044-.4041-.2783-.52l-6-4.00003ZM14.8733 12l-4.2483 2.8322V9.16782L14.8733 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

PlayCircle.propTypes = {
  className: PropTypes.string,
};

export default PlayCircle;
