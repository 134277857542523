import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Inbox({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m17.4306 5.66625.0017.00342 2.8568 5.70533H15.6c-.2195 0-.423.1152-.5359.3034l-1.618 2.6966h-2.8922l-1.61797-2.6966c-.11295-.1882-.31639-.3034-.53593-.3034H3.7109l2.85681-5.70533L5.44999 5.11l1.11942.55625c.06209-.12495.15781-.23011.27639-.30365.11854-.0735.25521-.11249.39468-.1126h9.51902c.1395.00011.2762.0391.3947.1126.1186.07354.2143.17869.2764.30365ZM3.25 12.625V18c0 .1989.07902.3897.21967.5303.14065.1407.33142.2197.53033.2197h16c.1989 0 .3897-.079.5303-.2197.1407-.1406.2197-.3314.2197-.5303v-5.375h-4.7961l-1.618 2.6966c-.1129.1882-.3164.3034-.5359.3034h-3.6c-.21954 0-.42298-.1152-.53593-.3034L8.04613 12.625H3.25Zm2.93704-8.32472c-.31621.19609-.57147.4765-.73705.80972L2 12v6c0 .5304.21071 1.0391.58579 1.4142C2.96086 19.7893 3.46957 20 4 20h16c.5304 0 1.0391-.2107 1.4142-.5858S22 18.5304 22 18v-6l-3.45-6.89c-.1656-.33321-.4208-.61363-.737-.80972-.3163-.19609-.6809-.30008-1.053-.30028H7.24c-.37208.0002-.73674.10419-1.05296.30028Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Inbox.propTypes = {
  className: PropTypes.string,
};

export default Inbox;
