import { lazy, Suspense } from 'react';

import DefaultLoader from '../components/Loader';

function loadable(
  importFunc,
  { Fallback = DefaultLoader } = { Fallback: DefaultLoader },
) {
  const LazyComponent = lazy(importFunc);

  function SuspendedComponent(props) {
    return (
      <Suspense fallback={<Fallback />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  }

  return SuspendedComponent;
}

export default loadable;
