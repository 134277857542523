import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function UserX({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.0119 9.15872c1.3547 0 2.4529-1.09823 2.4529-2.45296s-1.0982-2.45295-2.4529-2.45295c-1.35476 0-2.45299 1.09822-2.45299 2.45295s1.09823 2.45296 2.45299 2.45296Zm0 1.49998c2.1831 0 3.9529-1.76978 3.9529-3.95294 0-2.18315-1.7698-3.95295-3.9529-3.95295-2.18319 0-3.95299 1.7698-3.95299 3.95295 0 2.18316 1.7698 3.95294 3.95299 3.95294Zm.8547.8835c1.4404.1237 2.6933.5463 3.4336.9164.3705.1853.5206.6358.3354 1.0063-.1852.3705-.6357.5206-1.0062.3354-.5774-.2887-1.6469-.6567-2.8912-.7636-1.2402-.1065-2.59938.0504-3.8131.744-.85271.4872-2.70845 2.1893-3.3805 5.1252-.02191.0957.00191.1812.06557.2544.06786.0781.17969.1368.31585.1368h5.59168c.4142 0 .75.3358.75.75 0 .4143-.3358.75-.75.75H4.92602c-1.12209 0-2.12454-.9987-1.8436-2.226.77327-3.378 2.91265-5.4152 4.09847-6.0928 1.55334-.8876 3.24121-1.0602 4.68571-.9361Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.2697 15.0697c.2929-.2929.7677-.2929 1.0606 0L16.2 16.9393l1.8697-1.8696c.2929-.2929.7677-.2929 1.0606 0 .2929.2929.2929.7677 0 1.0606L17.2607 18l1.8696 1.8697c.2929.2929.2929.7677 0 1.0606-.2929.2929-.7677.2929-1.0606 0L16.2 19.0607l-1.8697 1.8696c-.2929.2929-.7677.2929-1.0606 0-.2929-.2929-.2929-.7677 0-1.0606L15.1393 18l-1.8696-1.8697c-.2929-.2929-.2929-.7677 0-1.0606Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}

UserX.propTypes = {
  className: PropTypes.string,
};

export default UserX;
