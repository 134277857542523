import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Briefcase({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9085 1.96324c-1.50028 0-2.7165 1.21622-2.7165 2.7165v1.4665H4.67974c-1.50028 0-2.7165 1.21622-2.7165 2.71651v5.48135c-.00022.0096-.00022.0191 0 .0286v4.9476c0 1.5002 1.21622 2.7165 2.7165 2.7165H19.3203c1.5002 0 2.7165-1.2163 2.7165-2.7165v-4.9474c.0002-.0096.0002-.0193 0-.029V8.86275c0-1.50029-1.2163-2.71651-2.7165-2.71651H16.808v-1.4665c0-1.50028-1.2162-2.7165-2.7165-2.7165h-4.183Zm-5.22876 5.433H19.3203c.8099 0 1.4665.65658 1.4665 1.46651v5.07065C18.0603 14.9826 15.0981 15.558 12 15.558c-3.09805 0-6.06032-.5754-8.78676-1.6246V8.86275c0-.80993.65657-1.46651 1.4665-1.46651Zm3.76226-1.25h7.116v-1.4665c0-.80993-.6566-1.4665-1.4665-1.4665h-4.183c-.80993 0-1.4665.65657-1.4665 1.4665v1.4665ZM12 16.808c3.085 0 6.0444-.5434 8.7868-1.54v4.0523c0 .8099-.6566 1.4665-1.4665 1.4665H4.67974c-.80993 0-1.4665-.6566-1.4665-1.4665V15.268c2.7424.9966 5.70176 1.54 8.78676 1.54Zm.3153-3.208c.434 0 .7859-.3582.7859-.8s-.3519-.8-.7859-.8c-.434 0-.7859.3582-.7859.8s.3519.8.7859.8Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Briefcase.propTypes = {
  className: PropTypes.string,
};

export default Briefcase;
