import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Pdf({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M20.5 3.25h-17c-.14 0-.25.11-.25.25v17c0 .14.11.25.25.25h17c.14 0 .25-.11.25-.25v-17c0-.14-.11-.25-.25-.25ZM3.5 2h17c.83 0 1.5.67 1.5 1.5v17c0 .83-.67 1.5-1.5 1.5h-17c-.83 0-1.5-.67-1.5-1.5v-17C2 2.67 2.67 2 3.5 2Zm.88 8c0-.35.28-.62.62-.62h1.61c1.11 0 2.01.9 2.01 2.01 0 1.11-.9 2.01-2.01 2.01h-.99V15c0 .35-.28.62-.62.62-.34 0-.62-.28-.62-.62v-5Zm1.25 2.15h.99c.42 0 .76-.34.76-.76s-.34-.76-.76-.76h-.99v1.53-.01ZM15.38 10c0-.35.28-.62.62-.62h3c.35 0 .62.28.62.62 0 .34-.28.62-.62.62h-2.38v1.53H19c.35 0 .62.28.62.62 0 .34-.28.62-.62.62h-2.38v1.6c0 .35-.28.62-.62.62-.34 0-.62-.28-.62-.62v-5V10Zm-6 0c0-.35.28-.62.62-.62h1.16c1.25 0 2.39.74 2.9 1.89l.1.22c.33.74.36 1.58.09 2.35-.38 1.07-1.39 1.79-2.53 1.79H9.99c-.35 0-.62-.28-.62-.62v-5l.01-.01Zm1.25.62v3.75h1.1c.61 0 1.15-.38 1.35-.96.16-.47.14-.98-.06-1.43l-.1-.22c-.31-.69-1-1.14-1.76-1.14h-.54.01Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Pdf.propTypes = {
  className: PropTypes.string,
};

export default Pdf;
