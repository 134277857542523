/**
 * @file
 * A wrapper around Axios.
 *
 * @see
 * https://github.com/axios/axios
 */
import axios from 'axios';

/**
 * @typedef {object} RequestOptions
 * @property {object} [body] - Request's payload
 * @property {object} [headers] - Request's custom headers
 * @property {object} [params] - Adds URL query params to the request
 * @property {string} [url] - Request's URL
 */

function shapeErrorMsg(error) {
  const { response } = error;

  // If the response returned from the server has a `message` or an `error`, then use it
  if (response?.data?.message || response?.data?.error) {
    return response?.data?.message ?? response?.data?.error;
  }

  // In case response returned from the server is a string, then use it
  if (typeof response?.data === 'string' && response?.data) {
    return response.data;
  }

  // Otherwise, return the status code (for example "404")
  return response?.status;
}

/**
 * @param {object} [options]
 * @param {object} [options.body] - Request's payload
 * @param {object} [options.headers] - Request's custom headers
 * @param {('delete'|'get'|'patch'|'post'|'put')} [options.method] - Request's method
 * @param {object} [options.params] - Adds URL query params to the request
 * @param {string} [options.url] - Request's URL
 * @param {boolean} [options.withCredentials] - Add credentials to cross-site Access-Control requests
 * @returns {Promise<Response>}
 */
async function request({
  body,
  headers,
  method,
  params,
  url,
  withCredentials,
}) {
  try {
    const response = await axios({
      data: body,
      headers,
      params,
      method,
      url,
      withCredentials,
    });

    return response.data;
  } catch (error) {
    const errorMsg = shapeErrorMsg(error);
    const customError = new Error(errorMsg ?? error.message);

    customError.meta = {
      body: error.response?.data,
      message: error.response?.data?.message ?? error.response?.data?.error,
      statusCode: error.response?.status,
      /**
       * In Chrome we wouldn't have a status-text. HTTP/2 does not have reason phrases.
       *
       * @see
       * https://datatracker.ietf.org/doc/html/rfc7540#section-8.1.2.4
       */
      statusText: error.response?.statusText,
    };

    throw customError;
  }
}

/**
 * Shape a request by combining given options with the Network's instance config.
 *
 * - Appends config's base URL to the request's URL
 *
 * @param {('delete'|'get'|'patch'|'post'|'put')} method
 * @param {object} options
 * @param {object} config
 * @returns {object}
 */
function shapeRequest(method, options, config) {
  return {
    method,
    ...options,
    url: [config?.baseUrl, options.url].filter(Boolean).join(''),
  };
}

/**
 * Create a new instance of network service along with a config of its own.
 *
 * @param {object} config
 * @param {string} config.baseUrl
 */
function create(config) {
  return {
    create,
    baseUrl: config?.baseUrl,
    /**
     * Intercept requests or responses before they are handled.
     * A proxy of Axios interceptors.
     *
     * @see
     * https://axios-http.com/docs/interceptors
     */
    interceptors: axios.interceptors,
    /**
     * @param {RequestOptions} [options]
     * @returns {Promise}
     */
    async delete(options) {
      const response = await request(shapeRequest('delete', options, config));

      return response;
    },
    /**
     * @param {RequestOptions} [options]
     * @returns {Promise}
     */
    async get(options) {
      const response = await request(shapeRequest('get', options, config));

      return response;
    },
    /**
     * @param {RequestOptions} [options]
     * @returns {Promise}
     */
    async patch(options) {
      const response = await request(shapeRequest('patch', options, config));

      return response;
    },
    /**
     * @param {RequestOptions} [options]
     * @returns {Promise}
     */
    async post(options) {
      const response = await request(shapeRequest('post', options, config));

      return response;
    },
    /**
     * @param {RequestOptions} [options]
     * @returns {Promise}
     */
    async put(options) {
      const response = await request(shapeRequest('put', options, config));

      return response;
    },
    /**
     * Shape Fetch API's Headers interface into an object.
     *
     * @param {Headers} headers - Fetch API's Headers interface.
     * @returns {object}
     */
    shapeHeadersToObj(headers) {
      return Object.fromEntries(headers.entries());
    },
  };
}

const network = create();

export { network };
