import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Camera({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51979 6.76613 9.86388 4.75h4.26832l1.3441 2.01613c.3941.59118 1.0576.94627 1.7681.94627h2.6346c.4832 0 .875.39175.875.875v9.8371c0 .4832-.3918.875-.875.875H4.11713c-.48325 0-.875-.3918-.875-.875V8.5874c0-.48325.39175-.875.875-.875h2.63455c.7105 0 1.37399-.35509 1.76811-.94627ZM9.79698 3.5c-.37615 0-.72741.18799-.93606.50096l-1.3812 2.0718c-.16228.24342-.43548.38964-.72804.38964H4.11713c-1.17361 0-2.125.95139-2.125 2.125v9.8371c0 1.1736.95139 2.1249 2.125 2.1249H19.879c1.1736 0 2.125-.9513 2.125-2.1249V8.5874c0-1.17361-.9514-2.125-2.125-2.125h-2.6346c-.2925 0-.5657-.14622-.728-.38964l-1.3812-2.0718c-.2087-.31297-.5599-.50096-.9361-.50096H9.79698Zm5.39462 9.7097c0 1.7638-1.4298 3.1936-3.1936 3.1936-1.7637 0-3.19356-1.4298-3.19356-3.1936s1.42986-3.1936 3.19356-3.1936c1.7638 0 3.1936 1.4298 3.1936 3.1936Zm1.25 0c0 2.4541-1.9894 4.4436-4.4436 4.4436-2.4541 0-4.44356-1.9895-4.44356-4.4436 0-2.4542 1.98946-4.44362 4.44356-4.44362 2.4542 0 4.4436 1.98942 4.4436 4.44362Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Camera.propTypes = {
  className: PropTypes.string,
};

export default Camera;
