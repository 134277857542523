import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { cx } from '@emotion/css';
import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import Trigger from './Trigger';
import SubTrigger from './SubTrigger';
import Content from './Content';
import SubContent from './SubContent';
import Sub from './Sub';
import Item from './Item';
import Label from './Label';
import Separator from './Separator';

/**
 * Dropdown allows a user to choose one action from a collection of actions.
 *
 * @see
 * [Radix UI: Dropdown Menu](https://www.radix-ui.com/docs/primitives/components/dropdown-menu)
 */
const Dropdown = forwardRef(({ children, className }, ref) => (
  <div className={cx(className, 'HioDropdown__root')} ref={ref}>
    <RadixDropdown.DropdownMenu>{children}</RadixDropdown.DropdownMenu>
  </div>
));

Dropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Dropdown.Content = Content;
Dropdown.Item = Item;
Dropdown.Label = Label;
Dropdown.Separator = Separator;
Dropdown.Sub = Sub;
Dropdown.SubContent = SubContent;
Dropdown.SubTrigger = SubTrigger;
Dropdown.Trigger = Trigger;

export default Dropdown;
