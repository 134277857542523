import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function CircleWarning({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.75c4.8325 0 8.75-3.9175 8.75-8.75 0-4.83249-3.9175-8.75-8.75-8.75-4.83249 0-8.75 3.91751-8.75 8.75 0 4.8325 3.91751 8.75 8.75 8.75ZM10 20c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10C4.47715 0 0 4.47715 0 10c0 5.5228 4.47715 10 10 10Zm.0694-8.8889c.3452 0 .625-.2798.625-.625V5.06945c0-.34518-.2798-.625-.625-.625-.34513 0-.62496.27982-.62496.625v5.41665c0 .3452.27983.625.62496.625ZM10 13.3333c.6137 0 1.1111.4975 1.1111 1.1111 0 .6137-.4974 1.1112-1.1111 1.1112-.61365 0-1.11111-.4975-1.11111-1.1112 0-.6136.49746-1.1111 1.11111-1.1111Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

CircleWarning.propTypes = {
  className: PropTypes.string,
};

export default CircleWarning;
