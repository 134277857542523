import { css } from '@emotion/react';

import RobotoLightWoff2 from './Roboto-Light.woff2';
import RobotoLightWoff from './Roboto-Light.woff';
import RobotoRegularWoff2 from './Roboto-Regular.woff2';
import RobotoRegularWoff from './Roboto-Regular.woff';
import RobotoMediumWoff2 from './Roboto-Medium.woff2';
import RobotoMediumWoff from './Roboto-Medium.woff';
import RobotoBlackWoff2 from './Roboto-Black.woff2';
import RobotoBlackWoff from './Roboto-Black.woff';

/**
 * @see
 * [Google Webfonts Helper](https://google-webfonts-helper.herokuapp.com/fonts)
 */
export const fontFaces = css`
  /* Light (300) */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'),
      url(${RobotoLightWoff2}) format('woff2'),
      url(${RobotoLightWoff}) format('woff');
  }

  /* Regular (400) */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Roboto Regular'), local('Roboto-Regular'),
      url(${RobotoRegularWoff2}) format('woff2'),
      url(${RobotoRegularWoff}) format('woff');
  }

  /* Medium (500) */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'),
      url(${RobotoMediumWoff2}) format('woff2'),
      url(${RobotoMediumWoff}) format('woff');
  }

  /* Black (900) */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: local('Roboto Black'), local('Roboto-Black'),
      url(${RobotoBlackWoff2}) format('woff2'),
      url(${RobotoBlackWoff}) format('woff');
  }
`;
