import { createSlice } from '@reduxjs/toolkit';

export const sliceKey = 'graph';

export const initialState = {
  startIndex: 0,
  endIndex: 0,
};

const graphSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setIndices(state, { payload: { start, end } }) {
      state.startIndex = start;
      state.endIndex = end;
    },
  },
});

export const { reducer, actions } = graphSlice;
