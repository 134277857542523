import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function FileReplace({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.375c.34518 0 .625.27982.625.625v.54167c0 .34517-.27982.625-.625.625s-.625-.27983-.625-.625V5c0-.34518.27982-.625.625-.625Zm0 2.70833c.34518 0 .625.27983.625.625v1.08334c0 .34517-.27982.625-.625.625s-.625-.27983-.625-.625V7.70833c0-.34517.27982-.625.625-.625Zm0 3.24997c.34518 0 .625.2799.625.625v1.0834c0 .3451-.27982.625-.625.625s-.625-.2799-.625-.625v-1.0834c0-.3451.27982-.625.625-.625Zm0 3.25c.34518 0 .625.2799.625.625v1.0834c0 .3451-.27982.625-.625.625s-.625-.2799-.625-.625v-1.0834c0-.3451.27982-.625.625-.625Zm0 3.25c.34518 0 .625.2799.625.625V18c0 .3452-.27982.625-.625.625s-.625-.2798-.625-.625v-.5417c0-.3451.27982-.625.625-.625ZM4.375 20c0-.3452.27982-.625.625-.625h.5c.34518 0 .625.2798.625.625s-.27982.625-.625.625H5c-.34518 0-.625-.2798-.625-.625Zm2.5 0c0-.3452.27982-.625.625-.625h1c.34518 0 .625.2798.625.625s-.27982.625-.625.625h-1c-.34518 0-.625-.2798-.625-.625Zm3 0c0-.3452.2798-.625.625-.625h1c.3452 0 .625.2798.625.625s-.2798.625-.625.625h-1c-.3452 0-.625-.2798-.625-.625Zm3 0c0-.3452.2798-.625.625-.625h1c.3452 0 .625.2798.625.625s-.2798.625-.625.625h-1c-.3452 0-.625-.2798-.625-.625Zm3 0c0-.3452.2798-.625.625-.625h.5c.3452 0 .625.2798.625.625s-.2798.625-.625.625h-.5c-.3452 0-.625-.2798-.625-.625Zm3.025-3.561H8.50001c-.13807 0-.25-.112-.25-.25V3.77503c0-.13807.11193-.25.25-.25h5.83119v3.81616c0 .62132.5037 1.125 1.125 1.125H19.15V16.189c0 .138-.1119.25-.25.25Zm-.4258-9.22281h-2.893V4.21463l2.893 3.00156ZM18.9 17.689H8.50001c-.82843 0-1.5-.6716-1.5-1.5V3.77503c0-.82843.67157-1.5 1.5-1.5h6.31029c.4074 0 .7973.16572 1.08.45906L19.98 6.97726c.2694.27955.42.65269.42 1.04095V16.189c0 .8284-.6716 1.5-1.5 1.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

FileReplace.propTypes = {
  className: PropTypes.string,
};

export default FileReplace;
