import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function File({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.09998 20.35H18.9c.138 0 .25-.1119.25-.25V9.8785h-5.075c-.6213 0-1.125-.50367-1.125-1.125V3.52503H5.09998c-.13807 0-.25.11193-.25.25V20.1c0 .1381.11192.25.25.25ZM14.2 4.08522l4.3789 4.54328H14.2V4.08522ZM5.09998 21.6H18.9c.8284 0 1.5-.6716 1.5-1.5V9.32189c0-.38826-.1506-.7614-.42-1.04095l-5.3462-5.54685c-.2827-.29334-.6726-.45906-1.08-.45906H5.09998c-.82843 0-1.5.67157-1.5 1.5V20.1c0 .8284.67157 1.5 1.5 1.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

File.propTypes = {
  className: PropTypes.string,
};

export default File;
