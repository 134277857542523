import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ExclamationTriangle({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        d="M12.0249 13.9212c.3487 0 .6177-.2804.6177-.6208V7.87359c0-.35044-.2789-.62077-.6177-.62077-.3387 0-.6177.28035-.6177.62077v5.42681c0 .3504.279.6208.6177.6208Zm.1793 2.443c.548 0 .9964.4506.9964 1.0013 0 .5506-.4484 1.0012-.9964 1.0012-.5479 0-.9963-.4506-.9963-1.0012 0-.5507.4484-1.0013.9963-1.0013Zm1.7336-12.2453 7.7614 13.5169C22.556 19.1277 21.48 21 19.7663 21H4.23369c-1.71367 0-2.78969-1.8723-1.93286-3.3642L10.0721 4.1189c.8569-1.49187 3.0089-1.49187 3.8657 0Zm-1.066.62077c-.3786-.67083-1.3451-.67083-1.7237 0L3.37686 18.2566c-.3786.6708.09963 1.5018.8668 1.5018H19.7763c.7671 0 1.2454-.831.8668-1.5018L12.8618 4.73967h.01Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ExclamationTriangle.propTypes = {
  className: PropTypes.string,
};

export default ExclamationTriangle;
