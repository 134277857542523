import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Globe({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1689 20.6726C17.246 20.1283 20.435 16.7764 20.728 12.625H16.5964C16.3898 15.5706 15.1875 18.3776 13.1689 20.6726ZM10.8311 20.6726C6.75399 20.1283 3.56497 16.7764 3.27198 12.625H7.40363C7.61023 15.5706 8.81251 18.3776 10.8311 20.6726ZM8.65704 12.625C8.86958 15.3617 10.0345 17.9791 12 20.1023C13.9655 17.9791 15.1304 15.3617 15.343 12.625H8.65704ZM15.343 11.375H8.65704C8.86958 8.63826 10.0345 6.02089 12 3.89769C13.9655 6.02089 15.1304 8.63826 15.343 11.375ZM16.5964 11.375H20.728C20.435 7.22356 17.246 3.8717 13.1689 3.3274C15.1875 5.62236 16.3898 8.42936 16.5964 11.375ZM10.8311 3.3274C8.81251 5.62236 7.61023 8.42936 7.40363 11.375H3.27198C3.56497 7.22356 6.75399 3.8717 10.8311 3.3274ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill="black"
      />
    </SvgIcon>
  );
}

Globe.propTypes = {
  className: PropTypes.string,
};

export default Globe;
