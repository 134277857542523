import { createSlice } from '@reduxjs/toolkit';

export const sliceKey = 'language';

export const initialState = {
  locale: 'en',
  supportedLanguages: ['en', 'he'],
};

const languageSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    changeLanguageLocale(state, action) {
      state.locale = action.payload;
    },
    resetLanguageLocale(state) {
      state.locale = initialState.locale;
    },
  },
});

export const { reducer, actions } = languageSlice;
