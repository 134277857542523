import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Circle({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.75c4.8325 0 8.75-3.9175 8.75-8.75 0-4.83249-3.9175-8.75-8.75-8.75-4.83249 0-8.75 3.91751-8.75 8.75 0 4.8325 3.91751 8.75 8.75 8.75ZM12 22c5.5228 0 10-4.4772 10-10 0-5.52285-4.4772-10-10-10C6.47715 2 2 6.47715 2 12c0 5.5228 4.47715 10 10 10Zm3.9976-14.0025c.2441.24407.2441.6398 0 .88388L12.879 12l3.1187 3.1186c.244.2441.244.6398 0 .8839-.2441.2441-.6399.2441-.8839 0l-3.1187-3.1186-3.11363 3.1136c-.24408.2441-.63981.2441-.88389 0-.24407-.2441-.24407-.6398 0-.8839L11.1112 12 7.99765 8.88638c-.24408-.24408-.24408-.63981 0-.88388.24407-.24408.6398-.24408.88388 0l3.11357 3.1136 3.1186-3.1186c.2441-.24408.6398-.24408.8839 0Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Circle.propTypes = {
  className: PropTypes.string,
};

export default Circle;
