import { getTokenClaims } from '../utils';

import request from './request';

/**
 * @typedef {import('./getInitialAuthToken.js').Config} Config
 */

/**
 * Get user claims payload from BE
 * @param {Config} config
 * @param {string} token - a valid auth token
 *
 * @returns {object?} The user's claims
 */
export default async function getUserClaims(config, token) {
  let url;
  switch (true) {
    case Boolean(config?.userClaimsUrl):
      url = config.userClaimsUrl;
      break;
    case Boolean(config?.userClaimsMultiRegion):
      {
        const { tags } = getTokenClaims(token);
        url = config.userClaimsMultiRegion[tags.region];
      }
      break;
    default:
      break;
  }

  const claims = await request(url);

  return claims;
}
