import { css } from '@emotion/react';

export const urineTokensCss = css`
  :root {
    /* -------------------
            Colors
    -------------------- */
    /* Primary */
    --primary-100: var(--blue-100);
    --primary-200: var(--blue-200);
    --primary-300: var(--blue-300);
    --primary-400: var(--blue-400);
    --primary-500: var(--blue-500);
    --primary-600: var(--blue-600);
    --primary-700: var(--blue-700);
  }
`;
