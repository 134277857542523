import PropTypes from 'prop-types';

import { css } from '@emotion/react';

import { components } from 'react-select';

import { colors } from '../../../theme';

import Icon from '../../Icon/Icon';

const iconCss = ({ isDisabled }) => css`
  color: ${isDisabled ? colors.gray600 : colors.black};
`;

function DropdownIndicator(props) {
  const {
    isDisabled,
    selectProps: { menuIsOpen, overrideDropdownIcon },
  } = props;

  const defaultIcon = menuIsOpen ? 'chevron-up' : 'chevron-down';

  return (
    <components.DropdownIndicator {...props}>
      <Icon
        css={iconCss({ isDisabled })}
        icon={overrideDropdownIcon || defaultIcon}
        size="sm"
      />
    </components.DropdownIndicator>
  );
}

DropdownIndicator.propTypes = {
  isDisabled: PropTypes.bool,
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
    overrideDropdownIcon: PropTypes.string,
  }),
};

export default DropdownIndicator;
