import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function MinutefulKit({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 3.25H7.625v17.5H19.5c.1381 0 .25-.1119.25-.25V17H17.5c-.5523 0-1-.4477-1-1V9.11111c0-.55228.4477-1 1-1h2.25V3.5c0-.13807-.1119-.25-.25-.25ZM21 9.11111V3.5c0-.82843-.6716-1.5-1.5-1.5h-15C3.67157 2 3 2.67157 3 3.5v17c0 .8284.67157 1.5 1.5 1.5h15c.8284 0 1.5-.6716 1.5-1.5V9.11111Zm-1.25.25h-2V15.75h2V9.36111ZM4.5 20.75h1.875V3.25H4.5c-.13807 0-.25.11193-.25.25v17c0 .1381.11193.25.25.25ZM9.75 6.93056c0-.34518.2798-.625.625-.625h5.5c.3452 0 .625.27982.625.625 0 .34517-.2798.625-.625.625h-5.5c-.3452 0-.625-.27983-.625-.625ZM10.375 8.75c-.3452 0-.625.27982-.625.625s.2798.625.625.625h3.25c.3452 0 .625-.27982.625-.625s-.2798-.625-.625-.625h-3.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

MinutefulKit.propTypes = {
  className: PropTypes.string,
};

export default MinutefulKit;
