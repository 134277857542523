import { css } from '@emotion/react';

export const woundTokensCss = css`
  :root {
    /* -------------------
            Colors
    -------------------- */
    /* Primary pallet */
    --secondary: var(--secondary-300);

    /* Primary */
    --primary-100: var(--teal-100);
    --primary-200: var(--teal-200);
    --primary-300: var(--teal-300);
    --primary-400: var(--teal-400);
    --primary-500: var(--teal-500);
    --primary-600: var(--teal-600);
    --primary-700: var(--teal-700);

    /* Secondary */
    --secondary-100: var(--salmon-100);
    --secondary-200: var(--salmon-200);
    --secondary-300: var(--salmon-300);
    --secondary-400: var(--salmon-400);
    --secondary-500: var(--salmon-500);
    --secondary-600: var(--salmon-600);
    --secondary-700: var(--salmon-700);
  }
`;
