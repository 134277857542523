import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Stack from './Stack';

/**
 * Renders a vertical Stack. Based on Flex-box
 * @param {String} align - Flexbox align-items attribute. Defaults to 'stretch'
 * @param {String} justify - Flexbox justify-content attribute. Defaults to 'flex-start'
 * @param {String} wrap - Flexbox wrap attribute. Defaults to 'wrap'
 * @param {React.ReactChildren} children - Rendered content
 */
const VStack = forwardRef(({ children, ...restProps }, ref) => (
  <Stack ref={ref} direction="column" {...restProps}>
    {children}
  </Stack>
));

VStack.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VStack;
