import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Voicemail({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57087 8.75C4.742 8.75 3.246 10.2536 3.246 12.125S4.742 15.5 6.57087 15.5c1.82886 0 3.32487-1.5036 3.32487-3.375S8.39973 8.75 6.57087 8.75ZM1.996 12.125c0-2.54686 2.04083-4.625 4.57487-4.625 2.53404 0 4.57483 2.07814 4.57483 4.625 0 1.3288-.5555 2.53-1.44673 3.375h4.60593c-.8913-.845-1.4468-2.0462-1.4468-3.375 0-2.54686 2.0408-4.625 4.5749-4.625 2.534 0 4.5748 2.07814 4.5748 4.625 0 2.5462-2.0397 4.6239-4.5728 4.625H6.80263c-.02114 0-.04202-.001-.06262-.0031-.05612.0021-.11251.0031-.16914.0031-2.53404 0-4.57487-2.0781-4.57487-4.625ZM17.433 15.5c1.8288 0 3.3248-1.5036 3.3248-3.375S19.2618 8.75 17.433 8.75c-1.8289 0-3.3249 1.5036-3.3249 3.375s1.496 3.375 3.3249 3.375Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Voicemail.propTypes = {
  className: PropTypes.string,
};

export default Voicemail;
