import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Truck({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.68298 5.47106c-.2071 0-.375.16789-.375.375V16.624c0 .2072.1679.375.375.375h1.07045c.02599 0 .0516.0016.07675.0047.28072-1.1116 1.2986-1.9353 2.51129-1.9353 1.211 0 2.22773.8214 2.51011 1.9306h2.18362c.2071 0 .375-.1678.375-.375V5.84606c0-.20711-.1679-.375-.375-.375H3.68298ZM4.75343 18.249c.02599 0 .0516-.0015.07675-.0046.28072 1.1116 1.2986 1.9352 2.51129 1.9352 1.211 0 2.22773-.8213 2.51011-1.9306h2.18362c.2219 0 .4334-.0444.6261-.125H14.12c.2354 1.1721 1.2825 2.0556 2.5386 2.0556 1.2127 0 2.2305-.8236 2.5113-1.9352.0251.0031.0507.0046.0767.0046h1.0705c.8974 0 1.625-.7275 1.625-1.625v-3.1111c0-.8975-.7276-1.625-1.625-1.625h-.6864l-1.897-3.74634c-.1064-.21018-.322-.34266-.5575-.34266h-3.516V5.84606c0-.89746-.7275-1.625-1.625-1.625H3.68298c-.89746 0-1.625.72754-1.625 1.625V16.624c0 .8975.72754 1.625 1.625 1.625h1.07045Zm8.90677-9.2001v2.839h4.5694l-1.4375-2.839h-3.1319Zm5.574 4.089h-5.574v3.4861c0 .1745-.0275.3425-.0783.5h.5381c.2354-1.172 1.2825-2.0556 2.5386-2.0556 1.2127 0 2.2305.8237 2.5113 1.9353.0251-.0031.0507-.0047.0767-.0047h1.0705c.2071 0 .375-.1678.375-.375v-3.1111c0-.2072-.1679-.375-.375-.375H19.26c-.0086.0001-.0172.0001-.0258 0ZM8.67954 17.624c0 .7063-.58415 1.3056-1.33807 1.3056-.75391 0-1.33806-.5993-1.33806-1.3056 0-.7062.58415-1.3056 1.33806-1.3056.75392 0 1.33807.5994 1.33807 1.3056Zm9.31706 0c0 .7063-.5841 1.3056-1.338 1.3056-.754 0-1.3381-.5993-1.3381-1.3056 0-.7062.5841-1.3056 1.3381-1.3056.7539 0 1.338.5994 1.338 1.3056Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Truck.propTypes = {
  className: PropTypes.string,
};

export default Truck;
