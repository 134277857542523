import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function ChevronDown({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.55806 8.55806c-.24408.24408-.24408.6398 0 .88388l7.00004 6.99996c.244.2441.6398.2441.8838 0l7-6.99996c.2441-.24408.2441-.6398 0-.88388-.244-.24408-.6398-.24408-.8838 0L12 15.1161 5.44194 8.55806c-.24408-.24408-.6398-.24408-.88388 0Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

ChevronDown.propTypes = {
  className: PropTypes.string,
};

export default ChevronDown;
