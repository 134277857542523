import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { toRem } from '../../helpers';
import { spacing } from '../../theme';

const containerCss = css`
  width: 100%;
  margin-inline: auto;
  padding-inline: ${spacing.space4};
  /* Default max-width user in Heart */
  max-width: calc(${toRem(1110)} + 2 * ${spacing.space4});
  
  &.HioContainer--sm {
    max-width: calc(${toRem(1024)});
  }
  
  &.HioContainer--md {
    max-width: calc(${toRem(1280)} );
  }
  
  &.HioContainer--lg {
    max-width: calc(${toRem(1440)} );
  }
  
  &.HioContainer--xl {
    max-width: calc(${toRem(1920)} );
  }

  &.HioContainer--fluid {
    max-width: unset;
  }
`;

const Container = forwardRef(
  ({ as: Component = 'div', children, className, isFluid, size }, ref) => (
    <Component
      css={containerCss}
      className={cx(
        'HioContainer__root',
        { [`HioContainer--${size}`]: Boolean(size) },
        { 'HioContainer--fluid': isFluid },
        className,
      )}
      ref={ref}
    >
      {children}
    </Component>
  ),
);

Container.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  /** Remove max-width from the container and allow it to take 100% in width */
  isFluid: PropTypes.bool,
};

export default Container;
