import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Check({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4065 5.52525c.2622.22451.2927.61905.0682.88125l-9.4187 11c-.11314.1321-.2762.2111-.44999.218-.1738.0069-.3426-.0589-.46583-.1817l-4.58124-4.5629c-.24456-.2436-.24535-.6394-.00176-.8839.24359-.2446.63931-.2454.88388-.0018l4.10375 4.0874L18.5253 5.5935c.2245-.2622.619-.29275.8812-.06825Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Check.propTypes = {
  className: PropTypes.string,
};

export default Check;
