import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';

function Mobile({ className }) {
  return (
    <SvgIcon className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 3.25h-9c-.13807 0-.25.11193-.25.25v17c0 .1381.11193.25.25.25h9c.1381 0 .25-.1119.25-.25v-17c0-.13807-.1119-.25-.25-.25ZM7.5 2C6.67157 2 6 2.67157 6 3.5v17c0 .8284.67157 1.5 1.5 1.5h9c.8284 0 1.5-.6716 1.5-1.5v-17c0-.82843-.6716-1.5-1.5-1.5h-9Zm5.33 16.65c0 .4694-.3738.85-.835.85-.4611 0-.835-.3806-.835-.85s.3739-.85.835-.85c.4612 0 .835.3806.835.85Zm-2.605-13.9c-.3452 0-.62502.27982-.62502.625S9.8798 6 10.225 6h3.55c.3452 0 .625-.27982.625-.625s-.2798-.625-.625-.625h-3.55Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

Mobile.propTypes = {
  className: PropTypes.string,
};

export default Mobile;
