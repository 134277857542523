import { createSlice } from '@reduxjs/toolkit';

export const sliceKey = 'timeline';

export const initialState = {
  nextSlideIndex: null,
  mode: 'carousel',
};

const timelineSlice = createSlice({
  name: sliceKey,
  initialState,
  reducers: {
    setNextSlideIndex(state, { payload: { index } }) {
      state.nextSlideIndex = index;
    },
    setMode(state, { payload: { mode } }) {
      state.mode = mode;
    },
  },
});

export const { reducer, actions } = timelineSlice;
