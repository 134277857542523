import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { cx } from '@emotion/css';
import { Children, forwardRef } from 'react';

import { borderRadius, colors, spacing } from '../../theme';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';

import Content from './Content';
import Title from './Title';

export const Intents = {
  INFO: 'info',
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const intentIcon = {
  [Intents.DANGER]: 'x-circle',
  [Intents.INFO]: 'exclamation-circle',
  [Intents.SUCCESS]: 'check-circle',
  [Intents.WARNING]: 'circle-warning',
};

const alertCss = ({ hasOnlyOneSubComponent }) => css`
  min-height: 60px;
  position: relative;
  display: flex;
  align-items: ${hasOnlyOneSubComponent ? 'center' : 'flex-start'};
  column-gap: ${spacing.space2};
  padding: ${spacing.space3};
  border: 1px solid;
  border-radius: ${borderRadius.sm};
  border-color: currentColor;
  color: currentColor;

  /* Intents */

  /* Danger */
  &.HioAlert--intent-danger {
    background-color: ${colors.danger100};
    color: ${colors.danger};
  }

  /* Info */
  &.HioAlert--intent-info {
    background-color: ${colors.info100};
    color: ${colors.info};
  }

  /* Success */
  &.HioAlert--intent-success {
    background-color: ${colors.success100};
    color: ${colors.success};
  }

  /* Warning */
  &.HioAlert--intent-warning {
    background-color: ${colors.warning100};
    color: ${colors.warning};
  }

  /* Content */
  .HioAlert__wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: ${spacing.space1};
  }
`;

/**
 * The `Alert` component is a customizable alert that can be used to provide brief notifications to users.
 *
 * It supports four intents: `danger`, `info`, `success`, and `warning`.
 * The `Alert` component can be used to display a title and content. The title is by using the `Alert.Title` component.
 * You can wrap the content in the `Alert.Content` component to add some spacing between the components inside the content and to control the direction of the content by the `direction` prop.
 */

const Alert = forwardRef(
  (
    {
      children,
      className,
      onClose,
      intent = Intents.INFO,
      isClosable = false,
      isVisible = true,
      ...restProps
    },
    ref,
  ) => {
    const icon = intentIcon[intent];

    const alertSubComponents = Children.map(children, (child) => {
      if (!child) {
        return null;
      }

      if (
        child?.type?.displayName === 'Alert.Title' ||
        child?.type?.displayName === 'Alert.Content'
      ) {
        return child;
      }

      return <Content>{child}</Content>;
    });

    const hasOnlyOneSubComponent = alertSubComponents.length === 1;

    if (!isVisible) {
      return null;
    }

    return (
      <div
        css={alertCss({ hasOnlyOneSubComponent })}
        className={cx([
          'HioAlert',
          {
            [`HioAlert--intent-${intent}`]: intent,
          },
          className,
        ])}
        ref={ref}
        role="banner"
        {...restProps}
      >
        <Icon icon={icon} size="md" />
        <div className="HioAlert__wrapper">
          {alertSubComponents.map((component) => component)}
        </div>
        {isClosable && (
          <Button
            className="HioAlert__dismiss-btn"
            isIcon
            appearance="text"
            size="sm"
            onClick={onClose}
          >
            <Icon icon="x" size="xs" />
          </Button>
        )}
      </div>
    );
  },
);

Alert.Content = Content;
Alert.Title = Title;

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** The alert's intent */
  intent: PropTypes.oneOf(['danger', 'info', 'success', 'warning']),
  /** Whether the alert is visible or not */
  isVisible: PropTypes.bool,
  /** Whether the close button is shown or not */
  isClosable: PropTypes.bool,
  /** Callback function when the close button is clicked */
  onClose: PropTypes.func,
};

export default Alert;
