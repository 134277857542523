const LAST_REFRESH_AT_KEY = `${window.location.origin}_lastRefreshAt`;
const SEC_THRESHOLD = 20;

function getLastRefreshedAt() {
  return sessionStorage.getItem(LAST_REFRESH_AT_KEY);
}

function setLastRefreshedAt(timestamp) {
  sessionStorage.setItem(LAST_REFRESH_AT_KEY, timestamp);
}

function diffTimestampsInSeconds(time1, time2) {
  const diff = time1 - time2;
  const diffInSec = diff / 1000;
  return Math.abs(diffInSec);
}

// Handle ChunkLoadError type errors
// Store a timestamp to avoid a reloading loop.
// Using session storage (opening multiple tabs/windows with the same URL creates sessionStorage for each tab/window).
// https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
export default function handleChunkLoadingError() {
  let triggeredReload = false;
  // check if we already have a refresh key
  const lastRefreshedAt = getLastRefreshedAt();
  // If not, set a new refresh key refresh the page.
  if (!lastRefreshedAt) {
    setLastRefreshedAt(Date.now());
    triggeredReload = true;
    window.location.reload();
  }
  // If the time passed between last refresh and now is greater then the threshold defined
  // set a new refresh key and refresh the page
  if (diffTimestampsInSeconds(lastRefreshedAt, Date.now()) > SEC_THRESHOLD) {
    setLastRefreshedAt(Date.now());
    triggeredReload = true;
    window.location.reload();
  }
  return triggeredReload;
}
