import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { cx } from '@emotion/css';
import useThemeContext from '../Theme/useThemeContext';
import DefaultButton from './DefaultButton';
import { SplitButtonContext } from './useSplitButtonContext';
import Dropdown from './Dropdown';

const splitButtonCss = (props) => css`
  display: flex;

  ${props.isContained &&
  css`
    column-gap: 1px;
  `}
`;

/**
 * A split button is a dual-function menu button that offers a default action as well as the possibility
 * of choosing a different action by selecting from a set of alternatives.
 */
function SplitButton({ appearance, children, className, size }) {
  const { defaultProps } = useThemeContext();
  const _appearance =
    appearance ?? defaultProps?.Button?.appearance ?? 'contained';

  const isContained = _appearance === 'contained';

  return (
    <SplitButtonContext.Provider
      value={{ appearance: _appearance, isContained, size }}
    >
      <div
        className={cx('HioSplitButton__root', className)}
        css={splitButtonCss({ isContained })}
      >
        {children}
      </div>
    </SplitButtonContext.Provider>
  );
}

SplitButton.propTypes = {
  appearance: PropTypes.oneOf(['contained', 'outlined']),
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

SplitButton.DefaultButton = DefaultButton;
SplitButton.Dropdown = Dropdown;

export default SplitButton;
